import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { BasketRightColumn } from './BasketRightColumn';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import { Multiselect } from 'ui/Multiselect';
import { Link } from 'react-router-dom';
import {
  BookingBuilderResponse,
  ENetworkRequestStatus,
  EUserType,
  ICompany,
  isBookingBuilderResponse,
  ISearchQuery,
  IUser,
  Lodging,
  makeBackendApi,
  MealPlanNames,
} from 'services/BackendApi';
import { BasketItem } from './BasketItem';
import { IBasketBuildL2, IBasketBuildL4, IBasketUserResponseItem } from 'services/BackendApi/types/Basket';
import classNames from 'classnames';
import * as AuthSelectors from 'store/modules/auth/selectors';
import { History } from 'history';
import { ERoutingPreviousPage } from 'utils/routingUtils';
import * as InventoryHeaderSelectors from 'store/modules/inventoryHeader/selectors';
import { isNil } from 'lodash-es';
import * as ActingOnBehalfOfSelectors from 'store/modules/actingOnBehalfOf/selectors';
import * as ActingOnBehalfOfActions from 'store/modules/actingOnBehalfOf/actions';
import { useRawUrlParams } from 'hooks/useRawUrlParams';
import * as BasketActions from 'store/modules/basket/actions';
import * as BookingBuilderActions from 'store/modules/bookingBuilder/actions';
import { convertBasketBuildToBookingBuilderFormat } from './utils';
import * as AgentsActions from 'store/modules/agents/actions';
import * as FastSearchActions from 'store/modules/fastSearch/actions';
import qs from 'qs';
import * as HotelActions from 'store/modules/hotel/actions';

interface IBasketPageParams {
  buildUuid?: string;
  actingOnBehalfOfUserUuid?: string;
  travelAgentUuid?: string;
}

const BASKET_OWNER_TYPE_TO_ABBR_MAP = {
  [EUserType.ADMIN]: 'ADM',
  [EUserType.SR]: 'RES',
  [EUserType.TA]: 'TA',
  [EUserType.FINANCE]: 'FIN',
  [EUserType.RL]: 'RL',
};

export const BasketPage: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const history = useHistory<History.LocationState>();
  const isInventoryHeaderOpen = useSelector(InventoryHeaderSelectors.isMenuOpenSelector);
  const isTa = useSelector(AuthSelectors.isTA);
  const loggedInUser = useSelector(AuthSelectors.getCurrentUser) as IUser;

  const params = useRawUrlParams<IBasketPageParams>();
  const {
    buildUuid: queryParamBuildUuid,
    travelAgentUuid: queryParamTaUuid,
    actingOnBehalfOfUserUuid: queryActingOnBehalfOfUserUuid,
  } = params;

  const actingOnBehalfOfUuid = useSelector(ActingOnBehalfOfSelectors.actingOnBehalfOfUuidSelector);
  const actingOnBehalfOfRole = useSelector(ActingOnBehalfOfSelectors.actingOnBehalfOfRoleSelector);

  const actingOnBehalfOfUser = useSelector(ActingOnBehalfOfSelectors.actingOnBehalfOfUserSelector);

  const [shouldShowLeaveAlert, setShouldShowLeaveAlert] = useState(false);
  const [redirectLocation, setRedirectLocation] = useState<string | null>(null);
  const [offset, setOffset] = useState(0);
  const [builds, setBuilds] = useState<IBasketBuildL4[]>([]);
  const [totalBuilds, setTotalBuilds] = useState<number | null>(null);

  const [getBasketRequestPageLoad, setGetBasketRequestPageLoad] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );
  const [getBasketRequestOffsetChange, setGetBasketRequestOffsetChange] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );
  const [getBasketUsersRequest, setGetBasketUsersRequest] = useState<ENetworkRequestStatus>(ENetworkRequestStatus.IDLE);

  const [getTARequest, setGetTaRequest] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );

  const [selectedBuildUuid, setSelectedBuildUuid] = useState<string | null>(queryParamBuildUuid ?? null);
  const [basketUsers, setBasketUsers] = useState<IBasketUserResponseItem[]>([]);
  const [selectedTaUserUuid, setSelectedTaUserUuid] = useState<string | null>(queryParamTaUuid ?? null);

  const backendApi = makeBackendApi();
  const selectedBasketBuild = builds.find(build => build.uuid === selectedBuildUuid);

  const selectedBasketUser = basketUsers.find(user => user.basketOwner.uuid === actingOnBehalfOfUuid);

  const isBasketOwnerDropdownDisabled =
    getBasketRequestPageLoad === ENetworkRequestStatus.PENDING ||
    getBasketRequestOffsetChange === ENetworkRequestStatus.PENDING;

  const isTaDropdownDisabled = isBasketOwnerDropdownDisabled || actingOnBehalfOfRole === EUserType.TA;

  useEffect(() => {
    if (redirectLocation) {
      history.push(redirectLocation, { comingFrom: ERoutingPreviousPage.BASKET_PAGE });
    }
  }, [redirectLocation, history]);

  // getting the basket users
  useEffect(() => {
    if (isTa) {
      return;
    }

    // if we're not selecting an owner via UUID, just use the logged in user as owner
    if (isNil(queryActingOnBehalfOfUserUuid)) {
      dispatch(
        ActingOnBehalfOfActions.setActingOnBehalfOfUserAction({
          uuid: loggedInUser.uuid,
          role: loggedInUser.type as EUserType,
        })
      );
    }

    setGetBasketUsersRequest(ENetworkRequestStatus.PENDING);
    backendApi
      .getBasketUsers()
      .then(res => {
        setGetBasketUsersRequest(ENetworkRequestStatus.SUCCESS);
        setBasketUsers(res.data);

        // if we ARE setting an owner via UUID, then we need to set the actingOnBehalfOfUser
        if (queryActingOnBehalfOfUserUuid) {
          const basketUser = res.data.find(user => user.basketOwner.uuid === queryActingOnBehalfOfUserUuid);
          if (basketUser) {
            dispatch(
              ActingOnBehalfOfActions.setActingOnBehalfOfUserAction({
                uuid: queryActingOnBehalfOfUserUuid,
                role: basketUser.basketOwner.type as EUserType,
              })
            );
            // at this point, if we're also setting a TA via URL
            if (queryParamTaUuid) {
              setSelectedTaUserUuid(queryParamTaUuid);
            } else {
              // and if we're NOT setting the TA via URL, just use the first one
              setSelectedTaUserUuid(basketUser.travelAgents[0].uuid!);
            }
          }
        } else {
          // if we're NOT setting an owner via UUID,
          // then we just set selected TA as the first one in the list for the owner
          const me = res.data.find(user => user.basketOwner.uuid === loggedInUser.uuid);
          if (me && me.travelAgents.length >= 1 && isNil(selectedTaUserUuid)) {
            setSelectedTaUserUuid(me!.travelAgents[0].uuid);
          }
        }
      })
      .catch(e => {
        setGetBasketUsersRequest(ENetworkRequestStatus.ERROR);
      });
  }, [isTa]);

  // a TA is selected then update the redux part for Agents
  // As commented with Tom, we are starting to have 2 concepts of TA
  // the TA from the BB as we used to have and now the "TA selected from dropdown basket"
  // Be aware that any of the 2 can be overwritten in that field of redux
  // This is the TAthat will be used to req BE to create a booking and to show in <BasketTravelAgentInfo/>
  useEffect(() => {
    if(!selectedTaUserUuid) {
      return;
    }
    setGetTaRequest(ENetworkRequestStatus.PENDING);
    // get the TA object for the selected ta, and put it into the agents domain
    backendApi
      .getUserByUuid(selectedTaUserUuid)
      .then(res => {
        setGetTaRequest(ENetworkRequestStatus.SUCCESS);
        const ta = res.data.data[0];
        dispatch(AgentsActions.selectedTaChangeAction(ta));
      })
      .catch(e => {
        setGetTaRequest(ENetworkRequestStatus.ERROR);
        console.error('something went wrong', e);
      });
  }, [selectedTaUserUuid]);

  // getting the basket items
  useEffect(() => {
    if (!isTa && (isNil(actingOnBehalfOfUuid) || isNil(selectedTaUserUuid))) {
      return;
    }

    offset === 0
      ? setGetBasketRequestPageLoad(ENetworkRequestStatus.PENDING)
      : setGetBasketRequestOffsetChange(ENetworkRequestStatus.PENDING);

    backendApi
      .getBasket({
        offset,
        travelAgentUuid: isTa ? undefined : selectedTaUserUuid ?? undefined,
        actingOnBehalfOfUserUuid: isTa ? undefined : actingOnBehalfOfUuid ?? undefined,
      })
      .then(async res => {
        // get photos for all the hotels...
        const hotelUuids = res.data.builds.map(build => build.initialBuildResponse.hotel.uuid);
        const uploadsForHotels = await backendApi.getPhotosForHotels(hotelUuids);

        // ...and then put a photo into the build for the hotel
        const buildsWithHotelPhotos = res.data.builds.map(build => {
          const hotelUploads = uploadsForHotels.data.data.filter(
            upload => upload.ownerUuid === build.initialBuildResponse.hotel.uuid
          );
          let hotelPhoto = hotelUploads.find(upload => upload.tag === 'featuredPhoto');
          if (!hotelPhoto) {
            hotelPhoto = hotelUploads[0];
          }
          return {
            ...build,
            hotelPhotoUrl: hotelPhoto?.url,
          };
        });

        return {
          buildsWithHotelPhotos,
          totalBuilds: res.data.total,
        };
      })
      .then(async ({ buildsWithHotelPhotos, totalBuilds }) => {
        // for each of the basket builds, we need to do a booking builder request
        // so we have a latest booking builder data
        const requests = buildsWithHotelPhotos.map(build => build.buildRequest);

        let responses;
        try {
          responses = await Promise.all(
            requests.map(request =>
              backendApi.postBookingBuilderRequest(
                request,
                undefined,
                selectedTaUserUuid!,
                status => (status >= 200 && status <= 299) || status === 422,
                actingOnBehalfOfUser ?? undefined
              )
            )
          );
        } catch (e) {
          console.error('error with responses', e);
        }

        // @ts-ignore the type is wrong - there IS a double data here
        const bookingBuilderDatas = responses.map(r => r.data?.data) as BookingBuilderResponse[];

        const basketBuildsWithBookingBuilderResponses: IBasketBuildL4[] = buildsWithHotelPhotos.map((build, index) => {
          const response = bookingBuilderDatas[index];
          return {
            ...build,
            bookingBuilderResponse: response!,
          };
        });

        setBuilds([...builds, ...basketBuildsWithBookingBuilderResponses]);
        setTotalBuilds(totalBuilds);
        offset === 0
          ? setGetBasketRequestPageLoad(ENetworkRequestStatus.SUCCESS)
          : setGetBasketRequestOffsetChange(ENetworkRequestStatus.SUCCESS);
      })
      .catch(error => {
        offset === 0
          ? setGetBasketRequestPageLoad(ENetworkRequestStatus.ERROR)
          : setGetBasketRequestOffsetChange(ENetworkRequestStatus.ERROR);
      });
  }, [isTa, actingOnBehalfOfUuid, selectedTaUserUuid, offset]);

  const onEdit = (build: IBasketBuildL4) => {
      const bookingBuild = {
        request: build.buildRequest,
        response: build.bookingBuilderResponse!,
        bookingErrors: build.bookingBuilderResponse?.errors || [],
      };

      // the startDate and endDate below need to be the booking start and end dates
      const searchQuery: ISearchQuery = {
        name: null,
        lodgings: build.buildRequest.Accommodation.map(accommodation => ({
          uuid: accommodation.uuid,
          startDate: accommodation.startDate,
          endDate: accommodation.endDate,
          guestAges: accommodation.guestAges,
          numberOfAdults: accommodation.guestAges.numberOfAdults,
          agesOfAllChildren: accommodation.guestAges.agesOfAllChildren,
          repeatCustomer: accommodation.repeatCustomer,
        })),
        startDate: build.buildRequest.startDate,
        endDate: build.buildRequest.endDate,
        mealPlanCategories: [MealPlanNames.ANY],
        regions: [],
        starRatings: [],
        filters: [],
        priceRange: {
          min: undefined,
          max: undefined,
        },
        clientCountryCode: build.clientCountryCode,
      };

      const searchQueryString = qs.stringify(searchQuery);
      dispatch(HotelActions.clearHotelAction());
      dispatch(FastSearchActions.populateQueryAction(searchQuery));
      dispatch(BookingBuilderActions.setCurrentBookingBuilderAction(bookingBuild));
      dispatch(BasketActions.setBasketBuildAction(build));

      setRedirectLocation(`/hotels/${build.buildRequest.hotelUuid}?${searchQueryString}`);

  };

  // Assume BE has done its job and removed basketBuild from basket in DB properly
  // Then just remove the view here.
  const handleRemoveBuildFromLocalList = useCallback((basketBuildUuid?: string) => {
    setBuilds(builds.filter( build => build.uuid === basketBuildUuid));
  }, []);

  return (
    <div className="basket-page container mx-auto max-w-[1800px] font-pt-sans text-black px-[20px]">
      <div className="basket-columns flex gap-[40px] mt-[20px]">
        {/* the widths are the size of the right coluimn AND the x padding of the container */}
        <div
          className={classNames('basket-left-column flex flex-col w-[calc(100%-280px)] lg:w-[calc(100%-390px)]', {
            'opacity-50 pointer-events-none': getBasketRequestOffsetChange === ENetworkRequestStatus.PENDING,
          })}
        >
          <div className="page-title-container flex items-center justify-between mb-20px">
            <h1 className={'primary-title font-noe-display text-4xl leading-46px text-black font-normal m-0'}>
              Basket <span className="text-26px leading-33">- Select Product</span>
            </h1>
          </div>

          {/* the "user" dropdowns */}
          {!isTa && getBasketUsersRequest === ENetworkRequestStatus.PENDING && <LoadingBar />}
          {!isTa && getBasketUsersRequest === ENetworkRequestStatus.ERROR && <ErrorBar />}
          {!isTa && getBasketUsersRequest === ENetworkRequestStatus.SUCCESS && (
            <div className="filters grid grid-cols-2 gap-5 mb-20px">
              <div className="flex flex-col space-y-2 w-full">
                <span className="uppercase">Basket Created by *</span>
                <span
                  className={classNames({
                    'opacity-50 pointer-events-none': isBasketOwnerDropdownDisabled,
                  })}
                >
                  <Multiselect
                    disabled={isBasketOwnerDropdownDisabled}
                    className="bg-ivory !font-hurmegeometric-sans"
                    itemsClassname="bg-ivory"
                    itemCtaClassName="hover:bg-gray-20"
                    itemContentClassName="!font-hurmegeometric-sans"
                    fontClass="!font-hurmegeometric-sans"
                    options={basketUsers.map(bu => {
                      return {
                        value: bu.basketOwner.uuid,
                        label: `${BASKET_OWNER_TYPE_TO_ABBR_MAP[bu.basketOwner.type]} - ${bu.basketOwner.firstName} ${
                          bu.basketOwner.lastName
                        }::${bu.basketOwner.type}`,
                      };
                    })}
                    isCloseOnSelect={true}
                    hideCheckboxes={true}
                    isSingleSelectMode={true}
                    optionsLabelRenderer={o => o.label.split('::')[0]}
                    selectedValuesRenderer={v => {
                      const [label, role] = v.split('::');
                      return label;
                    }}
                    onUpdate={(sv, isCustom, options) => {
                      if (sv.length <= 0) {
                        dispatch(ActingOnBehalfOfActions.setActingOnBehalfOfUserAction(null));
                        setSelectedTaUserUuid(null);
                      } else {
                        const user = options.find(o => o.value === sv[0]);
                        const [label, role] = user.label.split('::');
                        dispatch(
                          ActingOnBehalfOfActions.setActingOnBehalfOfUserAction({
                            uuid: sv[0],
                            role,
                          })
                        );
                        // if we've just selected a TA as the basket owner, we need to set the TA UUID
                        // as them too
                        if (role === EUserType.TA) {
                          setSelectedTaUserUuid(sv[0]);
                        } else {
                          setSelectedTaUserUuid(null);
                        }
                      }
                      setOffset(0);
                      setBuilds([]);
                      setSelectedBuildUuid(null);
                      setGetBasketRequestPageLoad(ENetworkRequestStatus.IDLE);
                    }}
                    selectedValues={selectedBasketUser ? [selectedBasketUser.basketOwner.uuid] : []}
                    isEnableFuzzySearch={true}
                  />
                </span>
              </div>
              <div className="flex flex-col space-y-2 w-full">
                <span className="uppercase">Travel Company - Travel Agent *</span>
                <span
                  className={classNames({
                    'opacity-50 pointer-events-none': isTaDropdownDisabled,
                  })}
                >
                  <Multiselect
                    disabled={isTaDropdownDisabled}
                    className="bg-ivory !font-hurmegeometric-sans"
                    itemsClassname="bg-ivory"
                    itemCtaClassName="hover:bg-gray-20"
                    itemContentClassName="!font-hurmegeometric-sans"
                    fontClass="!font-hurmegeometric-sans"
                    options={
                      selectedBasketUser
                        ? selectedBasketUser.travelAgents.map(ta => {
                            return {
                              value: ta.uuid,
                              label: `${ta.company.name} - ${ta.firstName} ${ta.lastName}`,
                            };
                          })
                        : []
                    }
                    isCloseOnSelect={true}
                    hideCheckboxes={true}
                    isSingleSelectMode={true}
                    onUpdate={sv => {
                      if (sv.length >= 1) {
                        setSelectedTaUserUuid(sv[0]);
                        setOffset(0);
                        setBuilds([]);
                        setSelectedBuildUuid(null);
                        setGetBasketRequestPageLoad(ENetworkRequestStatus.IDLE);
                      }
                    }}
                    selectedValues={selectedTaUserUuid ? [selectedTaUserUuid] : []}
                  />
                </span>
              </div>
            </div>
          )}

          {/* results but no results */}
          {getBasketRequestPageLoad === ENetworkRequestStatus.PENDING &&
            getBasketUsersRequest !== ENetworkRequestStatus.PENDING && <LoadingBar />}
          {getBasketRequestPageLoad === ENetworkRequestStatus.ERROR && <ErrorBar />}
          {getBasketRequestPageLoad === ENetworkRequestStatus.SUCCESS && builds.length <= 0 && (
            <div className="flex flex-col">
              <span className="text-15px leading-18px mb-20px">No results found</span>

              <Link
                className="block self-start bg-white border border-brown-prime font-hurmegeometric-sans uppercase px-10px py-[9px] text-brown-prime text-sm"
                to="/filters"
              >
                <span className="text-brown-prime">Search Products</span>
              </Link>
            </div>
          )}

          {/* results */}
          <div
            className={classNames('results flex flex-col space-y-10px mt-20px', {
              'opacity-50 pointer-events-none':
                getBasketRequestPageLoad === ENetworkRequestStatus.PENDING ||
                getTARequest === ENetworkRequestStatus.PENDING,
            })}
          >
            {getBasketRequestPageLoad === ENetworkRequestStatus.SUCCESS &&
              builds.map(build => (
                <BasketItem
                  key={build.uuid}
                  build={build}
                  isSelected={selectedBuildUuid === build.uuid}
                  onSelectBuild={uuid => {
                    setSelectedBuildUuid(uuid);
                  }}
                  onEdit={() => onEdit(build)}
                  onDeleteBuild={uuid => {
                    if (selectedBuildUuid === uuid) {
                      setSelectedBuildUuid(null); // Clean selection if deleted was selected
                    }
                    // We delete element locally rather than fetching them again
                    // in order to keep pointer and pagination information for the user
                    const remainingBuilds = builds.filter(b => b.uuid !== uuid);
                    setBuilds(remainingBuilds);
                    setTotalBuilds(totalBuilds ? totalBuilds - 1 : 0);
                  }}
                  actingOnBehalfOfUuid={actingOnBehalfOfUuid}
                  selectedTaUserUuid={selectedTaUserUuid}
                />
              ))}
          </div>

          {/* the load more button */}
          {totalBuilds && builds.length < totalBuilds && getBasketRequestPageLoad === ENetworkRequestStatus.SUCCESS && (
            <button
              className="bg-white mt-20px self-center block border border-brown-prime font-hurmegeometric-sans uppercase px-10px py-[9px] text-brown-prime text-sm items-center space-x-5px cursor-pointer min-w-[125px]"
              disabled={getBasketRequestOffsetChange === ENetworkRequestStatus.PENDING}
              onClick={() => {
                setOffset(builds.length);
              }}
            >
              {getBasketRequestOffsetChange === ENetworkRequestStatus.PENDING ? (
                <i className="fas fa-circle-notch fa-spin text-brown-140"></i>
              ) : (
                'Load More'
              )}
            </button>
          )}
        </div>

        <div
          className={classNames(
            'basket-right-column-wrapper fixed right-[20px] w-[260px] lg:w-[370px] transition-[top] duration-300',
            {
              // the odd heights are to ensure that we can always scroll down the right hand side and see everything
              // we need them because the right column is position:fixed
              'overflow-y-auto max-h-[calc(100vh-200px)] top-[190px]': isInventoryHeaderOpen,
              'overflow-y-auto max-h-[calc(100vh-150px)] top-[140px]': !isInventoryHeaderOpen,
            }
          )}
        >
          <BasketRightColumn
            basketBuild={selectedBasketBuild || null}
            shouldShowLeaveAlert={shouldShowLeaveAlert}
            setShouldShowLeaveAlert={setShouldShowLeaveAlert}
            setRedirectLocation={setRedirectLocation}
            onPostRemove={handleRemoveBuildFromLocalList}
          />
        </div>
      </div>

      <LeaveWithoutSavingModal
        title="You have made changes to this page, and not saved. If you leave this page now, these changes will be lost."
        when={shouldShowLeaveAlert}
        confirmButtonLabel="Yes"
        cancelButtonLabel="No"
      />
    </div>
  );
});
