import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IBasketBuildL2, IBasketBuildL4 } from 'services/BackendApi/types/Basket';
import { convertBasketBuildToBookingBuilderFormat } from './utils';
import { SvgIcon } from 'ui/SvgIcon';
import Star from 'ui/Icons/star.component.svg';
import CopyIcon from 'ui/Icons/copy.component.svg';
import PencilIcon from 'ui/Icons/pencil-no-circle.component.svg';
import TrashIcon from 'ui/Icons/trash-no-circle.component.svg';
import * as BookingBuilderUtils from 'utils/bookingBuilder';
import { formatDateDisplay, formatPrice, generateArrayOfDatesBetween, getCurrencySymbol } from 'utils';
import { ERoomRateType } from 'store/modules/bookingBuilder';
import { Radio } from 'ui/Radio';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { BasketItemExpandableInfo } from './BasketItemExpandableInfo';
import { useTranslation } from 'react-i18next';
import ArrowDown from 'ui/Icons/components/ArrowDown.component';
import { theme } from '../../../tailwind.config';

import { ConfirmationModal, EConfirmationModalType } from '../../ui/ConfirmationModal';
import { makeBackendApi, ENetworkRequestStatus } from 'services/BackendApi';
import { enqueueNotification } from 'store/modules/ui';

import RejectedIcon from 'ui/Icons/rejected.component.svg';
import PriorityIcon from 'ui/Icons/priority-high.component.svg';
import { HidingTooltip } from 'ui/Tooltip';
import { isNil } from 'lodash-es';
import { LodgingSummary } from 'interfaces';
import { BookingBuilder } from 'services/BackendApi';
import * as DateFNS from 'date-fns';
interface IBasketItemProps {
  build: IBasketBuildL4;
  isSelected: boolean;
  onSelectBuild: (buildUuid: string) => void;
  onEdit: () => void;
  onDeleteBuild?: (buildUuid: string) => void;
  actingOnBehalfOfUuid?: string | null | undefined;
  selectedTaUserUuid?: string | null;
}

const countString = (itemCount: number, noun: string) => {
  if (itemCount === 0) {
    return `No ${noun}s`;
  } else if (itemCount === 1) {
    return `${itemCount} ${noun}`;
  } else {
    return `${itemCount} ${noun}s`;
  }
};

const RateTypeBadge = ({ rateType }: { rateType: ERoomRateType }) => {
  switch (rateType) {
    case ERoomRateType.STATIC:
      return (
        <span className="block self-end font-hurmegeometric-sans py-2px px-5px text-black bg-white-true text-12px leading-14px uppercase">
          static rate
        </span>
      );

    case ERoomRateType.LIVE:
      return (
        <span className="block self-end font-hurmegeometric-sans py-2px px-5px text-black bg-teal-20 text-12px leading-14px uppercase">
          live rate
        </span>
      );
    default:
      return (
        <span className="block self-end font-hurmegeometric-sans py-2px px-5px text-black bg-white-true text-12px leading-14px">
          static rate
        </span>
      );
  }
};

export const BasketItem = (props: IBasketItemProps) => {
  // we add a day to the end to make it render correctly, and then _minus_ 1 from the total length
  // because we don't want to include the end date in the length (because the length is nights)
  const dispatch = useDispatch();

  const startDate = props.build.buildRequest.startDate;
  const endDate = DateFNS.addDays(new Date(props.build.buildRequest.endDate), 1);
  const lengthOfStay = generateArrayOfDatesBetween(startDate, endDate).length - 1;

  const dateRangeString = `${formatDateDisplay(startDate)} - ${formatDateDisplay(endDate)}`;
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  const accommodationCount = props.build.buildRequest.Accommodation.length;
  const transferCount = props.build.buildRequest.Transfer.length;
  const groundServiceCount = props.build.buildRequest['Ground Service'].length;

  const guestCount =
    props.build.buildRequest.guestAges.numberOfAdults + props.build.buildRequest.guestAges.agesOfAllChildren.length;

  const currencySymbol = getCurrencySymbol(props.build.initialBuildResponse.currency);

  const hasLatestBookingBuild = !isNil(props.build.bookingBuilderResponse);

  let rateType = ERoomRateType.STATIC;
  // optional chaining because the bookingBuilderResponse can be undefined
  if (props.build.bookingBuilderResponse?.potentialBooking?.Accommodation[0]?.isLiveRate) {
    rateType = ERoomRateType.LIVE;
  }

  const previousTotalCostString = props.build.initialBuildResponse.totals.oneOrMoreItemsOnRequest
    ? 'On Request'
    : currencySymbol + formatPrice(props.build.initialBuildResponse.totals.total);

  const currentTotalCostString = props.build.bookingBuilderResponse?.totals.oneOrMoreItemsOnRequest
    ? 'On Request'
    : currencySymbol + formatPrice(props.build.bookingBuilderResponse?.totals.total);

  const isOnRequest = props.build.bookingBuilderResponse?.totals.oneOrMoreItemsOnRequest;

  const isDiscounted =
    (props.build.bookingBuilderResponse?.totals.totalBeforeDiscountForPricedItemsCents || 0) >
    (props.build.bookingBuilderResponse?.totals.totalForPricedItemsCents || 0);

  const canBook = props.build.bookingBuilderResponse?.canBeBooked === true;

  const hasTotalsChanged =
    props.build.bookingBuilderResponse?.totals.total !== props.build.initialBuildResponse.totals.total ||
    props.build.bookingBuilderResponse?.totals.oneOrMoreItemsOnRequest !==
      props.build.initialBuildResponse.totals.oneOrMoreItemsOnRequest;

  const hasOffersChanged =
    props.build.bookingBuilderResponse?.appliedOfferNames.join(',') !==
    props.build.initialBuildResponse.appliedOfferNames.join(',');

  const total = formatPrice(props.build.bookingBuilderResponse?.totals.total);
  const totalBeforeDiscount = formatPrice(props.build.bookingBuilderResponse?.totals.totalBeforeDiscount);

  let bookingBuild: BookingBuilder | undefined;
  let lodgingSummaries: LodgingSummary[] = [];
  if (hasLatestBookingBuild) {
    bookingBuild = convertBasketBuildToBookingBuilderFormat(props.build);
    lodgingSummaries = BookingBuilderUtils.generateLodgingSummariesFromBooking(t, bookingBuild);
  }

  const previousOffersString = props.build.bookingBuilderResponse?.appliedOfferNames.join(', ');
  const newOffersString = props.build.bookingBuilderResponse?.appliedOfferNames.join(', ');

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [basketDeletingNetwork, setBasketDeletingNetwork] = useState(ENetworkRequestStatus.IDLE);

  const backendApi = makeBackendApi(props.selectedTaUserUuid as string | undefined);

  const handleDeleteItemClick = useCallback(() => {
    setConfirmationModalOpen(true);
  }, []);

  const handleDeleteItemConfirmation = useCallback(async () => {
    setBasketDeletingNetwork(ENetworkRequestStatus.PENDING);
    try {
      await backendApi.removeFromBasket(props.build.uuid, props.actingOnBehalfOfUuid);
      setBasketDeletingNetwork(ENetworkRequestStatus.SUCCESS);
      dispatch(
        enqueueNotification({
          message: `Item removed from basket successfully`,
          options: { variant: 'success' },
        })
      );
      if (props.onDeleteBuild) {
        props.onDeleteBuild(props.build.uuid);
      }
      setConfirmationModalOpen(false);
    } catch (error) {
      setBasketDeletingNetwork(ENetworkRequestStatus.ERROR);
      dispatch(
        enqueueNotification({
          message: `Deleting Error. Please try later`,
          options: { variant: 'error' },
        })
      );
    }
    setConfirmationModalOpen(false);
  }, [backendApi, props.build.uuid]);

  return (
    <div
      id={`basket-item-${props.build.uuid}`}
      className={classNames('flex flex-col bg-gray-3 py-15px px-20px', {
        'outline outline-teal-100': props.isSelected,
      })}
    >
      {confirmationModalOpen && (
        <ConfirmationModal
          type={EConfirmationModalType.WARNING}
          className="confirm-import-modal"
          title="You are about to remove an item from he basket."
          message={`Are you sure you want to continue?`}
          confirmButtonLabel="Yes"
          cancelButtonLabel="No"
          onConfirm={handleDeleteItemConfirmation}
          onCancel={() => {
            setConfirmationModalOpen(false);
          }}
          isConfirmLoading={false}
        />
      )}
      <div className={classNames('flex items-center justify-around space-x-20px', {})}>
        <span className="1st-col w-[25px]">
          <span onClick={() => canBook && props.onSelectBuild(props.build.uuid)} className="block ml-5px">
            <Radio
              className="!border-brown-100"
              color={theme.colors['brown-100']}
              checked={props.isSelected}
              onClick={() => canBook && props.onSelectBuild(props.build.uuid)}
            />
          </span>
        </span>
        <span className="2nd-col w-[200px] hidden lg:block">
          <img
            onClick={() => canBook && props.onSelectBuild(props.build.uuid)}
            className={classNames('w-full aspect-video', {
              'cursor-pointer': canBook,
            })}
            src={props.build.hotelPhotoUrl}
            alt="Hotel photo"
          />
        </span>
        <span className="3rd-col flex-grow">
          <span className="flex flex-row justify-between mb-10px">
            <div>
              <span className="flex items-center uppercase space-x-2 mb-5px">
                <SvgIcon IconComponent={Star} className="w-4 h-4 fill-brown-prime" />
                <span className="text-brown-prime text-12px leading-14px mt-3px">5 star</span>
              </span>
              {/* hotel name */}
              <span className="block font-noe-display text-brown-prime text-[20px] mb-5px">
                {props.build.initialBuildResponse.hotel.name}
              </span>
              {/* hotel region */}
              <span className="block font-hurmegeometric-sans text-brown-prime text-[14px] uppercase mb-15px">
                {props.build.initialBuildResponse.hotel.region}
              </span>

              <span className="flex items-center space-x-2 font-hurmegeometric-sans uppercase mb-10px">
                <span className="font-bold text-[13px] p-2px ">{dateRangeString}</span>
                <span className="bg-teal-20 text-13px p-2px">{lengthOfStay} nights</span>
                <span className="text-13px">{countString(guestCount, 'guest')}</span>
                <span className="text-13px">{countString(accommodationCount, 'room')}</span>
              </span>
              <span className="flex items-center space-x-2 font-hurmegeometric-sans uppercase">
                <span className="text-13px">{countString(transferCount, 'transfer')}</span>
                <span className="text-13px">{countString(groundServiceCount, 'ground service')}</span>
              </span>
            </div>
            <div className="flex flex-col space-y-5px">
              <span className="flex self-end items-center space-x-2">
                <div className="tool-tip icons flex space-x-2 mr-10">
                  {!canBook && (
                    <HidingTooltip
                      renderTooltipContent={() => {
                        return (
                          <div className="font-pt-sans text-13px p-2">
                            This item is no longer available and cannot be booked.
                            <br />
                            {hasLatestBookingBuild && <span>Please edit or remove the item.</span>}
                          </div>
                        );
                      }}
                      position="left"
                    >
                      <SvgIcon IconComponent={RejectedIcon} className="w-5 h-5 fill-red-95 mt-1" />
                    </HidingTooltip>
                  )}
                  {canBook && hasTotalsChanged && !hasOffersChanged && (
                    <HidingTooltip
                      renderTooltipContent={() => {
                        return (
                          <div className="font-pt-sans text-13px p-2">
                            <span className="block">The total cost has changed</span>
                            <span className="flex justify-between space-x-3">
                              <span>Previous total cost was:</span>
                              <span className="font-bold">{previousTotalCostString}</span>
                            </span>
                            <span className="flex justify-between space-x-3">
                              <span>Current total cost is:</span>
                              <span className="font-bold">{currentTotalCostString}</span>
                            </span>
                          </div>
                        );
                      }}
                      position="left"
                    >
                      <SvgIcon IconComponent={PriorityIcon} className="w-5 h-5 fill-status-requested" />
                    </HidingTooltip>
                  )}
                  {canBook && hasOffersChanged && !hasTotalsChanged && (
                    <HidingTooltip
                      renderTooltipContent={() => {
                        return (
                          <div className="font-pt-sans text-13px p-2">
                            <span className="block">The offers have changed</span>
                            <span className="block">Previous offers: {previousOffersString}</span>
                            <span className="block">New offers: {newOffersString}</span>
                          </div>
                        );
                      }}
                      position="left"
                    >
                      <SvgIcon IconComponent={PriorityIcon} className="w-5 h-5 fill-status-requested" />
                    </HidingTooltip>
                  )}

                  {canBook && hasOffersChanged && hasTotalsChanged && (
                    <HidingTooltip
                      renderTooltipContent={() => {
                        return (
                          <div className="font-pt-sans text-13px p-2">
                            <span className="block">The total cost has changed</span>
                            <span className="flex justify-between space-x-3">
                              <span>Previous total cost was:</span>
                              <span className="font-bold">{previousTotalCostString}</span>
                            </span>
                            <span className="flex justify-between space-x-3">
                              <span>Current total cost is:</span>
                              <span className="font-bold">{currentTotalCostString}</span>
                            </span>
                            <br />
                            <span className="block">Previous offers: {previousOffersString}</span>
                            <span className="block">New offers: {newOffersString}</span>
                          </div>
                        );
                      }}
                      position="left"
                    >
                      <SvgIcon IconComponent={PriorityIcon} className="w-5 h-5 fill-status-requested" />
                    </HidingTooltip>
                  )}
                </div>
                {hasLatestBookingBuild && (
                  <span
                    className="bg-transparent border-none outline-none cursor-pointer hover:bg-gray-20 rounded-full w-6 h-6 flex items-center justify-around"
                    onClick={() => {}}
                  >
                    <SvgIcon
                      IconComponent={CopyIcon}
                      width="18px"
                      height="18px"
                      className="mt-[2px] fill-brown-prime"
                    />
                  </span>
                )}

                {hasLatestBookingBuild && (
                  <button
                    className="bg-transparent border-none outline-none cursor-pointer hover:bg-gray-20 rounded-full w-6 h-6 flex items-center justify-around"
                    onClick={props.onEdit}
                  >
                    <SvgIcon IconComponent={PencilIcon} width="18px" height="18px" className="-ml-[2px]" />
                  </button>
                )}
                <span
                  className="bg-transparent border-none outline-none cursor-pointer hover:bg-gray-20 rounded-full w-6 h-6 flex items-center justify-around"
                  onClick={handleDeleteItemClick}
                >
                  <SvgIcon IconComponent={TrashIcon} width="12px" height="14px" className="-mt-[2px]" />
                </span>
              </span>

              {hasLatestBookingBuild && <RateTypeBadge rateType={rateType} />}

              {hasLatestBookingBuild && isOnRequest ? (
                <div className="flex flex-col space-y-1">
                  <span className="uppercase font-hurmegeometric-sans text-flint text-13px font-bold text-right">
                    total cost
                  </span>
                  <span className="font-hurmegeometric-sans text-black text-15px leading-20px font-[600] text-right">
                    On Request
                  </span>
                </div>
              ) : null}

              {hasLatestBookingBuild && !isDiscounted && !isOnRequest ? (
                <div className="flex flex-col space-y-1">
                  <span className="uppercase font-hurmegeometric-sans text-flint text-13px font-bold text-right">
                    total cost
                  </span>
                  <span className="uppercase font-hurmegeometric-sans text-black text-19px leading-20px font-[600] text-right">
                    {currencySymbol}
                    {formatPrice(total)}
                  </span>
                </div>
              ) : null}

              {hasLatestBookingBuild && isDiscounted && !isOnRequest ? (
                <div className="flex flex-col space-y-1">
                  <span className="uppercase font-hurmegeometric-sans text-flint text-13px font-bold text-right">
                    total cost
                  </span>
                  <span className="uppercase font-hurmegeometric-sans text-red-92 text-19px leading-20px font-[600] text-right">
                    {currencySymbol}
                    {formatPrice(total)}
                  </span>
                  <span className="uppercase font-hurmegeometric-sans text-gray-80 text-15px leading-18px text-right line-through">
                    {currencySymbol}
                    {formatPrice(totalBeforeDiscount)}
                  </span>
                </div>
              ) : null}
              {hasLatestBookingBuild && (
                <button
                  onClick={() => setIsExpanded(!isExpanded)}
                  className={classNames(
                    '!mt-auto self-end cursor-pointer flex justify-around items-center h-6 w-6 hover:bg-gray-40 active:bg-gray-40 bg-transparent border-none outline-none'
                  )}
                >
                  <SvgIcon
                    IconComponent={ArrowDown}
                    className={classNames('transition-transform w-[14px] stroke-black', {
                      'rotate-180': isExpanded,
                    })}
                  />
                </button>
              )}
            </div>
          </span>
          {/* Hiding until future ticket */}
          {/* <span className="text-brown-prime text-13px leading-16px uppercase underline font-hurmegeometric-sans">
            add commission
          </span> */}
        </span>
      </div>
      {hasLatestBookingBuild && bookingBuild && (
        <AnimateHeight duration={300} height={isExpanded ? 'auto' : 0} className={classNames('accordion-area', {})}>
          <BasketItemExpandableInfo key={props.build.uuid} booking={bookingBuild} lodgingSummaries={lodgingSummaries} />
        </AnimateHeight>
      )}
    </div>
  );
};
