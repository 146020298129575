import React, { useState, FormEvent } from 'react';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import qs from 'qs';
import StyledFastSearchContainer from './styles';
import SearchSettings from 'pureUi/SearchSettings';
import PredictiveTextInput from 'pureUi/PredictiveTextInput';
import { RangeValueType } from 'pureUi/RangeInput';
import { LodgingsEditor } from 'pureUi/LodgingsEditor/index';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Link } from 'ui/Link';

import { Heading2 } from 'styles';
import { PrimaryButton } from 'pureUi/Buttons';
import SearchResultList from 'pureUi/SearchResultsList';
import DateRangeInput from 'pureUi/DateRangeInput';
import Checkbox from 'pureUi/Checkbox';
import { IWithTravelAgentsDataProps, withTravelAgentsData } from 'hoc/WithTravelAgentsData';
import { clearBookingBuilderAction } from 'store/modules/bookingBuilder';
import { DatePickerStateProvider, IDatePickerSateParams } from 'pureUi/providers/DatePickerStateProvider';
import { Multiselect } from 'ui/Multiselect';
import { getBootstrapCountriesSelector } from 'store/modules/bootstrap/selectors';

import * as FastSearchActions from 'store/modules/fastSearch/actions';
import * as FastSearchSelectors from 'store/modules/fastSearch/selectors';
import { theme } from '../../../tailwind.config';

import { isInternalUser } from 'store/modules/auth';
import { selectedTaSelector } from 'store/modules/agents/selectors';
import { TCountryCode } from 'interfaces';
import SingleSelect from 'ui/SingleSelect';
import { ESortOrder } from 'store/common/types';
import RadioButton from 'pureUi/RadioButton';
import { StandardModal } from 'pureUi/Modal';
import { ICompanyMembership } from 'services/BookingManagerApi/types/CompanyMemberships';
import InfoIcon from 'ui/Icons/components/Info.component';
import { SvgIcon } from 'ui/SvgIcon';
import { ModalV1 } from 'ui/ModalV1';
import { Helmet } from 'react-helmet';
import { isEqual } from 'lodash-es';
import { ParameterService } from 'services/ParametersProviderApi/ParametersService';
import * as AgentsActions from 'store/modules/agents/actions';
import * as AgentsSelectors from 'store/modules/agents/selectors';

const searchResultsSortOptions = [
  { label: 'Ascending', value: ESortOrder.ASC },
  { label: 'Descending', value: ESortOrder.DESC },
];

export class FastSearchContainer extends React.PureComponent<
  FastSearchProps,
  {
    membershipInModal: ICompanyMembership | null;
  }
> {
  constructor(props) {
    super(props);
    this.state = { membershipInModal: null };
  }

  componentDidMount() {
    if (!this.props.searchOptions) {
      this.props.getOptions();
    }

    let searchInitiated = false;
    // Automatically execute the URL search query
    if (window.location.search && (!this.props.searchResults || this.props.searchResults.length <= 0)) {
      this.props.initializeQuery(window.location.search.replace('?', ''));
      searchInitiated = true;
    }

    // The UI Back button does not go "back" in history, so we lose the
    // Query String info. This condition puts it back
    if (!window.location.search && this.props.searchResults) {
      this.props.updateQueryString();
    }

    if (searchInitiated) {
      // all other cases initiate search, so avoid double fetches during mount.
      return;
    } else {
      if (!window.location.search && !this.props.searchResults) {
        this.fetchAvailableOffers(this.props.searchQuery);
        clearBookingBuilderAction();
      }

      // If the user has search results, then navigates back to the homepage,
      // edits the query, and hit's search again, trigger a search
      if (this.props.queryHasChanged) {
        this.fetchAvailableOffers(this.props.searchQuery);
        clearBookingBuilderAction();
      }
    }

    // similar to what we do in src/pages/Dashboard/Dashboard.jsx - if we have a selected company,
    // fetch the memberships, so that if a user changes TC inside the booking builder, if they come back
    // it'll be correct
    if (this.props.selectedCompany?.uuid) {
      this.props.getMembershipsRequestAction(this.props.selectedCompany.uuid, false, false);
    }
  }

  componentDidUpdate(prevProps) {
    const dynamicParameters = ParameterService.getParameters();
    const prevCompanyCode = prevProps.selectedCompany ? prevProps.selectedCompany.countryCode : undefined;
    const newCompanyCode = this.props.selectedCompany ? this.props.selectedCompany.countryCode : undefined;

    if (newCompanyCode && newCompanyCode !== prevCompanyCode) {
      this.fetchAvailableOffers(this.props.searchQuery);
    }

    // OWA-5182
    if (dynamicParameters.WOOPRA_DOMAIN && this.props.searchResults) {
      const prevUuids = prevProps.searchResults ? prevProps.searchResults.map(r => r.uuid) : [];
      const uuids = this.props.searchResults.map(r => r.uuid);
      if (!isEqual(prevUuids, uuids)) {
        // Avoid action track for every update, only when results are different
        // Yes, top10 might be the same, but it's a different result
        const top10 = uuids.slice(0, 10);
        // @ts-ignore
        window.woopra &&
          // @ts-ignore
          window.woopra.track('search-outcome', {
            total: uuids.length,
            top10: top10.join(','),
          });
      }
    }
  }

  // ----------------------

  fetchAvailableOffers(query) {
    const { getOffers } = this.props;
    getOffers(query);
  }

  handleDestinationChange = (e: FormEvent<HTMLInputElement>) => {
    this.props.destinationChange(e.currentTarget.value);
  };

  handleSubmit = () => {
    this.fetchAvailableOffers(this.props.searchQuery);
    clearBookingBuilderAction();
  };

  handleToggleLodgingControls = () => {
    this.props.toggleLodgingControls();
  };

  handleSetLogdingControlsVisibility = (visible: boolean) => () => {
    this.props.setLodgingControlsVisibility(visible);
  };

  handleShowNameSearchDropDown = (visible: boolean) => () => {
    this.props.setNamesSearchResultsVisibility(visible);
  };

  handleShowTravelAgentsDropDown = (visible: boolean) => () => {
    this.props.showTaDropdownChange(visible);
  };

  handleShowCompaniesDropDown = (visible: boolean) => () => {
    this.props.showCompanyDropdownChange(visible);
  };

  closeMembershipModal = () => {
    this.setState({ membershipInModal: null });
  };

  // ---------------------------------------------------

  handleRemoveAllFilters = () => {
    this.props.setAllFilters(false);
  };

  handlePriceRangeChange = (type: RangeValueType, value: string) => {
    const action = type === 'min' ? this.props.minPriceChange : this.props.maxPriceChange;

    if (!isNaN(parseInt(value))) {
      action(parseInt(value, 10));
    }

    if (isNaN(parseInt(value))) {
      action(undefined);
    }
  };

  canSearch = () => (this.props.isSr ? this.props.canSrSearch && this.props.canSearch : this.props.canSearch);

  handleSearchResultClick = (hotelUuid: string, hotelName: string) => {
    const urlParams = qs.parse(window.location.search.slice(1));
    if (this.props.selectedTa?.uuid) {
      urlParams.tauuid = this.props.selectedTa.uuid;
    }
    urlParams.name = hotelName;
    const url = `/hotels/${hotelUuid}?${qs.stringify(urlParams)}`;
    this.props.history.push(url);
  };

  handleSearchResultsSortingChange = (newSortOrder: ESortOrder) => {
    this.props.setSortOrder(newSortOrder);
  };

  renderSideBar = () => {
    const dynamicParameters = ParameterService.getParameters();
    return (
      <div className="sidebar">
        {this.props.isSr && (
          <div className="sidebar-group mb-1rem p-18px bg-gray-light">
            <label className="basicSearchLabel">
              <span>Company</span>
              {this.props.isFetchingCompanies ? (
                <span>Loading companies...</span>
              ) : (
                <PredictiveTextInput
                  placeholder="Select company..."
                  value={this.props.companyNameSearch}
                  onChange={e => this.props.searchCompanyByName(e.currentTarget.value)}
                  options={[this.props.companiesNames]}
                  onOptionSelect={this.props.handleCompanyNameChange}
                  showDropDown={this.props.showCompanyDropdown}
                  onFocus={this.handleShowCompaniesDropDown(true)}
                  onBlur={this.handleShowCompaniesDropDown(false)}
                />
              )}
            </label>
            {this.props.selectedCompany && (
              <label className="basicSearchLabel">
                <span>Travel Agent</span>
                {this.props.isFetchingTA ? (
                  <span>Loading travel agents...</span>
                ) : (
                  <PredictiveTextInput
                    placeholder="Select agent..."
                    value={this.props.taNameSearch}
                    onChange={e => this.props.searchTaByName(e.currentTarget.value)}
                    options={[this.props.taNames]}
                    onOptionSelect={this.props.handleTaNameChange}
                    showDropDown={this.props.showTaDropdown}
                    onFocus={this.handleShowTravelAgentsDropDown(true)}
                    onBlur={this.handleShowTravelAgentsDropDown(false)}
                  />
                )}
              </label>
            )}
            <label className="basicSearchLabel">
              <span>Guest country</span>
              <Multiselect
                className={'guest-country bg-white font-hurmegeometric-sans'}
                placeholderText="SELECT COUNTRY"
                borderColourClass="border-gray-17"
                itemsClassname="uppercase text-black"
                itemContentClassName="uppercase text-black"
                fontClass="font-hurmegeometric-sans"
                itemCtaClassName="uppercase bg-white hover:bg-blue-5"
                openClassName="outline outline-2 outline-teal-100"
                placeholderClasses="not-italic text-gray-15"
                height={37}
                onUpdate={sv => {
                  if (sv[0]) {
                    this.props.guestCountryChangeAction(sv[0] as TCountryCode);
                  } else {
                    this.props.guestCountryChangeAction(null);
                  }
                }}
                hideCheckboxes={true}
                isSingleSelectMode={true}
                isCloseOnSelect={true}
                isEnableFuzzySearch={true}
                fuzzySearchInputActiveClass="bg-white"
                options={this.props.getBootstrapCountries.map(country => ({
                  value: country.code,
                  label: country.name,
                }))}
                selectedValues={
                  this.props.searchQuery.clientCountryCode ? [this.props.searchQuery.clientCountryCode] : []
                }
              />
            </label>
          </div>
        )}

        <div className="sidebar-group mb-1rem p-18px bg-gray-light">
          {this.props.optionsRequestPending && <h2>Options Loading</h2>}
          {!this.props.optionsRequestPending &&
            this.props.optionsRequestError &&
            Array.isArray(this.props.optionsRequestError.errors) && (
              <h2>{this.props.optionsRequestError.errors.map(e => e.title)}</h2>
            )}

          <label className="basicSearchLabel">
            <span>Destination or Resort</span>
            <PredictiveTextInput
              placeholder="Where to"
              value={this.props.searchQuery.name! || ''}
              inputClassName="destination-or-resort"
              onChange={this.handleDestinationChange}
              options={this.props.nameSearchResults}
              onOptionSelect={this.props.destinationChange}
              showDropDown={this.props.showNameSearchResults}
              onFocus={this.handleShowNameSearchDropDown(true)}
              onBlur={this.handleShowNameSearchDropDown(false)}
            />
          </label>

          <label className="basicSearchLabel">
            <span>Rooms *</span>
            <LodgingsEditor
              className="lodging-editor"
              showControls={this.props.showLodgingControls}
              lodgings={this.props.searchQuery.lodgings}
              activeLodgingIndex={this.props.activeLodingIndex}
              onIncrementIndex={this.props.incrementActiveLodgingIndex}
              onTabSelect={this.props.setActiveLodgingIndex}
              onIncrementRoomCount={this.props.incrementRoom}
              onIncrementAdultCount={this.props.incrementAdult}
              onIncrementChildCount={this.props.incrementChild}
              onChildAgeChange={this.props.setAge}
              totalGuestCount={this.props.totalGuestCount}
              onClick={this.handleToggleLodgingControls}
              onClickOutside={this.handleSetLogdingControlsVisibility(false)}
            />
          </label>

          <label className="basicSearchLabel">
            <span>Dates *</span>
            <DatePickerStateProvider
              defaultSelectedDates={this.props.selectedDates}
              onDateChange={this.props.dateRangeChangeAction}
              render={(params: IDatePickerSateParams) => (
                <DateRangeInput
                  displayString={params.displayString}
                  currentDate={params.datePickerCurrentDate}
                  totalNights={params.totalNights}
                  selectedDates={params.selectedDates}
                  onDayClick={params.handleDayClick}
                  onDayMouseOver={params.handleDateMouseOver}
                  showDatePicker={params.showDatePicker}
                  onNextClick={params.incrementDate}
                  onPrevClick={params.decrementDate}
                  onMouseDown={params.toggleDatePicker}
                  onClickOutside={params.hideDatePicker}
                />
              )}
            />
          </label>

          {dynamicParameters.TA_USE_CLIENT_COUNTRY_CODE && (
            <label className="basicSearchLabel">
              <span>Guest country</span>
              <Multiselect
                className={'guest-country bg-white font-hurmegeometric-sans'}
                placeholderText="SELECT COUNTRY"
                borderColourClass="border-gray-17"
                itemsClassname="uppercase text-black"
                itemContentClassName="uppercase text-black"
                fontClass="font-hurmegeometric-sans"
                itemCtaClassName="uppercase bg-white hover:bg-blue-5"
                openClassName="outline outline-2 outline-teal-100"
                placeholderClasses="not-italic text-gray-15"
                height={37}
                onUpdate={sv => {
                  if (sv[0]) {
                    this.props.guestCountryChangeAction(sv[0] as TCountryCode);
                  } else {
                    this.props.guestCountryChangeAction(null);
                  }
                }}
                hideCheckboxes={true}
                isSingleSelectMode={true}
                isCloseOnSelect={true}
                isEnableFuzzySearch={true}
                fuzzySearchInputActiveClass="bg-white"
                options={this.props.getBootstrapCountries.map(country => ({
                  value: country.code,
                  label: country.name,
                }))}
                selectedValues={
                  this.props.searchQuery.clientCountryCode ? [this.props.searchQuery.clientCountryCode] : []
                }
              />
            </label>
          )}

          {this.props.isSr && (
            <label className="basicSearchLabel repeatGuest">
              <span className="label">Repeat Guest</span>
              <Checkbox checked={this.props.isRepeatGuest} onChange={this.props.toggleRepeatGuest} />
            </label>
          )}

          {this.props.companyMemberships && this.props.companyMemberships.length >= 1 && (
            <div>
              <span className="uppercase text-xs block mb-10px text-flint">Membership</span>
              <div className="flex flex-row flex-wrap gap-10px">
                {this.props.companyMemberships.map(membership => {
                  return (
                    <div key={membership.uuid} className="inline-flex flex-row items-center space-x-8px">
                      <RadioButton
                        name="company-membership"
                        type="radio"
                        checked={membership.uuid === this.props.selectedCompanyMembership?.uuid}
                        onChange={() => {
                          this.props.setSelectedCompanyMembership(membership);
                        }}
                      />
                      <span className="font-hurmegeometric-sans text-black-legacy text-sm font-normal space-x-5px flex items-center">
                        <span>{membership.name}</span>
                        {membership.benefitsDescription && (
                          <button
                            onClick={() => {
                              this.setState({ membershipInModal: membership });
                            }}
                            className="show-membership-info-modal bg-none bg-transparent outline-none border-non p-0 m-0 cursor-pointer"
                          >
                            <SvgIcon
                              IconComponent={InfoIcon}
                              defaultFill={theme.colors['gray-40']}
                              hoverFill={theme.colors['gray-80']}
                              activeFill={theme.colors['gray-40']}
                              width="18px"
                              height="18px"
                            />
                          </button>
                        )}
                      </span>
                    </div>
                  );
                })}
                {this.state.membershipInModal && (
                  <ModalV1
                    className="membership-modal min-w-[500px] max-w-[500px]"
                    title={this.state.membershipInModal.name}
                    message={this.state.membershipInModal.benefitsDescription ?? ''}
                    onClose={this.closeMembershipModal}
                  />
                )}
              </div>
            </div>
          )}

          <PrimaryButton className="searchButton" disabled={!this.canSearch()} onClick={this.handleSubmit}>
            Search
          </PrimaryButton>
        </div>

        {this.props.searchOptions && (
          <SearchSettings
            options={this.props.searchOptions}
            query={this.props.searchQuery}
            showRegions={this.props.showRegions}
            onFilterChange={this.props.toggleFilter}
            onStarRatingChange={this.props.toggleStarRating}
            onOccasionChange={this.props.toggleOccasion}
            onMealPlanChange={this.props.selectMealPlan}
            onToggleShowRegions={this.props.toggleShowRegions}
            onRegionChange={this.props.toggleRegion}
            canSubmit={this.props.searchPending}
            onRemoveAllFilters={this.handleRemoveAllFilters}
            onPriceRangeChange={this.handlePriceRangeChange}
            onSubmit={this.handleSubmit}
          />
        )}
        <div className="sidebar-group mb-1rem p-18px bg-gray-light">
          <PrimaryButton className="searchButton" disabled={!this.canSearch()} onClick={this.handleSubmit}>
            Search
          </PrimaryButton>
        </div>
      </div>
    );
  };

  renderResults = () => {
    if (this.props.searchPending) {
      return null;
    }

    if (this.props.offersRequestError) {
      // @ts-ignore
      const errorStatusCode = this.props.offersRequestError?.response?.data?.errors[0].status || null;

      if (errorStatusCode) {
        return (
          <h3 className="search-error-message">
            There was an error with the search, please try again or check your search options ({errorStatusCode})
          </h3>
        );
      } else {
        return (
          <h3 className="search-error-message">
            There was an error with the search, please try again or check your search options
          </h3>
        );
      }
    } else if (this.props.searchResults === null || this.props.searchResults.length <= 0) {
      return <h3>No results</h3>;
    }

    return (
      <SearchResultList
        searchResults={this.props.searchResults}
        expandedHighlights={this.props.expandedHighlights}
        onToggleHighlights={this.props.toggleHighlights}
        onNavigateToHotel={this.handleSearchResultClick}
      />
    );
  };

  render() {
    if (!this.props.searchOptions) {
      return null;
    }

    let searchResultsLength = 0;
    if (this.props.searchResults && this.props.searchResults.length >= 1) {
      searchResultsLength = this.props.searchResults.length;
    }

    const dynamicParameters = ParameterService.getParameters();
    const homepage = dynamicParameters.ENABLE_INVENTORY_WORKSPACES ? '/filters' : '/';

    return (
      <StyledFastSearchContainer>
        <Link to={homepage} className="backButton flex flex-row items-center">
          <i className="fas fa-chevron-left w-18px h-18px text-center pt-2px bg-gray-20 mr-2"></i>
          Back to Homepage
        </Link>
        {this.props.searchPending && <Heading2 className="heading mt-0">Loading...</Heading2>}
        {!this.props.searchPending && (
          <div className="search-results-heading-container flex justify-between items-center">
            <Heading2 className="search-results-heading heading mt-0">Search Results {searchResultsLength}</Heading2>
            <SingleSelect
              fieldId="search-results-sorting-dropdown"
              className="search-results-sorting-dropdown w-150px"
              value={this.props.searchResultsSortOrder}
              options={searchResultsSortOptions}
              onChange={this.handleSearchResultsSortingChange}
            />
          </div>
        )}
        <div className="sideBar">{this.renderSideBar()}</div>

        <div className="searchResults">
          <div className="resultsGrid">{this.renderResults()}</div>
        </div>
      </StyledFastSearchContainer>
    );
  }
}

// -----------------------------------------------------------------------------
// Prop Typings
// -----------------------------------------------------------------------------
export type StateToProps = ReturnType<typeof mapStateToProps>;
export type DispatchToProps = typeof actionCreators;
export interface FastSearchProps
  extends StateToProps,
    DispatchToProps,
    RouteComponentProps,
    IWithTravelAgentsDataProps {
  className: string;
}

const mapStateToProps = createStructuredSelector({
  optionsRequestPending: FastSearchSelectors.searchOptionsPendingSelector,
  optionsRequestError: FastSearchSelectors.searchOptionsErrorSelector,
  offersRequestError: FastSearchSelectors.searchOffersRequestErrorSelector,
  searchOptions: FastSearchSelectors.searchOptionsSelector,
  searchPending: FastSearchSelectors.offersSearchPendingSelector,
  searchResults: FastSearchSelectors.orderedSearchResults,
  searchQuery: FastSearchSelectors.offersQuerySelector,
  originalSearchQuery: FastSearchSelectors.offersOriginalQuerySelector,
  activeFilters: FastSearchSelectors.activeFiltersSelector,
  priceRange: FastSearchSelectors.priceRangeSelector,
  showRegions: FastSearchSelectors.showRegionsSelector,
  activeLodingIndex: FastSearchSelectors.activeLodingIndexSelector,
  expandedHighlights: FastSearchSelectors.expandedHighlightsSelector,
  totalGuestCount: FastSearchSelectors.totalGuestCountSelector,
  showLodgingControls: FastSearchSelectors.showLodgingControlsSelector,
  nameSearchResults: FastSearchSelectors.nameSearchResultsSelector,
  showNameSearchResults: FastSearchSelectors.showNameSearchResultsSelector,
  selectedDates: FastSearchSelectors.selectedDatesSelector,
  isRepeatGuest: FastSearchSelectors.isRepeatGuestSelector,
  queryHasChanged: FastSearchSelectors.queryHasChangedSelector,
  canSearch: FastSearchSelectors.canSearchSelector,
  getBootstrapCountries: getBootstrapCountriesSelector,
  isSr: isInternalUser,
  selectedTa: selectedTaSelector,
  searchResultsSortOrder: FastSearchSelectors.searchResultsSortOrderSelector,
});

const actionCreators = {
  getOptions: FastSearchActions.optionsRequestAction,
  getOffers: FastSearchActions.offersSearchRequestAction,
  toggleFilter: FastSearchActions.toggleFilterAction,
  setAllFilters: FastSearchActions.setAllFiltersAction,
  minPriceChange: FastSearchActions.minPriceChangeAction,
  maxPriceChange: FastSearchActions.maxPriceChangeAction,
  toggleStarRating: FastSearchActions.toggleStarRatingAction,
  toggleOccasion: FastSearchActions.toggleOccasionAction,
  selectMealPlan: FastSearchActions.selectMealPlanAction,
  toggleShowRegions: FastSearchActions.toggleShowRegionsAction,
  toggleRegion: FastSearchActions.toggleRegionAction,
  destinationChange: FastSearchActions.destinationChangeAction,
  incrementRoom: FastSearchActions.incrementRoomAction,
  incrementAdult: FastSearchActions.incrementAdultAction,
  incrementChild: FastSearchActions.incrementChildAction,
  setAge: FastSearchActions.setAgeAction,
  setActiveLodgingIndex: FastSearchActions.setActiveLodgingIndexAction,
  toggleHighlights: FastSearchActions.toggleHighlightsAction,
  incrementActiveLodgingIndex: FastSearchActions.incrementActiveLodgingIndexAction,
  toggleLodgingControls: FastSearchActions.toggleLodgingControlsAction,
  setLodgingControlsVisibility: FastSearchActions.setLodgingControlsVisibilityAction,
  setNamesSearchResultsVisibility: FastSearchActions.setNamesSearchResultsVisibilityAction,
  initializeQuery: FastSearchActions.initializeQueryAction,
  toggleRepeatGuest: FastSearchActions.toggleRepeatGuestAction,
  clearBookingBuilderAction,

  dateRangeChangeAction: FastSearchActions.dateRangeChangeAction,
  updateQueryString: FastSearchActions.updateQueryStringAction,
  guestCountryChangeAction: FastSearchActions.clientCountryCodeChangeAction,
  setSortOrder: FastSearchActions.setSortOrderAction,
  getMembershipsRequestAction: AgentsActions.getMembershipsRequestAction,
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

// -----------------------------------------------------------------------------
// Connected
// -----------------------------------------------------------------------------
const withConnect = connect<StateToProps, DispatchToProps, FastSearchProps>(mapStateToProps, mapDispatchToProps);

export const FastSearchContainerConnected = compose(
  withConnect,
  withRouter,
  withTravelAgentsData()
)(FastSearchContainer);
