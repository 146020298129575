import {
  IHeadlineLineItemBreakdown,
  IHeadlineLineItemBreakdownAccommodationLineItem,
  IHeadlineBreakdownVersionListVersion,
  IInvoiceAddressee,
} from 'services/BookingManagerApi';
import {
  ICancellationPolicies,
  IOfferTerms,
  IPaymentTerms,
  IPoliciesAndTerms,
  IInvoiceDueDate,
  IManualInvoiceDueDate,
  IPaymentMethod,
  IAncillaryData,
  IHeadlineLineItemBreakdownLineItem,
  IHeadlineLineItemBreakdownLineItemCustomData,
} from 'services/BookingManagerApi/types';
import { EBankAccount, TCountryCode, TCurrencyCode } from 'interfaces';
import {
  EPdfLogo,
  EInvoiceType,
  EInvoiceAddresseeType,
  EInvoiceMutationMode,
  ILiveCancellationModalDataAccom,
  ILiveAccommodationData,
  ILiveAccommodationCancellationPolicy,
  EInvoiceLang,
  IBookingManagerBreakdownSubdomain,
} from './model';
import { ICompanyMembership } from 'services/BookingManagerApi/types/CompanyMemberships';
import { SuppliersSearchItem } from 'services/BookingManagerApi/types/AncillaryService';

export const GET_HEADLINE_LINE_ITEM_BREAKDOWN_REQUEST = 'bookingManager/GET_HEADLINE_LINE_ITEM_BREAKDOWN_REQUEST';
export const GET_HEADLINE_LINE_ITEM_BREAKDOWN_SUCCESS = 'bookingManager/GET_HEADLINE_LINE_ITEM_BREAKDOWN_SUCCESS';
export const GET_HEADLINE_LINE_ITEM_BREAKDOWN_FAILURE = 'bookingManager/GET_HEADLINE_LINE_ITEM_BREAKDOWN_FAILURE';

// updating line items
export const SET_HEADLINE_LINE_ITEM_FIELD = 'bookingManager/SET_HEADLINE_LINE_ITEM_FIELD';
export const ADD_HEADLINE_LINE_ITEM = 'bookingManager/ADD_HEADLINE_LINE_ITEM';
export const REMOVE_HEADLINE_LINE_ITEM = 'bookingManager/REMOVE_HEADLINE_LINE_ITEM';
export const SET_IS_ACCOMMODATION_MODIFIED = 'bookingManager/SET_IS_ACCOMMODATION_MODIFIED';
export const REPLACE_HEADLINE_LINE_ITEM = 'bookingManager/REPLACE_HEADLINE_LINE_ITEM';

export const SET_HEADLINE_LINE_ITEM_BREAKDOWN_NOTES = 'bookingManager/SET_HEADLINE_LINE_ITEM_BREAKDOWN_NOTES';
export const SET_HEADLINE_LINE_ITEM_BREAKDOWN_MARGIN_PERCENTAGE =
  'bookingManager/SET_HEADLINE_LINE_ITEM_BREAKDOWN_MARGIN_PERCENTAGE';

// saving the breakdown
export const SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_INTENT = 'bookingManager/SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_INTENT';
export const SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_REQUEST = 'bookingManager/SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_REQUEST';
export const SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_SUCCESS = 'bookingManager/SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_SUCCESS';
export const SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_FAILURE = 'bookingManager/SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_FAILURE';

// download the breakdown for TA
export const DOWNLOAD_BREAKDOWN_FOR_TA_REQUEST = 'bookingManager/DOWNLOAD_BREAKDOWN_FOR_TA_REQUEST';
export const DOWNLOAD_BREAKDOWN_FOR_TA_SUCCESS = 'bookingManager/DOWNLOAD_BREAKDOWN_FOR_TA_SUCCESS';
export const DOWNLOAD_BREAKDOWN_FOR_TA_FAILURE = 'bookingManager/DOWNLOAD_BREAKDOWN_FOR_TA_FAILURE';

// modifying holds
export const SET_BREAKDOWN_HOLD = 'bookingManager/SET_BREAKDOWN_HOLD';

export const SET_COMPANY_LOGO = 'bookingManager/SET_COMPANY_LOGO';

// work for versions
export const GET_HEADLINE_BREAKDOWN_VERSION_LIST_REQUEST = 'bookingManager/GET_HEADLINE_BREAKDOWN_VERSION_LIST_REQUEST';
export const GET_HEADLINE_BREAKDOWN_VERSION_LIST_SUCCESS = 'bookingManager/GET_HEADLINE_BREAKDOWN_VERSION_LIST_SUCCESS';
export const GET_HEADLINE_BREAKDOWN_VERSION_LIST_FAILURE = 'bookingManager/GET_HEADLINE_BREAKDOWN_VERSION_LIST_FAILURE';
export const SET_HEADLINE_BREAKDOWN_SELECTED_VERSION = 'bookingManager/SET_HEADLINE_BREAKDOWN_SELECTED_VERSION';
export const SET_HEADLINE_BREAKDOWN_REAL_LATEST_VERSION = 'bookingManager/SET_HEADLINE_BREAKDOWN_REAL_LATEST_VERSION';

// download the breakdown for client
export const DOWNLOAD_BREAKDOWN_FOR_CLIENT_REQUEST = 'bookingManager/DOWNLOAD_BREAKDOWN_FOR_CLIENT_REQUEST';
export const DOWNLOAD_BREAKDOWN_FOR_CLIENT_SUCCESS = 'bookingManager/DOWNLOAD_BREAKDOWN_FOR_CLIENT_SUCCESS';
export const DOWNLOAD_BREAKDOWN_FOR_CLIENT_FAILURE = 'bookingManager/DOWNLOAD_BREAKDOWN_FOR_CLIENT_FAILURE';

// activities OWA-6783
export const CLOSE_ADD_ACTIVITY_MODAL = 'bookingManager/CLOSE_ADD_ACTIVITY_MODAL';
export const OPEN_ADD_ACTIVITY_MODAL = 'bookingManager/OPEN_ADD_ACTIVITY_MODAL';

// transfers 7180
export const ADD_ANCILLARY_TRANSFERS_MODAL_TOGGLE = 'bookingManager/ADD_ANCILLARY_TRANSFERS_MODAL_TOGGLE';

// ground services 7125
export const ADD_ANCILLARY_GROUND_SERVICE_MODAL_TOGGLE = 'bookingManager/ADD_ANCILLARY_GROUND_SERVICE_MODAL_TOGGLE';

// create invoices
export const OPEN_CREATE_INVOICE_MODAL = 'bookingManager/OPEN_CREATE_INVOICE_MODAL';
export const CLOSE_CREATE_INVOICE_MODAL = 'bookingManager/CLOSE_CREATE_INVOICE_MODAL';
export const OPEN_ADDRESSEE_MODAL = 'bookingManager/OPEN_ADDRESSEE_MODAL';
export const CLOSE_ADDRESSEE_MODAL = 'bookingManager/CLOSE_ADDRESSEE_MODAL';
export const SET_INVOICE_BANK_ACCOUNT = 'bookingManager/SET_INVOICE_BANK_ACCOUNT';
export const SET_INVOICE_ADDRESSEE_TYPE = 'bookingManager/SET_INVOICE_ADDRESSEE_TYPE';
export const SET_INVOICE_LANG = 'bookingManager/SET_INVOICE_LANG';
export const GENERATE_INVOICE_REQUEST = 'bookingManager/GENERATE_INVOICE_REQUEST';
export const GENERATE_INVOICE_SUCCESS = 'bookingManager/GENERATE_INVOICE_SUCCESS';
export const GENERATE_INVOICE_FAILURE = 'bookingManager/GENERATE_INVOICE_FAILURE';

// create manual invoices
export const OPEN_CREATE_MANUAL_INVOICE_MODAL = 'bookingManager/OPEN_CREATE_MANUAL_INVOICE_MODAL';
export const CLOSE_CREATE_MANUAL_INVOICE_MODAL = 'bookingManager/CLOSE_CREATE_MANUAL_INVOICE_MODAL';
export const OPEN_ADDRESSEE_MANUAL_MODAL = 'bookingManager/OPEN_ADDRESSEE_MANUAL_MODAL';
export const CLOSE_ADDRESSEE_MANUAL_MODAL = 'bookingManager/CLOSE_ADDRESSEE_MANUAL_MODAL';
export const SET_MANUAL_INVOICE_BANK_ACCOUNT = 'bookingManager/SET_MANUAL_INVOICE_BANK_ACCOUNT';
export const SET_MANUAL_INVOICE_ADDRESSEE_TYPE = 'bookingManager/SET_MANUAL_INVOICE_ADDRESSEE_TYPE';
export const SET_MANUAL_INVOICE_LANG = 'bookingManager/SET_MANUAL_INVOICE_LANG';
export const GENERATE_MANUAL_INVOICE_REQUEST = 'bookingManager/GENERATE_MANUAL_INVOICE_REQUEST';
export const GENERATE_MANUAL_INVOICE_SUCCESS = 'bookingManager/GENERATE_MANUAL_INVOICE_SUCCESS';
export const GENERATE_MANUAL_INVOICE_FAILURE = 'bookingManager/GENERATE_MANUAL_INVOICE_FAILURE';

export const SET_IS_CREATE_QUOTE_MODAL_OPEN = 'bookingManager/SET_IS_CREATE_QUOTE_MODAL_OPEN';

export const GET_PAYMENT_TERMS_REQUEST = 'bookingManager/GET_PAYMENT_TERMS_REQUEST';
export const GET_PAYMENT_TERMS_SUCCESS = 'bookingManager/GET_PAYMENT_TERMS_SUCCESS';
export const GET_PAYMENT_TERMS_FAILURE = 'bookingManager/GET_PAYMENT_TERMS_FAILURE';
export const GET_CANCELLATION_POLICIES_REQUEST = 'bookingManager/GET_CANCELLATION_POLICIES_REQUEST';
export const GET_CANCELLATION_POLICIES_SUCCESS = 'bookingManager/GET_CANCELLATION_POLICIES_SUCCESS';
export const GET_CANCELLATION_POLICIES_FAILURE = 'bookingManager/GET_CANCELLATION_POLICIES_FAILURE';
export const GET_OFFER_TERMS_REQUEST = 'bookingManager/GET_OFFER_TERMS_REQUEST';
export const GET_OFFER_TERMS_SUCCESS = 'bookingManager/GET_OFFER_TERMS_SUCCESS';
export const GET_OFFER_TERMS_FAILURE = 'bookingManager/GET_OFFER_TERMS_FAILURE';

export const GET_MEMBERSHIP_REQUEST = 'bookingManager/GET_MEMBERSHIP_REQUEST';
export const GET_MEMBERSHIP_SUCCESS = 'bookingManager/GET_MEMBERSHIP_SUCCESS';
export const GET_MEMBERSHIP_FAILURE = 'bookingManager/GET_MEMBERSHIP_FAILURE';

export const SET_MEMBERSHIP_BENEFITS_DESCRIPTION = 'bookingManager/SET_MEMBERSHIP_BENEFITS_DESCRIPTION';

export const UPDATE_MEMBERSHIP_REQUEST = 'bookingManager/UPDATE_MEMBERSHIP_REQUEST';
export const UPDATE_MEMBERSHIP_SUCCESS = 'bookingManager/UPDATE_MEMBERSHIP_SUCCESS';
export const UPDATE_MEMBERSHIP_FAILURE = 'bookingManager/UPDATE_MEMBERSHIP_FAILURE';

export const SET_PAYMENT_TERMS = 'bookingManager/SET_PAYMENT_TERMS';
export const SET_CANCELLATION_POLICIES = 'bookingManager/SET_CANCELLATION_POLICIES';
export const SET_OFFER_TERMS = 'bookingManager/SET_OFFER_TERMS';

export const UPDATE_POLICIES_AND_TERMS_REQUEST = 'bookingManager/UPDATE_POLICIES_AND_TERMS_REQUEST';
export const UPDATE_POLICIES_AND_TERMS_SUCCESS = 'bookingManager/UPDATE_POLICIES_AND_TERMS_SUCCESS';
export const UPDATE_POLICIES_AND_TERMS_FAILURE = 'bookingManager/UPDATE_POLICIES_AND_TERMS_FAILURE';

export const GET_POLICIES_AND_RESTRICTIONS_REQUEST = 'bookingManager/GET_POLICIES_AND_RESTRICTIONS_REQUEST';
export const GET_POLICIES_AND_RESTRICTIONS_SUCCESS = 'bookingManager/GET_POLICIES_AND_RESTRICTIONS_SUCCESS';
export const GET_POLICIES_AND_RESTRICTIONS_FAILURE = 'bookingManager/GET_POLICIES_AND_RESTRICTIONS_FAILURE';

export const SET_POLICIES_AND_RESTRICTIONS = 'bookingManager/SET_POLICIES_AND_RESTRICTIONS';

export const SET_IS_BREAKDOWN_EDITED_WITHOUT_SAVING = 'bookingManager/SET_IS_BREAKDOWN_EDITED_WITHOUT_SAVING';

export const GET_INVOICE_ADDRESSEE_REQUEST = 'bookingManager/GET_INVOICE_ADDRESSEE_REQUEST';
export const GET_INVOICE_ADDRESSEE_SUCCESS = 'bookingManager/GET_INVOICE_ADDRESSEE_SUCCESS';
export const GET_INVOICE_ADDRESSEE_FAILURE = 'bookingManager/GET_INVOICE_ADDRESSEE_FAILURE';

export const GET_MANUAL_INVOICE_ADDRESSE_REQUEST = 'bookingManager/GET_MANUAL_INVOICE_ADDRESSE_REQUEST';
export const GET_MANUAL_INVOICE_ADDRESSE_SUCCESS = 'bookingManager/GET_MANUAL_INVOICE_ADDRESSE_SUCCESS';
export const GET_MANUAL_INVOICE_ADDRESSE_FAILURE = 'bookingManager/GET_MANUAL_INVOICE_ADDRESSE_FAILURE';

export const SAVE_MANUAL_INVOICE_ADDRESSEE_REQUEST = 'bookingManager/SAVE_MANUAL_INVOICE_ADDRESSEE_REQUEST';
export const SAVE_MANUAL_INVOICE_ADDRESSEE_SUCCESS = 'bookingManager/SAVE_MANUAL_INVOICE_ADDRESSEE_SUCCESS';
export const SAVE_MANUAL_INVOICE_ADDRESSEE_FAILURE = 'bookingManager/SAVE_MANUAL_INVOICE_ADDRESSEE_FAILURE';

export const SAVE_INVOICE_ADDRESSEE_REQUEST = 'bookingManager/SAVE_INVOICE_ADDRESSEE_REQUEST';
export const SAVE_INVOICE_ADDRESSEE_SUCCESS = 'bookingManager/SAVE_INVOICE_ADDRESSEE_SUCCESS';
export const SAVE_INVOICE_ADDRESSEE_FAILURE = 'bookingManager/SAVE_INVOICE_ADDRESSEE_FAILURE';

export const OPEN_PCR_DETAILS_MODAL = 'bookingManager/OPEN_PCR_DETAILS_MODAL';
export const CLOSE_PCR_DETAILS_MODAL = 'bookingManager/CLOSE_PCR_DETAILS_MODAL';

export const OPEN_SAVE_TO_PERFORM_ACTION_MODAL = 'bookingManager/OPEN_SAVE_TO_PERFORM_ACTION_MODAL';
export const CLOSE_SAVE_TO_PERFORM_ACTION_MODAL = 'bookingManager/CLOSE_SAVE_TO_PERFORM_ACTION_MODAL';

export const ADD_HEADLINE_LINE_ITEM_ACCOMMODATION = 'bookingManager/ADD_HEADLINE_LINE_ITEM_ACCOMMODATION';

export const UPDATE_BAR_COMMISSION_PERCENTAGE = 'bookingManager/UPDATE_BAR_COMMISSION_PERCENTAGE';
export const UPDATE_BAR_COMMISSION_AMOUNT = 'bookingManager/UPDATE_BAR_COMMISSION_AMOUNT';
export const SET_BAR_COMMISSION_ERRORS = 'bookingManager/SET_BAR_COMMISSION_ERRORS';

export const GET_BAR_COMMISSION_REQUEST = 'bookingManager/GET_BAR_COMMISSION_REQUEST';
export const GET_BAR_COMMISSION_SUCCESS = 'bookingManager/GET_BAR_COMMISSION_SUCCESS';
export const GET_BAR_COMMISSION_FAILURE = 'bookingManager/GET_BAR_COMMISSION_FAILURE';

export const SET_CURRENTLY_EDITING_PRODUCT_DATA = 'bookingManagerBreakdown/SET_CURRENTLY_EDITING_PRODUCT_DATA';

// live accom cancel modal stuff
export const SET_LIVE_ACCOMMODATION_CANCELLATION_MODAL_IS_OPEN =
  'bookingManager/SET_LIVE_ACCOMMODATION_CANCELLATION_MODAL_IS_OPEN';

export const SET_LIVE_ACCOMMODATION_CANCELLATION_WARNING_MODAL_IS_OPEN =
  'bookingManager/SET_LIVE_ACCOMMODATION_CANCELLATION_WARNING_MODAL_IS_OPEN';

export const RESET_LIVE_ACCOMMODATION_CANCELLATION_MODAL = 'bookingManager/RESET_LIVE_ACCOMMODATION_CANCELLATION_MODAL';

// adding new accommodations into the modal data
export const ADD_LIVE_ACCOMMODATION_CANCELLATION_MODAL_ACCOM =
  'bookingManager/ADD_LIVE_ACCOMMODATION_CANCELLATION_MODAL_ACCOM';

// adding ALL accommodations into the modal data
export const ADD_ALL_LIVE_ACCOMMODATION_CANCELLATION_MODAL_ACCOMS =
  'bookingManager/ADD_ALL_LIVE_ACCOMMODATION_CANCELLATION_MODAL_ACCOMS';

// updating an accommodation
export const SET_LIVE_ACCOMMODATION_CANCELLATION_MODAL_ACCOM_DATA =
  'bookingManager/SET_LIVE_ACCOMMODATION_CANCELLATION_MODAL_ACCOM_DATA';

// actually requesting the DATA for the live accommodations
export const GET_LIVE_ACCOMMODATION_CANCELLATION_DATA_REQUEST =
  'bookingManager/GET_LIVE_ACCOMMODATION_CANCELLATION_DATA_REQUEST';
export const GET_LIVE_ACCOMMODATION_CANCELLATION_DATA_SUCCESS =
  'bookingManager/GET_LIVE_ACCOMMODATION_CANCELLATION_DATA_SUCCESS';
export const GET_LIVE_ACCOMMODATION_CANCELLATION_DATA_FAILURE =
  'bookingManager/GET_LIVE_ACCOMMODATION_CANCELLATION_DATA_FAILURE';
export const SET_LIVE_ACCOMMODATION_CANCELLATION_POLICIES =
  'bookingManager/SET_LIVE_ACCOMMODATION_CANCELLATION_POLICIES';

// actually requesting the cancellation(s)
export const POST_LIVE_ACCOMMODATION_CANCELLATION_REQUEST =
  'bookingManager/POST_LIVE_ACCOMMODATION_CANCELLATION_REQUEST';
export const POST_LIVE_ACCOMMODATION_CANCELLATION_SUCCESS =
  'bookingManager/POST_LIVE_ACCOMMODATION_CANCELLATION_SUCCESS';
export const POST_LIVE_ACCOMMODATION_CANCELLATION_FAILURE =
  'bookingManager/POST_LIVE_ACCOMMODATION_CANCELLATION_FAILURE';

export const GET_PAYMENT_METHODS_REQUEST = 'bookingManager/GET_PAYMENT_METHODS_REQUEST';
export const GET_PAYMENT_METHODS_SUCCESS = 'bookingManager/GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAILURE = 'bookingManager/GET_PAYMENT_METHODS_FAILURE';

// suppliers stuff
export const GET_SUPPLIERS_REQUEST = 'bookingManager/GET_SUPPLIERS_REQUEST';
export const GET_SUPPLIERS_SUCCESS = 'bookingManager/GET_SUPPLIERS_SUCCESS';
export const GET_SUPPLIERS_FAILURE = 'bookingManager/GET_SUPPLIERS_FAILURE';

export type GetHeadlineLineItemBreakdownRequestAction = ReturnType<typeof getHeadlineLineItemBreakdownRequestAction>;
export const getHeadlineLineItemBreakdownRequestAction = () => ({
  type: GET_HEADLINE_LINE_ITEM_BREAKDOWN_REQUEST as typeof GET_HEADLINE_LINE_ITEM_BREAKDOWN_REQUEST,
});

export type GetHeadlineLineItemBreakdownSuccessAction = ReturnType<typeof getHeadlineLineItemBreakdownSuccessAction>;
export const getHeadlineLineItemBreakdownSuccessAction = (
  headlineLineItemBreakdown: IHeadlineLineItemBreakdown,
  headlineLineItemBreakdownUpdatedAt: string | null = null
) => ({
  type: GET_HEADLINE_LINE_ITEM_BREAKDOWN_SUCCESS as typeof GET_HEADLINE_LINE_ITEM_BREAKDOWN_SUCCESS,
  headlineLineItemBreakdown,
  headlineLineItemBreakdownUpdatedAt,
});

export type GetHeadlineLineItemBreakdownFailureAction = ReturnType<typeof getHeadlineLineItemBreakdownFailureAction>;
export const getHeadlineLineItemBreakdownFailureAction = (error: string) => ({
  type: GET_HEADLINE_LINE_ITEM_BREAKDOWN_FAILURE as typeof GET_HEADLINE_LINE_ITEM_BREAKDOWN_FAILURE,
  error,
});

export interface iSetHeadlineLineItemFieldActionOptions {
  isAnAccommodationArrivalOrDepartureEdit: boolean;
}
export type SetHeadlineLineItemFieldAction = ReturnType<typeof setHeadlineLineItemFieldAction>;
export const setHeadlineLineItemFieldAction = (setPath: string, newValue: any) => ({
  type: SET_HEADLINE_LINE_ITEM_FIELD as typeof SET_HEADLINE_LINE_ITEM_FIELD,
  setPath,
  newValue,
});

export type AddHeadlineLineItemAction = ReturnType<typeof addHeadlineLineItemAction>;
export const addHeadlineLineItemAction = (
  addPath: string,
  initialData?: Partial<IHeadlineLineItemBreakdownLineItem>,
  ancillaryRequestData?: Partial<IAncillaryData>,
  customRequestData?: Partial<IHeadlineLineItemBreakdownLineItemCustomData>,
  atIndex?: number
) => ({
  type: ADD_HEADLINE_LINE_ITEM as typeof ADD_HEADLINE_LINE_ITEM,
  addPath,
  initialData,
  ancillaryRequestData,
  customRequestData,
  atIndex,
});

export type ReplaceHeadlineLineItemAction = ReturnType<typeof replaceHeadlineLineItemAction>;
export const replaceHeadlineLineItemAction = (
  path: string,
  index: number,
  newLineItem?: Partial<IHeadlineLineItemBreakdownLineItem>
) => ({
  type: REPLACE_HEADLINE_LINE_ITEM as typeof REPLACE_HEADLINE_LINE_ITEM,
  path,
  index,
  newLineItem,
});

export type RemoveHeadlineLineItemAction = ReturnType<typeof removeHeadlineLineItemAction>;
export const removeHeadlineLineItemAction = (removePath: string, index: number) => ({
  type: REMOVE_HEADLINE_LINE_ITEM as typeof REMOVE_HEADLINE_LINE_ITEM,
  removePath,
  index,
});

export type SetHeadlineLineItemBreakdownNotesAction = ReturnType<typeof setHeadlineLineItemBreakdownNotesAction>;
export const setHeadlineLineItemBreakdownNotesAction = (newNotes: string) => ({
  type: SET_HEADLINE_LINE_ITEM_BREAKDOWN_NOTES as typeof SET_HEADLINE_LINE_ITEM_BREAKDOWN_NOTES,
  newNotes,
});

export type SetHeadlineLineItemBreakdownMarginPercentageAction = ReturnType<
  typeof setHeadlineLineItemBreakdownMarginPercentageAction
>;
export const setHeadlineLineItemBreakdownMarginPercentageAction = (newMargin: number) => ({
  type: SET_HEADLINE_LINE_ITEM_BREAKDOWN_MARGIN_PERCENTAGE as typeof SET_HEADLINE_LINE_ITEM_BREAKDOWN_MARGIN_PERCENTAGE,
  newMargin,
});

export type SaveAndUpdateHeadlineBreakdownIntentAction = ReturnType<typeof saveAndUpdateHeadlineBreakdownIntentAction>;
export const saveAndUpdateHeadlineBreakdownIntentAction = () => ({
  type: SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_INTENT as typeof SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_INTENT,
});

export type SaveAndUpdateHeadlineBreakdownRequestAction = ReturnType<
  typeof saveAndUpdateHeadlineBreakdownRequestAction
>;
export const saveAndUpdateHeadlineBreakdownRequestAction = () => ({
  type: SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_REQUEST as typeof SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_REQUEST,
});

export type SaveAndUpdateHeadlineBreakdownSuccessAction = ReturnType<
  typeof saveAndUpdateHeadlineBreakdownSuccessAction
>;
export const saveAndUpdateHeadlineBreakdownSuccessAction = (headlineLineItemBreakdown: IHeadlineLineItemBreakdown) => ({
  type: SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_SUCCESS as typeof SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_SUCCESS,
  headlineLineItemBreakdown,
});

export type SaveAndUpdateHeadlineBreakdownFailureAction = ReturnType<
  typeof saveAndUpdateHeadlineBreakdownFailureAction
>;
export const saveAndUpdateHeadlineBreakdownFailureAction = (error: string) => ({
  type: SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_FAILURE as typeof SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_FAILURE,
  error,
});

export type DownloadBreakdownForTARequestAction = ReturnType<typeof downloadBreakdownForTARequestAction>;
export const downloadBreakdownForTARequestAction = () => ({
  type: DOWNLOAD_BREAKDOWN_FOR_TA_REQUEST as typeof DOWNLOAD_BREAKDOWN_FOR_TA_REQUEST,
});

export type DownloadBreakdownForTASuccessAction = ReturnType<typeof downloadBreakdownForTASuccessAction>;
export const downloadBreakdownForTASuccessAction = () => ({
  type: DOWNLOAD_BREAKDOWN_FOR_TA_SUCCESS as typeof DOWNLOAD_BREAKDOWN_FOR_TA_SUCCESS,
});

export type DownloadBreakdownForTAFailureAction = ReturnType<typeof downloadBreakdownForTAFailureAction>;
export const downloadBreakdownForTAFailureAction = (error: string) => ({
  type: DOWNLOAD_BREAKDOWN_FOR_TA_FAILURE as typeof DOWNLOAD_BREAKDOWN_FOR_TA_FAILURE,
  error,
});

export type SetBreakdownHoldAction = ReturnType<typeof setBreakdownHoldAction>;
export const setBreakdownHoldAction = (
  accommodationIndex: number,
  hold?: IHeadlineLineItemBreakdownAccommodationLineItem['hold']
) => ({
  type: SET_BREAKDOWN_HOLD as typeof SET_BREAKDOWN_HOLD,
  hold,
  accommodationIndex,
});

export type SetBreakdownLogoAction = ReturnType<typeof setBreakdownLogoAction>;
export const setBreakdownLogoAction = (logo: EPdfLogo) => ({
  type: SET_COMPANY_LOGO as typeof SET_COMPANY_LOGO,
  logo,
});

export type GetHeadlineBreakdownVersionListRequestAction = ReturnType<
  typeof getHeadlineBreakdownVersionListRequestAction
>;
export const getHeadlineBreakdownVersionListRequestAction = () => ({
  type: GET_HEADLINE_BREAKDOWN_VERSION_LIST_REQUEST as typeof GET_HEADLINE_BREAKDOWN_VERSION_LIST_REQUEST,
});

export type GetHeadlineBreakdownVersionListSuccessAction = ReturnType<
  typeof getHeadlineBreakdownVersionListSuccessAction
>;
export const getHeadlineBreakdownVersionListSuccessAction = (
  headlineBreakdownVersionList: IHeadlineBreakdownVersionListVersion[]
) => ({
  type: GET_HEADLINE_BREAKDOWN_VERSION_LIST_SUCCESS as typeof GET_HEADLINE_BREAKDOWN_VERSION_LIST_SUCCESS,
  headlineBreakdownVersionList,
});

export type GetHeadlineBreakdownVersionListFailureAction = ReturnType<
  typeof getHeadlineBreakdownVersionListFailureAction
>;
export const getHeadlineBreakdownVersionListFailureAction = (error: string) => ({
  type: GET_HEADLINE_BREAKDOWN_VERSION_LIST_FAILURE as typeof GET_HEADLINE_BREAKDOWN_VERSION_LIST_FAILURE,
  error,
});

export type SetHeadlineBreakdownSelectedVersionAction = ReturnType<typeof setHeadlineBreakdownSelectedVersionAction>;
export const setHeadlineBreakdownSelectedVersionAction = (selectedVersion: IHeadlineBreakdownVersionListVersion) => ({
  type: SET_HEADLINE_BREAKDOWN_SELECTED_VERSION as typeof SET_HEADLINE_BREAKDOWN_SELECTED_VERSION,
  selectedVersion,
});

export type SetHeadlineBreakdownRealLatestVersionAction = ReturnType<
  typeof setHeadlineBreakdownRealLatestVersionAction
>;
export const setHeadlineBreakdownRealLatestVersionAction = (
  realLatestVersion: IHeadlineBreakdownVersionListVersion
) => ({
  type: SET_HEADLINE_BREAKDOWN_REAL_LATEST_VERSION as typeof SET_HEADLINE_BREAKDOWN_REAL_LATEST_VERSION,
  realLatestVersion,
});

export type DownloadBreakdownForClientRequestAction = ReturnType<typeof downloadBreakdownForClientRequestAction>;
export const downloadBreakdownForClientRequestAction = () => ({
  type: DOWNLOAD_BREAKDOWN_FOR_CLIENT_REQUEST as typeof DOWNLOAD_BREAKDOWN_FOR_CLIENT_REQUEST,
});

export type DownloadBreakdownForClientSuccessAction = ReturnType<typeof downloadBreakdownForClientSuccessAction>;
export const downloadBreakdownForClientSuccessAction = () => ({
  type: DOWNLOAD_BREAKDOWN_FOR_CLIENT_SUCCESS as typeof DOWNLOAD_BREAKDOWN_FOR_CLIENT_SUCCESS,
});

export type DownloadBreakdownForClientFailureAction = ReturnType<typeof downloadBreakdownForClientFailureAction>;
export const downloadBreakdownForClientFailureAction = (error: string) => ({
  type: DOWNLOAD_BREAKDOWN_FOR_CLIENT_FAILURE as typeof DOWNLOAD_BREAKDOWN_FOR_CLIENT_FAILURE,
  error,
});

export type OpenCreateInvoiceModalAction = ReturnType<typeof openCreateInvoiceModalAction>;
export const openCreateInvoiceModalAction = (
  mode: EInvoiceMutationMode,
  hotelCurrency: TCurrencyCode,
  travelAgentCompanyCountryCode: TCountryCode,
  hotelCountryCode: TCountryCode
) => ({
  type: OPEN_CREATE_INVOICE_MODAL as typeof OPEN_CREATE_INVOICE_MODAL,
  mode,
  hotelCurrency,
  travelAgentCompanyCountryCode,
  hotelCountryCode,
});

export type CloseCreateInvoiceModalAction = ReturnType<typeof closeCreateInvoiceModalAction>;
export const closeCreateInvoiceModalAction = () => ({
  type: CLOSE_CREATE_INVOICE_MODAL as typeof CLOSE_CREATE_INVOICE_MODAL,
});

export type OpenCreateManualInvoiceModalAction = ReturnType<typeof openCreateManualInvoiceModalAction>;
export const openCreateManualInvoiceModalAction = (
  mode: EInvoiceMutationMode,
  hotelCurrency: TCurrencyCode,
  travelAgentCompanyCountryCode: TCountryCode,
  hotelCountryCode: TCountryCode
) => ({
  type: OPEN_CREATE_MANUAL_INVOICE_MODAL as typeof OPEN_CREATE_MANUAL_INVOICE_MODAL,
  mode,
  hotelCurrency,
  travelAgentCompanyCountryCode,
  hotelCountryCode,
});

// OWA-6783
export type CloseAddActivityModalAction = ReturnType<typeof closeAddActivityModalAction>;
export const closeAddActivityModalAction = () => ({
  type: CLOSE_ADD_ACTIVITY_MODAL as typeof CLOSE_ADD_ACTIVITY_MODAL,
});

export type OpenAddActivityModalAction = ReturnType<typeof openAddActivityModalAction>;
export const openAddActivityModalAction = () => ({
  type: OPEN_ADD_ACTIVITY_MODAL as typeof OPEN_ADD_ACTIVITY_MODAL,
});

export type SetAddAncillaryTransferModalToggleAction = ReturnType<typeof setAddAncillaryTransferModalToggleAction>;
export const setAddAncillaryTransferModalToggleAction = (isOpen: boolean) => ({
  type: ADD_ANCILLARY_TRANSFERS_MODAL_TOGGLE as typeof ADD_ANCILLARY_TRANSFERS_MODAL_TOGGLE,
  isOpen,
});

export type SetAddAncillaryGroundServiceModalToggleAction = ReturnType<
  typeof setAddAncillaryGroundServiceModalToggleAction
>;
export const setAddAncillaryGroundServiceModalToggleAction = (isOpen: boolean) => ({
  type: ADD_ANCILLARY_GROUND_SERVICE_MODAL_TOGGLE as typeof ADD_ANCILLARY_GROUND_SERVICE_MODAL_TOGGLE,
  isOpen,
});

export type CloseCreateManualInvoiceModalAction = ReturnType<typeof closeCreateManualInvoiceModalAction>;
export const closeCreateManualInvoiceModalAction = () => ({
  type: CLOSE_CREATE_MANUAL_INVOICE_MODAL as typeof CLOSE_CREATE_MANUAL_INVOICE_MODAL,
});

export type OpenAddresseeManualModalAction = ReturnType<typeof openAddresseeManualModalAction>;
export const openAddresseeManualModalAction = () => ({
  type: OPEN_ADDRESSEE_MANUAL_MODAL as typeof OPEN_ADDRESSEE_MANUAL_MODAL,
});

export type CloseAddresseeManualModalAction = ReturnType<typeof closeAddresseeManualModalAction>;
export const closeAddresseeManualModalAction = () => ({
  type: CLOSE_ADDRESSEE_MANUAL_MODAL as typeof CLOSE_ADDRESSEE_MANUAL_MODAL,
});

export type OpenAddresseeModalAction = ReturnType<typeof openAddresseeModalAction>;
export const openAddresseeModalAction = () => ({
  type: OPEN_ADDRESSEE_MODAL as typeof OPEN_ADDRESSEE_MODAL,
});

export type CloseAddresseeModalAction = ReturnType<typeof closeAddresseeModalAction>;
export const closeAddresseeModalAction = () => ({
  type: CLOSE_ADDRESSEE_MODAL as typeof CLOSE_ADDRESSEE_MODAL,
});

export type SetInvoiceBankAccountAction = ReturnType<typeof setInvoiceBankAccountAction>;
export const setInvoiceBankAccountAction = (bankAccount: EBankAccount) => ({
  type: SET_INVOICE_BANK_ACCOUNT as typeof SET_INVOICE_BANK_ACCOUNT,
  bankAccount,
});

export type SetInvoiceAddresseeTypeAction = ReturnType<typeof setInvoiceAddresseeTypeAction>;
export const setInvoiceAddresseeTypeAction = (invoiceAddresseeType: EInvoiceAddresseeType) => ({
  type: SET_INVOICE_ADDRESSEE_TYPE as typeof SET_INVOICE_ADDRESSEE_TYPE,
  invoiceAddresseeType,
});

export type SetManualInvoiceBankAccountAction = ReturnType<typeof setManualInvoiceBankAccountAction>;
export const setManualInvoiceBankAccountAction = (bankAccount: EBankAccount) => ({
  type: SET_MANUAL_INVOICE_BANK_ACCOUNT as typeof SET_MANUAL_INVOICE_BANK_ACCOUNT,
  bankAccount,
});

export type SetManualInvoiceAddresseeTypeAction = ReturnType<typeof setManualInvoiceAddresseeTypeAction>;
export const setManualInvoiceAddresseeTypeAction = (invoiceAddresseeType: EInvoiceAddresseeType) => ({
  type: SET_MANUAL_INVOICE_ADDRESSEE_TYPE as typeof SET_MANUAL_INVOICE_ADDRESSEE_TYPE,
  invoiceAddresseeType,
});

export type SetManualInvoiceLangAction = ReturnType<typeof setManualInvoiceLangAction>;
export const setManualInvoiceLangAction = (lang: EInvoiceLang) => ({
  type: SET_MANUAL_INVOICE_LANG as typeof SET_MANUAL_INVOICE_LANG,
  lang,
});

export type SetInvoiceLangAction = ReturnType<typeof setInvoiceLangAction>;
export const setInvoiceLangAction = (lang: EInvoiceLang) => ({
  type: SET_INVOICE_LANG as typeof SET_INVOICE_LANG,
  lang,
});

export type GenerateInvoiceRequestAction = ReturnType<typeof generateInvoiceRequestAction>;
export const generateInvoiceRequestAction = (
  invoiceType: EInvoiceType,
  bankAccount: EBankAccount,
  invoiceDueDates?: IInvoiceDueDate[]
) => ({
  type: GENERATE_INVOICE_REQUEST as typeof GENERATE_INVOICE_REQUEST,
  invoiceType,
  bankAccount,
  invoiceDueDates,
});

export type GenerateManualInvoiceRequestAction = ReturnType<typeof generateManualInvoiceRequestAction>;
export const generateManualInvoiceRequestAction = (
  bankAccount: EBankAccount,
  invoiceDueDate: IManualInvoiceDueDate,
  cancellationPolicyText: string,
  description?: string
) => ({
  type: GENERATE_MANUAL_INVOICE_REQUEST as typeof GENERATE_MANUAL_INVOICE_REQUEST,
  bankAccount,
  invoiceDueDate,
  cancellationPolicyText,
  description,
});

export type GenerateManualInvoiceSuccessAction = ReturnType<typeof generateManualInvoiceSuccessAction>;
export const generateManualInvoiceSuccessAction = () => ({
  type: GENERATE_MANUAL_INVOICE_SUCCESS as typeof GENERATE_MANUAL_INVOICE_SUCCESS,
});

export type GenerateManualInvoiceFailureAction = ReturnType<typeof generateManualInvoiceFailureAction>;
export const generateManualInvoiceFailureAction = (error: string) => ({
  type: GENERATE_MANUAL_INVOICE_FAILURE as typeof GENERATE_MANUAL_INVOICE_FAILURE,
  error,
});

export type GenerateInvoiceSuccessAction = ReturnType<typeof generateInvoiceSuccessAction>;
export const generateInvoiceSuccessAction = () => ({
  type: GENERATE_INVOICE_SUCCESS as typeof GENERATE_INVOICE_SUCCESS,
});

export type GenerateInvoiceFailureAction = ReturnType<typeof generateInvoiceFailureAction>;
export const generateInvoiceFailureAction = (error: string) => ({
  type: GENERATE_INVOICE_FAILURE as typeof GENERATE_INVOICE_FAILURE,
  error,
});

export type SetIsCreateQuoteModalOpenAction = ReturnType<typeof setIsCreateQuoteModalOpenAction>;
export const setIsCreateQuoteModalOpenAction = (value: boolean) => ({
  type: SET_IS_CREATE_QUOTE_MODAL_OPEN as typeof SET_IS_CREATE_QUOTE_MODAL_OPEN,
  value,
});

export type GetPaymentTermsRequestAction = ReturnType<typeof getPaymentTermsRequestAction>;
export const getPaymentTermsRequestAction = () => ({
  type: GET_PAYMENT_TERMS_REQUEST as typeof GET_PAYMENT_TERMS_REQUEST,
});

export type GetPaymentTermsSuccessAction = ReturnType<typeof getPaymentTermsSuccessAction>;
export const getPaymentTermsSuccessAction = (paymentTerms: IPaymentTerms) => ({
  type: GET_PAYMENT_TERMS_SUCCESS as typeof GET_PAYMENT_TERMS_SUCCESS,
  paymentTerms,
});

export type GetPaymentTermsFailureAction = ReturnType<typeof getPaymentTermsFailureAction>;
export const getPaymentTermsFailureAction = (error: string) => ({
  type: GET_PAYMENT_TERMS_FAILURE as typeof GET_PAYMENT_TERMS_FAILURE,
  error,
});

export type GetCancellationPoliciesRequestAction = ReturnType<typeof getCancellationPoliciesRequestAction>;
export const getCancellationPoliciesRequestAction = () => ({
  type: GET_CANCELLATION_POLICIES_REQUEST as typeof GET_CANCELLATION_POLICIES_REQUEST,
});

export type GetCancellationPoliciesSuccessAction = ReturnType<typeof getCancellationPoliciesSuccessAction>;
export const getCancellationPoliciesSuccessAction = (cancellationPolicy: ICancellationPolicies) => ({
  type: GET_CANCELLATION_POLICIES_SUCCESS as typeof GET_CANCELLATION_POLICIES_SUCCESS,
  cancellationPolicy,
});

export type GetCancellationPoliciesFailureAction = ReturnType<typeof getCancellationPoliciesFailureAction>;
export const getCancellationPoliciesFailureAction = (error: string) => ({
  type: GET_CANCELLATION_POLICIES_FAILURE as typeof GET_CANCELLATION_POLICIES_FAILURE,
  error,
});

export type GetOfferTermsRequestAction = ReturnType<typeof getOfferTermsRequestAction>;
export const getOfferTermsRequestAction = () => ({
  type: GET_OFFER_TERMS_REQUEST as typeof GET_OFFER_TERMS_REQUEST,
});

export type GetOfferTermsSuccessAction = ReturnType<typeof getOfferTermsSuccessAction>;
export const getOfferTermsSuccessAction = (offerTerms: IOfferTerms) => ({
  type: GET_OFFER_TERMS_SUCCESS as typeof GET_OFFER_TERMS_SUCCESS,
  offerTerms,
});

export type GetOfferTermsFailureAction = ReturnType<typeof getOfferTermsFailureAction>;
export const getOfferTermsFailureAction = (error: string) => ({
  type: GET_OFFER_TERMS_FAILURE as typeof GET_OFFER_TERMS_FAILURE,
  error,
});

export type GetMembershipRequestAction = ReturnType<typeof getMembershipRequestAction>;
export const getMembershipRequestAction = () => ({
  type: GET_MEMBERSHIP_REQUEST as typeof GET_MEMBERSHIP_REQUEST,
});

export type GetMembershipSuccessAction = ReturnType<typeof getMembershipSuccessAction>;
export const getMembershipSuccessAction = (membership: ICompanyMembership | null) => ({
  type: GET_MEMBERSHIP_SUCCESS as typeof GET_MEMBERSHIP_SUCCESS,
  membership,
});

export type GetMembershipFailureAction = ReturnType<typeof getMembershipFailureAction>;
export const getMembershipFailureAction = (error: string) => ({
  type: GET_MEMBERSHIP_FAILURE as typeof GET_MEMBERSHIP_FAILURE,
  error,
});

export type SetMembershipBenefitsDescriptionAction = ReturnType<typeof setMembershipBenefitsDescriptionAction>;
export const setMembershipBenefitsDescriptionAction = (text: string) => ({
  type: SET_MEMBERSHIP_BENEFITS_DESCRIPTION as typeof SET_MEMBERSHIP_BENEFITS_DESCRIPTION,
  text,
});

export type UpdateMembershipRequestAction = ReturnType<typeof updateMembershipRequestAction>;
export const updateMembershipRequestAction = () => ({
  type: UPDATE_MEMBERSHIP_REQUEST as typeof UPDATE_MEMBERSHIP_REQUEST,
});

export type UpdateMembershipSuccessAction = ReturnType<typeof updateMembershipSuccessAction>;
export const updateMembershipSuccessAction = (membership: ICompanyMembership | null) => ({
  type: UPDATE_MEMBERSHIP_SUCCESS as typeof UPDATE_MEMBERSHIP_SUCCESS,
  membership,
});

export type UpdateMembershipFailureAction = ReturnType<typeof updateMembershipFailureAction>;
export const updateMembershipFailureAction = (error: string) => ({
  type: UPDATE_MEMBERSHIP_FAILURE as typeof UPDATE_MEMBERSHIP_FAILURE,
  error,
});

export type SetPaymentTermsAction = ReturnType<typeof setPaymentTermsAction>;
export const setPaymentTermsAction = (text: string) => ({
  type: SET_PAYMENT_TERMS as typeof SET_PAYMENT_TERMS,
  text,
});

export type SetCancellationPoliciesAction = ReturnType<typeof setCancellationPoliciesAction>;
export const setCancellationPoliciesAction = (text: string) => ({
  type: SET_CANCELLATION_POLICIES as typeof SET_CANCELLATION_POLICIES,
  text,
});

export type SetOfferTermsAction = ReturnType<typeof setOfferTermsAction>;
export const setOfferTermsAction = (text: string) => ({
  type: SET_OFFER_TERMS as typeof SET_OFFER_TERMS,
  text,
});

export type UpdatePoliciesAndTermsRequestAction = ReturnType<typeof updatePoliciesAndTermsRequestAction>;
export const updatePoliciesAndTermsRequestAction = () => ({
  type: UPDATE_POLICIES_AND_TERMS_REQUEST as typeof UPDATE_POLICIES_AND_TERMS_REQUEST,
});

export type UpdatePoliciesAndTermsSuccessAction = ReturnType<typeof updatePoliciesAndTermsSuccessAction>;
export const updatePoliciesAndTermsSuccessAction = (policiesAndTerms: IPoliciesAndTerms) => ({
  type: UPDATE_POLICIES_AND_TERMS_SUCCESS as typeof UPDATE_POLICIES_AND_TERMS_SUCCESS,
  cancellationPolicies: policiesAndTerms.cancellationPolicies,
  paymentTerms: policiesAndTerms.paymentTerms,
  offerTerms: policiesAndTerms.offerTerms,
});

export type UpdatePoliciesAndTermsFailureAction = ReturnType<typeof updatePoliciesAndTermsFailureAction>;
export const updatePoliciesAndTermsFailureAction = (error: string) => ({
  type: UPDATE_POLICIES_AND_TERMS_FAILURE as typeof UPDATE_POLICIES_AND_TERMS_FAILURE,
  error,
});

export type GetPoliciesAndRestrictionsRequestAction = ReturnType<typeof getPoliciesAndRestrictionsRequestAction>;
export const getPoliciesAndRestrictionsRequestAction = () => ({
  type: GET_POLICIES_AND_RESTRICTIONS_REQUEST as typeof GET_POLICIES_AND_RESTRICTIONS_REQUEST,
});

export type GetPoliciesAndRestrictionsSuccessAction = ReturnType<typeof getPoliciesAndRestrictionsSuccessAction>;
export const getPoliciesAndRestrictionsSuccessAction = (policiesAndRestrictions: string) => ({
  type: GET_POLICIES_AND_RESTRICTIONS_SUCCESS as typeof GET_POLICIES_AND_RESTRICTIONS_SUCCESS,
  policiesAndRestrictions,
});

export type GetPoliciesAndRestrictionsFailureAction = ReturnType<typeof getPoliciesAndRestrictionsFailureAction>;
export const getPoliciesAndRestrictionsFailureAction = (error: string) => ({
  type: GET_POLICIES_AND_RESTRICTIONS_FAILURE as typeof GET_POLICIES_AND_RESTRICTIONS_FAILURE,
  error,
});

export type SetPoliciesAndRestrictionsAction = ReturnType<typeof setPoliciesAndRestrictionsAction>;
export const setPoliciesAndRestrictionsAction = (text: string) => ({
  type: SET_POLICIES_AND_RESTRICTIONS as typeof SET_POLICIES_AND_RESTRICTIONS,
  text,
});

export type SetIsBreakdownEditedWithoutSaving = ReturnType<typeof setIsBreakdownEditedWithoutSaving>;
export const setIsBreakdownEditedWithoutSaving = (value: boolean) => ({
  type: SET_IS_BREAKDOWN_EDITED_WITHOUT_SAVING as typeof SET_IS_BREAKDOWN_EDITED_WITHOUT_SAVING,
  value,
});

export type GetInvoiceAddresseeRequestAction = ReturnType<typeof getInvoiceAddresseeRequestAction>;
export const getInvoiceAddresseeRequestAction = () => ({
  type: GET_INVOICE_ADDRESSEE_REQUEST as typeof GET_INVOICE_ADDRESSEE_REQUEST,
});

export type GetInvoiceAddresseeSuccessAction = ReturnType<typeof getInvoiceAddresseeSuccessAction>;
export const getInvoiceAddresseeSuccessAction = (invoiceAddressee?: IInvoiceAddressee) => ({
  type: GET_INVOICE_ADDRESSEE_SUCCESS as typeof GET_INVOICE_ADDRESSEE_SUCCESS,
  invoiceAddressee,
});

export type GetInvoiceAddresseeFailureAction = ReturnType<typeof getInvoiceAddresseeFailureAction>;
export const getInvoiceAddresseeFailureAction = (error: string) => ({
  type: GET_INVOICE_ADDRESSEE_FAILURE as typeof GET_INVOICE_ADDRESSEE_FAILURE,
  error,
});

export type GetManualInvoiceAddresseeRequestAction = ReturnType<typeof getManualInvoiceAddresseeRequestAction>;
export const getManualInvoiceAddresseeRequestAction = () => ({
  type: GET_MANUAL_INVOICE_ADDRESSE_REQUEST as typeof GET_MANUAL_INVOICE_ADDRESSE_REQUEST,
});

export type GetManualInvoiceAddresseeSuccessAction = ReturnType<typeof getManualInvoiceAddresseeSuccessAction>;
export const getManualInvoiceAddresseeSuccessAction = (invoiceAddressee?: IInvoiceAddressee) => ({
  type: GET_MANUAL_INVOICE_ADDRESSE_SUCCESS as typeof GET_MANUAL_INVOICE_ADDRESSE_SUCCESS,
  invoiceAddressee,
});

export type GetManualInvoiceAddresseeFailureAction = ReturnType<typeof getManualInvoiceAddresseeFailureAction>;
export const getManualInvoiceAddresseeFailureAction = (error: string) => ({
  type: GET_MANUAL_INVOICE_ADDRESSE_FAILURE as typeof GET_MANUAL_INVOICE_ADDRESSE_FAILURE,
  error,
});

export type SaveManualInvoiceAddresseeRequestAction = ReturnType<typeof saveManualInvoiceAddresseeRequestAction>;
export const saveManualInvoiceAddresseeRequestAction = (invoiceAddressee: IInvoiceAddressee) => ({
  type: SAVE_MANUAL_INVOICE_ADDRESSEE_REQUEST as typeof SAVE_MANUAL_INVOICE_ADDRESSEE_REQUEST,
  invoiceAddressee,
});

export type SaveManualInvoiceAddresseeSuccessAction = ReturnType<typeof saveManualInvoiceAddresseeSuccessAction>;
export const saveManualInvoiceAddresseeSuccessAction = (invoiceAddressee?: IInvoiceAddressee) => ({
  type: SAVE_MANUAL_INVOICE_ADDRESSEE_SUCCESS as typeof SAVE_MANUAL_INVOICE_ADDRESSEE_SUCCESS,
  invoiceAddressee,
});

export type SaveManualInvoiceAddresseeFailureAction = ReturnType<typeof saveManualInvoiceAddresseeFailureAction>;
export const saveManualInvoiceAddresseeFailureAction = (error: string) => ({
  type: SAVE_MANUAL_INVOICE_ADDRESSEE_FAILURE as typeof SAVE_MANUAL_INVOICE_ADDRESSEE_FAILURE,
  error,
});

export type SaveInvoiceAddresseeRequestAction = ReturnType<typeof saveInvoiceAddresseeRequestAction>;
export const saveInvoiceAddresseeRequestAction = (invoiceAddressee: IInvoiceAddressee) => ({
  type: SAVE_INVOICE_ADDRESSEE_REQUEST as typeof SAVE_INVOICE_ADDRESSEE_REQUEST,
  invoiceAddressee,
});

export type SaveInvoiceAddresseeSuccessAction = ReturnType<typeof saveInvoiceAddresseeSuccessAction>;
export const saveInvoiceAddresseeSuccessAction = (invoiceAddressee?: IInvoiceAddressee) => ({
  type: SAVE_INVOICE_ADDRESSEE_SUCCESS as typeof SAVE_INVOICE_ADDRESSEE_SUCCESS,
  invoiceAddressee,
});

export type SaveInvoiceAddresseeFailureAction = ReturnType<typeof saveInvoiceAddresseeFailureAction>;
export const saveInvoiceAddresseeFailureAction = (error: string) => ({
  type: SAVE_INVOICE_ADDRESSEE_FAILURE as typeof SAVE_INVOICE_ADDRESSEE_FAILURE,
  error,
});

export type OpenPcrDetailsModalAction = ReturnType<typeof openPcrDetailsModalAction>;
export const openPcrDetailsModalAction = () => ({
  type: OPEN_PCR_DETAILS_MODAL as typeof OPEN_PCR_DETAILS_MODAL,
});

export type ClosePcrDetailsModalAction = ReturnType<typeof closePcrDetailsModalAction>;
export const closePcrDetailsModalAction = () => ({
  type: CLOSE_PCR_DETAILS_MODAL as typeof CLOSE_PCR_DETAILS_MODAL,
});

export type OpenSaveToPerformActionModalAction = ReturnType<typeof openSaveToPerformActionModalAction>;
export const openSaveToPerformActionModalAction = () => ({
  type: OPEN_SAVE_TO_PERFORM_ACTION_MODAL as typeof OPEN_SAVE_TO_PERFORM_ACTION_MODAL,
});

export type CloseSaveToPerformActionModalAction = ReturnType<typeof closeSaveToPerformActionModalAction>;
export const closeSaveToPerformActionModalAction = () => ({
  type: CLOSE_SAVE_TO_PERFORM_ACTION_MODAL as typeof CLOSE_SAVE_TO_PERFORM_ACTION_MODAL,
});

export type AddHeadlineLineItemAccommodationAction = ReturnType<typeof addHeadlineLineItemAccommodationAction>;
export const addHeadlineLineItemAccommodationAction = () => ({
  type: ADD_HEADLINE_LINE_ITEM_ACCOMMODATION as typeof ADD_HEADLINE_LINE_ITEM_ACCOMMODATION,
});

export type SetLiveAccommodationCancellationModalIsOpenAction = ReturnType<
  typeof setLiveAccommodationCancellationModalIsOpenAction
>;
export const setLiveAccommodationCancellationModalIsOpenAction = (isOpen: boolean) => ({
  type: SET_LIVE_ACCOMMODATION_CANCELLATION_MODAL_IS_OPEN as typeof SET_LIVE_ACCOMMODATION_CANCELLATION_MODAL_IS_OPEN,
  isOpen,
});

export type SetLiveAccommodationCancellationWarningModalIsOpenAction = ReturnType<
  typeof setLiveAccommodationCancellationWarningModalIsOpenAction
>;
export const setLiveAccommodationCancellationWarningModalIsOpenAction = (isOpen: boolean) => ({
  type: SET_LIVE_ACCOMMODATION_CANCELLATION_WARNING_MODAL_IS_OPEN as typeof SET_LIVE_ACCOMMODATION_CANCELLATION_WARNING_MODAL_IS_OPEN,
  isOpen,
});

export type AddLiveAccommodationCancellationModalAccomAction = ReturnType<
  typeof addLiveAccommodationCancellationModalAccomAction
>;
export const addLiveAccommodationCancellationModalAccomAction = (liveAccommodationBookingRef: string) => ({
  type: ADD_LIVE_ACCOMMODATION_CANCELLATION_MODAL_ACCOM as typeof ADD_LIVE_ACCOMMODATION_CANCELLATION_MODAL_ACCOM,
  liveAccommodationBookingRef,
});

export type AddAllLiveAccommodationCancellationModalAccomsAction = ReturnType<
  typeof addAllLiveAccommodationCancellationModalAccomsAction
>;
export const addAllLiveAccommodationCancellationModalAccomsAction = () => ({
  type: ADD_ALL_LIVE_ACCOMMODATION_CANCELLATION_MODAL_ACCOMS as typeof ADD_ALL_LIVE_ACCOMMODATION_CANCELLATION_MODAL_ACCOMS,
});

export type SetLiveAccommodationCancellationModalAccomDataAction = ReturnType<
  typeof setLiveAccommodationCancellationModalAccomDataAction
>;
export const setLiveAccommodationCancellationModalAccomDataAction = (
  bookingRef: string,
  accomData: Partial<ILiveCancellationModalDataAccom>
) => ({
  type: SET_LIVE_ACCOMMODATION_CANCELLATION_MODAL_ACCOM_DATA as typeof SET_LIVE_ACCOMMODATION_CANCELLATION_MODAL_ACCOM_DATA,
  bookingRef,
  accomData,
});

export type ResetLiveAccommodationCancellationModalAction = ReturnType<
  typeof resetLiveAccommodationCancellationModalAction
>;
export const resetLiveAccommodationCancellationModalAction = () => ({
  type: RESET_LIVE_ACCOMMODATION_CANCELLATION_MODAL as typeof RESET_LIVE_ACCOMMODATION_CANCELLATION_MODAL,
});

// getting the data for the live accommodations
export type GetLiveAccommodationCancellationDataRequestAction = ReturnType<
  typeof getLiveAccommodationCancellationDataRequestAction
>;
export const getLiveAccommodationCancellationDataRequestAction = () => ({
  type: GET_LIVE_ACCOMMODATION_CANCELLATION_DATA_REQUEST as typeof GET_LIVE_ACCOMMODATION_CANCELLATION_DATA_REQUEST,
});

export type GetLiveAccommodationCancellationDataSuccessAction = ReturnType<
  typeof getLiveAccommodationCancellationDataSuccessAction
>;
export const getLiveAccommodationCancellationDataSuccessAction = (liveAccommodationData: ILiveAccommodationData[]) => ({
  type: GET_LIVE_ACCOMMODATION_CANCELLATION_DATA_SUCCESS as typeof GET_LIVE_ACCOMMODATION_CANCELLATION_DATA_SUCCESS,
  liveAccommodationData,
});

export type GetLiveAccommodationCancellationDataFailureAction = ReturnType<
  typeof getLiveAccommodationCancellationDataFailureAction
>;
export const getLiveAccommodationCancellationDataFailureAction = () => ({
  type: GET_LIVE_ACCOMMODATION_CANCELLATION_DATA_FAILURE as typeof GET_LIVE_ACCOMMODATION_CANCELLATION_DATA_FAILURE,
});

// "special" case to set the new live accommodation cancellation policies
// that come with the static cancellation policies
export type SetLiveAccommodationCancellationPoliciesAction = ReturnType<
  typeof setLiveAccommodationCancellationPoliciesAction
>;
export const setLiveAccommodationCancellationPoliciesAction = (
  liveAccommodationCancellationPolicies: ILiveAccommodationCancellationPolicy[]
) => ({
  type: SET_LIVE_ACCOMMODATION_CANCELLATION_POLICIES as typeof SET_LIVE_ACCOMMODATION_CANCELLATION_POLICIES,
  liveAccommodationCancellationPolicies,
});

// actually doing the request cancellation
export type PostLiveAccommodationCancellationRequestAction = ReturnType<
  typeof postLiveAccommodationCancellationRequestAction
>;
export const postLiveAccommodationCancellationRequestAction = () => ({
  type: POST_LIVE_ACCOMMODATION_CANCELLATION_REQUEST as typeof POST_LIVE_ACCOMMODATION_CANCELLATION_REQUEST,
});
export type PostLiveAccommodationCancellationSuccessAction = ReturnType<
  typeof postLiveAccommodationCancellationSuccessAction
>;
export const postLiveAccommodationCancellationSuccessAction = () => ({
  type: POST_LIVE_ACCOMMODATION_CANCELLATION_SUCCESS as typeof POST_LIVE_ACCOMMODATION_CANCELLATION_SUCCESS,
});
export type PostLiveAccommodationCancellationFailureAction = ReturnType<
  typeof postLiveAccommodationCancellationFailureAction
>;
export const postLiveAccommodationCancellationFailureAction = () => ({
  type: POST_LIVE_ACCOMMODATION_CANCELLATION_FAILURE as typeof POST_LIVE_ACCOMMODATION_CANCELLATION_FAILURE,
});

export type SetBookingBarCommissionPercentageAction = ReturnType<typeof setBookingBarCommissionPercentageAction>;
export const setBookingBarCommissionPercentageAction = (commissionPercentage: number | null) => {
  return {
    type: UPDATE_BAR_COMMISSION_PERCENTAGE as typeof UPDATE_BAR_COMMISSION_PERCENTAGE,
    commissionPercentage,
  };
};

export type SetBookingBarCommissionAmountAction = ReturnType<typeof setBookingBarCommissionAmountAction>;
export const setBookingBarCommissionAmountAction = (commissionAmount: number | null) => {
  return {
    type: UPDATE_BAR_COMMISSION_AMOUNT as typeof UPDATE_BAR_COMMISSION_AMOUNT,
    commissionAmount,
  };
};

export type SetBookingBarCommissionErrorsAction = ReturnType<typeof setBookingBarCommissionErrorsAction>;
export const setBookingBarCommissionErrorsAction = (
  errors: IBookingManagerBreakdownSubdomain['barCommissionErrors']
) => {
  return {
    type: SET_BAR_COMMISSION_ERRORS as typeof SET_BAR_COMMISSION_ERRORS,
    errors,
  };
};

export type GetBookingBarCommissionRequestAction = ReturnType<typeof getBookingBarCommissionRequestAction>;
export const getBookingBarCommissionRequestAction = () => ({
  type: GET_BAR_COMMISSION_REQUEST as typeof GET_BAR_COMMISSION_REQUEST,
});

export type GetBookingBarCommissionSuccessAction = ReturnType<typeof getBookingBarCommissionSuccessAction>;
export const getBookingBarCommissionSuccessAction = (
  commissionPercentage: null | number,
  commissionAmount: null | number
) => ({
  type: GET_BAR_COMMISSION_SUCCESS as typeof GET_BAR_COMMISSION_SUCCESS,
  commissionPercentage,
  commissionAmount,
});

export type GetBookingBarCommissionFailureAction = ReturnType<typeof getBookingBarCommissionFailureAction>;
export const getBookingBarCommissionFailureAction = (error: string) => ({
  type: GET_BAR_COMMISSION_FAILURE as typeof GET_BAR_COMMISSION_FAILURE,
  error,
});

export type GetPaymentMethodsRequestAction = ReturnType<typeof getPaymentMethodsRequestAction>;
export const getPaymentMethodsRequestAction = () => ({
  type: GET_PAYMENT_METHODS_REQUEST as typeof GET_PAYMENT_METHODS_REQUEST,
});

export type GetPaymentMethodsSuccessAction = ReturnType<typeof getPaymentMethodsSuccessAction>;
export const getPaymentMethodsSuccessAction = (
  paymentMethods: IPaymentMethod[],
  defaultPaymentMethodCode?: string | null
) => ({
  type: GET_PAYMENT_METHODS_SUCCESS as typeof GET_PAYMENT_METHODS_SUCCESS,
  paymentMethods,
  defaultPaymentMethodCode,
});

export type GetPaymentMethodsFailureAction = ReturnType<typeof getPaymentMethodsFailureAction>;
export const getPaymentMethodsFailureAction = () => ({
  type: GET_PAYMENT_METHODS_FAILURE as typeof GET_PAYMENT_METHODS_FAILURE,
});

export type SetIsAccommodationModifiedAction = ReturnType<typeof setIsAccommodationModifiedAction>;
export const setIsAccommodationModifiedAction = (value: boolean) => ({
  type: SET_IS_ACCOMMODATION_MODIFIED as typeof SET_IS_ACCOMMODATION_MODIFIED,
  value,
});

export type GetSuppliersRequestAction = ReturnType<typeof getSuppliersRequestAction>;
export const getSuppliersRequestAction = () => ({
  type: GET_SUPPLIERS_REQUEST as typeof GET_SUPPLIERS_REQUEST,
});

export type GetSuppliersSuccessAction = ReturnType<typeof getSuppliersSuccessAction>;
export const getSuppliersSuccessAction = (
  transfersSuppliers: SuppliersSearchItem[],
  groundServicesSuppliers: SuppliersSearchItem[],
  activitiesSuppliers: SuppliersSearchItem[]
) => ({
  type: GET_SUPPLIERS_SUCCESS as typeof GET_SUPPLIERS_SUCCESS,
  transfersSuppliers,
  groundServicesSuppliers,
  activitiesSuppliers,
});

export type GetSuppliersFailureAction = ReturnType<typeof getSuppliersFailureAction>;
export const getSuppliersFailureAction = () => ({
  type: GET_SUPPLIERS_FAILURE as typeof GET_SUPPLIERS_FAILURE,
});

export type SetCurrentlyEditingLineItemAction = ReturnType<typeof setCurrentlyEditingLineItemAction>;
export const setCurrentlyEditingLineItemAction = (
  lineItem: IHeadlineLineItemBreakdownLineItem | null,
  index: number | null
) => ({
  type: SET_CURRENTLY_EDITING_PRODUCT_DATA as typeof SET_CURRENTLY_EDITING_PRODUCT_DATA,
  lineItem,
  index,
});

export type BreakdownAction =
  | GetHeadlineLineItemBreakdownRequestAction
  | GetHeadlineLineItemBreakdownSuccessAction
  | GetHeadlineLineItemBreakdownFailureAction
  | SetHeadlineLineItemFieldAction
  | AddHeadlineLineItemAction
  | RemoveHeadlineLineItemAction
  | SetHeadlineLineItemBreakdownNotesAction
  | SetHeadlineLineItemBreakdownMarginPercentageAction
  | SaveAndUpdateHeadlineBreakdownIntentAction
  | SaveAndUpdateHeadlineBreakdownRequestAction
  | SaveAndUpdateHeadlineBreakdownSuccessAction
  | SaveAndUpdateHeadlineBreakdownFailureAction
  | DownloadBreakdownForTARequestAction
  | DownloadBreakdownForTASuccessAction
  | DownloadBreakdownForTAFailureAction
  | SetBreakdownHoldAction
  | SetBreakdownLogoAction
  | GetHeadlineBreakdownVersionListRequestAction
  | GetHeadlineBreakdownVersionListSuccessAction
  | GetHeadlineBreakdownVersionListFailureAction
  | SetHeadlineBreakdownSelectedVersionAction
  | SetHeadlineBreakdownRealLatestVersionAction
  | DownloadBreakdownForClientRequestAction
  | DownloadBreakdownForClientSuccessAction
  | DownloadBreakdownForClientFailureAction
  | OpenCreateInvoiceModalAction
  | CloseCreateInvoiceModalAction
  | OpenCreateManualInvoiceModalAction
  | CloseCreateManualInvoiceModalAction
  | SetInvoiceBankAccountAction
  | SetManualInvoiceBankAccountAction
  | GenerateInvoiceRequestAction
  | GenerateInvoiceSuccessAction
  | GenerateInvoiceFailureAction
  | SetIsCreateQuoteModalOpenAction
  | GetPaymentTermsRequestAction
  | GetPaymentTermsSuccessAction
  | GetPaymentTermsFailureAction
  | GetCancellationPoliciesRequestAction
  | GetCancellationPoliciesSuccessAction
  | GetCancellationPoliciesFailureAction
  | UpdatePoliciesAndTermsRequestAction
  | UpdatePoliciesAndTermsSuccessAction
  | UpdatePoliciesAndTermsFailureAction
  | SetPaymentTermsAction
  | SetCancellationPoliciesAction
  | GetPoliciesAndRestrictionsRequestAction
  | GetPoliciesAndRestrictionsSuccessAction
  | GetPoliciesAndRestrictionsFailureAction
  | SetPoliciesAndRestrictionsAction
  | GetOfferTermsRequestAction
  | GetOfferTermsSuccessAction
  | GetOfferTermsFailureAction
  | SetOfferTermsAction
  | GetMembershipRequestAction
  | GetMembershipSuccessAction
  | GetMembershipFailureAction
  | SetMembershipBenefitsDescriptionAction
  | UpdateMembershipRequestAction
  | UpdateMembershipSuccessAction
  | UpdateMembershipFailureAction
  | SetInvoiceAddresseeTypeAction
  | SetManualInvoiceAddresseeTypeAction
  | SetInvoiceLangAction
  | GetInvoiceAddresseeRequestAction
  | GetInvoiceAddresseeSuccessAction
  | GetInvoiceAddresseeFailureAction
  | SaveInvoiceAddresseeRequestAction
  | SaveInvoiceAddresseeSuccessAction
  | SaveInvoiceAddresseeFailureAction
  | SetIsBreakdownEditedWithoutSaving
  | OpenAddresseeModalAction
  | CloseAddresseeModalAction
  | OpenAddresseeManualModalAction
  | CloseAddresseeManualModalAction
  | OpenPcrDetailsModalAction
  | ClosePcrDetailsModalAction
  | OpenSaveToPerformActionModalAction
  | CloseSaveToPerformActionModalAction
  | AddHeadlineLineItemAccommodationAction
  | SetLiveAccommodationCancellationModalIsOpenAction
  | AddLiveAccommodationCancellationModalAccomAction
  | SetLiveAccommodationCancellationModalAccomDataAction
  | PostLiveAccommodationCancellationRequestAction
  | PostLiveAccommodationCancellationSuccessAction
  | PostLiveAccommodationCancellationFailureAction
  | ResetLiveAccommodationCancellationModalAction
  | GetLiveAccommodationCancellationDataRequestAction
  | GetLiveAccommodationCancellationDataSuccessAction
  | GetLiveAccommodationCancellationDataFailureAction
  | SetLiveAccommodationCancellationPoliciesAction
  | AddAllLiveAccommodationCancellationModalAccomsAction
  | SetLiveAccommodationCancellationWarningModalIsOpenAction
  | SetBookingBarCommissionPercentageAction
  | SetBookingBarCommissionAmountAction
  | SetBookingBarCommissionErrorsAction
  | GetBookingBarCommissionRequestAction
  | GetBookingBarCommissionSuccessAction
  | GetBookingBarCommissionFailureAction
  | SaveManualInvoiceAddresseeRequestAction
  | SaveManualInvoiceAddresseeSuccessAction
  | SaveManualInvoiceAddresseeFailureAction
  | SetManualInvoiceLangAction
  | GetManualInvoiceAddresseeRequestAction
  | GetManualInvoiceAddresseeSuccessAction
  | GetManualInvoiceAddresseeFailureAction
  | GenerateManualInvoiceRequestAction
  | GenerateManualInvoiceSuccessAction
  | GenerateManualInvoiceFailureAction
  | GetPaymentMethodsRequestAction
  | GetPaymentMethodsSuccessAction
  | GetPaymentMethodsFailureAction
  | SetIsAccommodationModifiedAction
  // OWA-6783
  | CloseAddActivityModalAction
  | OpenAddActivityModalAction
  | SetAddAncillaryTransferModalToggleAction
  | SetAddAncillaryGroundServiceModalToggleAction
  | GetSuppliersRequestAction
  | GetSuppliersSuccessAction
  | GetSuppliersFailureAction
  | SetCurrentlyEditingLineItemAction
  | ReplaceHeadlineLineItemAction;
