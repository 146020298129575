import { useSelector } from 'react-redux';
import { ParameterService } from 'services/ParametersProviderApi/ParametersService';
import { getDynamicParametersSelector } from 'store/modules/bootstrap/selectors';

export function useDynamicParameters() {
  const dynamicParametersFromService = ParameterService.getParameters();
  const dynamicParametersFromRedux = useSelector(getDynamicParametersSelector);
  const dynamicParameters = Object.keys(dynamicParametersFromService).length > 0 
    ? dynamicParametersFromService : dynamicParametersFromRedux;
  const dynamicParametersAreEmpty = Object.keys(dynamicParameters).length === 0;

  return { dynamicParameters, dynamicParametersAreEmpty }
}
