import React, { Fragment, useState, Children, useEffect, useCallback, useLayoutEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose, isEmpty, map, prop, values } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';
import { useTranslation } from 'react-i18next';
import { History } from 'history';
import { Loader, Tabs, List } from '@pure-escapes/webapp-ui-components';
import { BookingBuilderSearchBar as SearchBar } from 'containers/BookingBuilderSearchBar';
import { useCurrentWidth } from 'effects';
import qs from 'qs';
import { convertArrayToKeyValueObject, mapWithIndex } from 'utils';
import styled from 'styled-components';
import { pureUiTheme } from 'pureUi/pureUiTheme';

import * as HotelContainerStyles from './HotelContainer.styles';
import { Hotel } from 'components';
import SummaryForm from 'containers/SummaryForm';
import { makeBackendApi } from 'services/BackendApi';
import * as TableCardUI from 'pureUi/TableCard';
import { useSelector, useDispatch } from 'react-redux';
import * as BookingBuilderActions from 'store/modules/bookingBuilder/actions';
import * as HotelActions from 'store/modules/hotel/actions';
import * as ProposalsActions from 'store/modules/proposals/actions';
import * as HotelAccommodationProductsActions from 'store/modules/hotelAccommodationProducts/actions';
import * as StaticRatesAvailabilityActions from 'store/modules/staticRatesAvailability/actions';
import * as AgentsSelectors from 'store/modules/agents/selectors';
import * as AgentsActions from 'store/modules/agents/actions';
import * as AuthSelectors from 'store/modules/auth/selectors';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import * as HotelSelectors from 'store/modules/hotel/selectors';
import * as ProposalsSelectors from 'store/modules/proposals/selectors';
import * as ProposalsSelectorsV2 from 'store/modules/proposalsList/selectors';
import { IReduxDomainStatus } from 'interfaces';
import { ProposalModal } from './ProposalModal';
import {
  getProposalListRequestAction,
  setPageNumberAction,
  setSelectedTravelAgentAction,
  setSortAction,
} from 'store/modules/proposalsList/actions';
import { EProposalSelectionSortableKey } from 'services/BookingManagerApi/types/ProposalSelection';
import { ESortOrder } from 'store/common/types';
import { VerticalSpace } from 'ui/VerticalSpace';
import { differenceInCalendarDays } from 'date-fns';
import { DateHelper } from 'pureUi/DatePicker';
import { dateRangeChangeAction, destinationSetAction, saveOriginalQueryAction } from 'store/modules/fastSearch';
import { StickyTopBar } from './StickyTopBar';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { TermsAndConditions } from './TermsAndConditions';
import { ERoutingPreviousPage } from 'utils/routingUtils';
import * as BasketActions from 'store/modules/basket/actions';
import * as BasketSelectors from 'store/modules/basket/selectors';
import { isNil } from 'lodash-es';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import * as ActingOnBehalfOfActions from 'store/modules/actingOnBehalfOf/actions';

interface ILeftColumnProps {
  id: string;
}
const LeftColumn = (props: ILeftColumnProps) => {
  const { id } = props;

  const hotel = useSelector(HotelSelectors.hotelSelector);
  const photos = useSelector(HotelSelectors.hotelPhotosSelector);

  if (hotel === null) {
    return null;
  }
  return (
    <div
      style={{
        flex: 1,
        paddingLeft: '14px',
        paddingRight: '14px',
      }}
    >
      <TableCardUI.TableCardNumberedBanner className="mb-4">
        <TableCardUI.TableCardNumberBannerNumber>1</TableCardUI.TableCardNumberBannerNumber>
        <TableCardUI.TableCardNumberBannerText>Select Room Type</TableCardUI.TableCardNumberBannerText>
      </TableCardUI.TableCardNumberedBanner>

      <Hotel {...hotel} id={id} photos={photos} />
    </div>
  );
};

interface IRightColumnProps extends IHotelSummaryProps {}
const RightColumn = (props: IRightColumnProps) => {
  const { id, t, history } = props;
  return (
    <div
      style={{
        flex: 1,
        paddingLeft: '14px',
        paddingRight: '14px',
      }}
    >
      <HotelSummary t={t} id={id} history={history} />
    </div>
  );
};

const renderBackButton = (t, handler) => {
  return <HotelContainerStyles.Back onClick={handler}>{t('labels.backToSearch')}</HotelContainerStyles.Back>;
};

const renderBrochure = ({ uuid, displayName, url }) => (
  <TableCardUI.TableCardRow key={uuid} depth={3} className="table-card-row brochure-row">
    <span>{displayName}</span>
    <a href={url} target="_blank">
      {/* get app */}
      <svg
        className="text-brown-100 hover:text-teal-100"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.01562 18H18.9844V20.0156H5.01562V18ZM18.9844 9L12 15.9844L5.01562 9H9V3H15V9H18.9844Z"
          fill="currentColor"
        />
      </svg>
    </a>
  </TableCardUI.TableCardRow>
);

interface IHotelSummaryProps {
  id: string; // the hotel UUID, from the route params
  t: (s: string) => React.ReactNode;
  history: RouteComponentProps['history'];
}
const HotelSummary = (props: IHotelSummaryProps) => {
  const { history, t, id } = props;

  const dispatch = useDispatch();

  const selectedTa = useSelector(AgentsSelectors.selectedTaSelector);
  const proposalsV2 = useSelector(ProposalsSelectorsV2.proposalsSelector);
  const requestPending = useSelector(ProposalsSelectorsV2.requestPendingSelector);
  const proposals = convertArrayToKeyValueObject(proposalsV2, 'uuid', 'name');

  const hotel = useSelector(HotelSelectors.hotelSelector);
  const brochures = useSelector(HotelSelectors.hotelBrochuresSelector);
  const paymentTerms = useSelector(BookingBuilderSelectors.bookingPaymentTermsSelector);
  const cancellationPolicy = useSelector(BookingBuilderSelectors.bookingCancellationPoliciesSelector);
  const offersTerms = useSelector(BookingBuilderSelectors.bookingOffersTermsSelector);
  const backendApi = makeBackendApi(selectedTa?.uuid);
  const proposalStatus = useSelector(ProposalsSelectors.getProposalsStatus) as IReduxDomainStatus;

  const [isProposalModalOpen, setIsProposalModalOpen] = useState(false);

  const { dynamicParameters } = useDynamicParameters();

  const cancellationPoliciesFormatted = cancellationPolicy.map((policy, index) => {
    if (/Accommodation [0-9]: /gm.test(policy)) {
      // if the policy is for a cancellation, split it, and indent all the further texts
      // and also remove policies that are already past their date, etc.
      const [accomTitle, rest] = policy.split(/(Accommodation [0-9]: )/gm).filter(x => x !== '');

      // if an accom doesn't actually have a policy, don't show it
      if (rest) {
        return (
          <div key={index}>
            <span className="block">{accomTitle}</span>
            <List>{rest.split('\n')}</List>
          </div>
        );
      } else {
        return null;
      }
    }

    // otherwise just return it
    return policy;
  });

  useEffect(() => {
    async function load() {
      // passing undefined seems weird, but this is legacy
      await dispatch(ProposalsActions.fetchProposals(undefined));
    }
  }, []);

  useEffect(() => {
    // In order to show all the proposals of a specific TA,
    // The list of proposals on the booking builder page should be:
    // - sorted by proposal name asc,
    // - filtered by TA
    // - have limit === 0
    dispatch(setSortAction(EProposalSelectionSortableKey.NAME, ESortOrder.ASC));
    dispatch(setSelectedTravelAgentAction(selectedTa?.uuid!));
    dispatch(setPageNumberAction(-1));
    dispatch(getProposalListRequestAction());
  }, []);

  const handleAddToProposalClick = () => {
    setIsProposalModalOpen(true);
  };

  const handleCreateNewProposalClick = (name, bookingId) => {
    dispatch(ProposalsActions.createNewProposal(name, bookingId, backendApi));
  };

  const handleOnSuccess = useCallback(() => history.push('/search/beta'), [history]);

  const handleOnClose = useCallback(() => setIsProposalModalOpen(false), []);

  const handleAddToProposal = useCallback(
    async (proposalUuid, bookingUuid) => {
      await dispatch(ProposalsActions.addToProposal(proposalUuid, bookingUuid));
    },
    [dispatch]
  );

  if (!hotel) {
    return null;
  }

  return (
    <aside id="aside">
      <TableCardUI.TableCardNumberedBanner className="mb-4">
        <TableCardUI.TableCardNumberBannerNumber>2</TableCardUI.TableCardNumberBannerNumber>
        <TableCardUI.TableCardNumberBannerText>Review Selections</TableCardUI.TableCardNumberBannerText>
      </TableCardUI.TableCardNumberedBanner>
      {isProposalModalOpen && (
        <ProposalModal
          proposals={proposals}
          hotelUuid={id}
          createNewProposal={handleCreateNewProposalClick}
          addToProposal={handleAddToProposal}
          isLoading={requestPending}
          proposalStatus={proposalStatus}
          onSuccess={handleOnSuccess}
          onClose={handleOnClose}
        />
      )}
      <SummaryForm
        id={id}
        showRoomImage={false}
        handleAddToProposalClick={handleAddToProposalClick}
        companyUuid={selectedTa?.companyUuid}
      />
      {!isEmpty(brochures) && (
        <TableCardUI.TableCardBox className="mt-4 brochures">
          <HotelContainerStyles.AsideDetails>
            <>
              <TableCardUI.TableCardRow depth={3} hasOriginalLineHeight={true}>
                <HotelContainerStyles.Title>{t('brochure_plural')}</HotelContainerStyles.Title>
              </TableCardUI.TableCardRow>
              {values(map(renderBrochure, brochures))}
            </>
          </HotelContainerStyles.AsideDetails>
        </TableCardUI.TableCardBox>
      )}
    </aside>
  );
};

interface IHotelFullLayoutProps {
  t: (s: string) => React.ReactNode;
  id: string; // the hotel UUID, from the route params
  history: RouteComponentProps['history'];
  onSearch: () => void;
  onBackToSearchResults: () => void;
}
export const HotelFullLayout = (props: IHotelFullLayoutProps) => {
  const { t, id, history, onSearch, onBackToSearchResults } = props;
  const urlParams = qs.parse(window.location.search.slice(1));
  const { startDate, endDate } = urlParams;
  const length = differenceInCalendarDays(new Date(endDate), new Date(startDate)) + 2;
  const firstTimeStamp = new Date(startDate).getTime();
  const selectedDates = DateHelper.generateDatesFrom(firstTimeStamp, length, 'en-US')
    .map(dateObject => dateObject.dateString)
    .map(date => date.split('T')[0]);

  const basketBuild = useSelector(BasketSelectors.basketBuildSelector);
  const shouldShowLeaveAlert = useSelector(BasketSelectors.shouldShowLeaveWarningInBookingBuilderSelector);

  const hotel = useSelector(HotelSelectors.hotelSelector);
  const isTa = useSelector(AuthSelectors.isTAOrActingAsTa);
  const [isSearchBarVisible, setSearchBarVisible] = useState(false);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(dateRangeChangeAction(selectedDates));
    dispatch(destinationSetAction(hotel?.name ?? ''));
    setSearchBarVisible(true);
  }, []);

  return (
    <Fragment>
      <HotelContainerStyles.StyledBreadcrumbs
        // @ts-ignore TODO legacy, investigate
        links={[
          { label: renderBackButton(t, onBackToSearchResults) },
          { label: hotel?.name, to: `/hotels/${id}${window.location.search}` },
        ]}
      />
      {isSearchBarVisible && isNil(basketBuild) && (
        <StickyTopBar className="pt-5 pb-5">
          <div className="search-bar-container px-14px">
            {/* @ts-ignore */}
            <SearchBar isSearchIconVisible history={history} onSearch={onSearch} />
          </div>
        </StickyTopBar>
      )}
      <VerticalSpace height="20px" />
      <HotelContainerStyles.Full>
        <div
          style={{
            width: '50%',
          }}
        >
          <LeftColumn id={id} />
        </div>
        <div
          style={{
            width: '50%',
          }}
        >
          <RightColumn t={t} id={id} history={history} />
        </div>
      </HotelContainerStyles.Full>
      <LeaveWithoutSavingModal
        title="You are currently editing a basket item, and have not updated the basket, so any changes have not been saved."
        when={shouldShowLeaveAlert}
        confirmButtonLabel="Leave"
        cancelButtonLabel="Cancel"
      />
    </Fragment>
  );
};

interface IHotelTabLayoutProps {
  t: (s: string) => React.ReactNode;
  id: string; // the hotel UUID, from the route params
  history: RouteComponentProps['history'];
  onBackToSearchResults: () => void;
}
export const HotelTabLayout = (props: IHotelTabLayoutProps) => {
  const { t, id, history, onBackToSearchResults } = props;

  const hotel = useSelector(HotelSelectors.hotelSelector);

  return (
    <Fragment>
      <HotelContainerStyles.StyledBreadcrumbs
        // @ts-ignore TODO legacy, investigate
        links={[
          { label: renderBackButton(t, onBackToSearchResults) },
          { label: hotel?.name, to: `/hotels/${id}${window.location.search}` },
        ]}
      />
      <Tabs labels={['Rooms', 'Booking']}>
        <div className="pt-14px">
          <LeftColumn id={id} />
        </div>
        <div className="pt-14px">
          <RightColumn id={id} t={t} history={history} />
        </div>
      </Tabs>
    </Fragment>
  );
};

interface IHotelContainerProps extends RouteComponentProps {
  id: string; // the hotel UUID, from the route params
  className?: string; // comes from styled components
}
export const HotelContainer = (props: IHotelContainerProps) => {
  const { t } = useTranslation();
  const { id, className, history }: { id: string; className?: string; history: History.LocationState } = props;
  const { dynamicParameters } = useDynamicParameters();

  const selectedTa = useSelector(AgentsSelectors.selectedTaSelector);
  const hotel = useSelector(HotelSelectors.hotelSelector);
  const hotelError = useSelector(HotelSelectors.hotelFetchErrorSelector);

  const isInternalUserOrActingAsInternalUser = useSelector(AuthSelectors.isInternalUserOrActingAsInternalUser);

  const { isMobile } = useCurrentWidth();

  const dispatch = useDispatch();

  // solution for triggering a component re-render in functional components
  const [currentState, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const resetBookingBuilderState = useCallback(() => {
    dispatch(BookingBuilderActions.resetBookingBuilderUiStateAction());
    // reset the hotel accommodation products
    dispatch(HotelAccommodationProductsActions.resetHotelAccommodationProductsStateAction());
    // clear the hotel data
    dispatch(HotelActions.clearHotelAction());
    // clear out the static rates availability data
    dispatch(StaticRatesAvailabilityActions.resetStaticRatesAvailabilityDomainAction());
    // clear out the booking builder domain
    dispatch(BookingBuilderActions.clearBookingBuilderAction());
  }, []);

  const handleSearch = useCallback(() => {
    forceUpdate();
    resetBookingBuilderState();
  }, [resetBookingBuilderState, forceUpdate]);

  const handleBackToSearchResults = useCallback(() => {
    dispatch(BookingBuilderActions.backToSearchResultsAction());
  }, [dispatch]);

  useEffect(() => {
    if (history.location.state?.comingFrom === ERoutingPreviousPage.BOOKING_CONFIRMATION_PAGE) {
      return;
    }
    if (history.location.state?.comingFrom === ERoutingPreviousPage.BASKET_PAGE) {
      return;
    }

    dispatch(saveOriginalQueryAction());
    dispatch(AgentsActions.saveOriginalSelectionAction());
  }, [dispatch]);

  useEffect(() => {
    if (history.location.state?.comingFrom === ERoutingPreviousPage.BOOKING_CONFIRMATION_PAGE) {
      return;
    }
    if (history.location.state?.comingFrom === ERoutingPreviousPage.BASKET_PAGE) {
      return;
    }
    // on initial mount, if we have a tauuid in the URL and _not_ in the redux, load that
    // ta into the redux
    if (!isInternalUserOrActingAsInternalUser) {
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const tauuid = urlParams.get('tauuid');
    if (selectedTa === null && tauuid) {
      dispatch(AgentsActions.loadSelectedTaViaUuidRequestAction(tauuid));
    }
  }, [currentState, selectedTa, isInternalUserOrActingAsInternalUser]);

  useEffect(() => {
    if (history.location.state?.comingFrom === ERoutingPreviousPage.BOOKING_CONFIRMATION_PAGE) {
      return;
    }
    if (history.location.state?.comingFrom === ERoutingPreviousPage.BASKET_PAGE) {
      return;
    }
    // when the ID of the hotel we're looking at changes, initialise the booking builder
    // with that hotel
    // if we have a selectedTa from the withTravelAgent domain
    // initialise with that too
    if (selectedTa && selectedTa.uuid) {
      dispatch(BookingBuilderActions.initializeBookingBuilderAction(id, selectedTa.uuid));
    } else {
      // undefined is weird but legacy
      dispatch(BookingBuilderActions.initializeBookingBuilderAction(id));
    }
  }, [currentState, id, selectedTa]);

  useEffect(() => {
    if (history.location.state?.comingFrom === ERoutingPreviousPage.BOOKING_CONFIRMATION_PAGE) {
      return;
    }
    // whenever the hotel ID changes, load the new hotel
    dispatch(HotelActions.setHotelUuidAction(id));
    async function runFetchHotelRequestAction() {
      await dispatch(HotelActions.fetchHotelRequestAction(id));
    }
    runFetchHotelRequestAction();
  }, [currentState, id]);

  useEffect(() => {
    window.history.replaceState({}, '');

    return () => {
      // we're leaving the booking builder, so clear out any possible state re. baskets and acting on behalf of
      dispatch(BasketActions.setShouldShowLeaveWarningInBookingBuilderAction(false));
      dispatch(BasketActions.setBasketBuildAction(null));
      dispatch(ActingOnBehalfOfActions.setActingOnBehalfOfDataAction(null));
    };
  }, []);

  if (hotelError) {
    return <p>{hotelError}</p>;
  }

  return (
    <div className={className}>
      {isNilOrEmpty(hotel) || (isInternalUserOrActingAsInternalUser && isNilOrEmpty(selectedTa)) ? (
        <Loader isLoading={true} text={t('messages.gettingHotel')} />
      ) : (
        <HotelContainerStyles.StyledHotelContainer>
          {isMobile && (
            <HotelTabLayout t={t} id={id} history={history} onBackToSearchResults={handleBackToSearchResults} />
          )}
          {!isMobile && (
            <HotelFullLayout
              t={t}
              id={id}
              history={history}
              onSearch={handleSearch}
              onBackToSearchResults={handleBackToSearchResults}
            />
          )}
        </HotelContainerStyles.StyledHotelContainer>
      )}
    </div>
  );
};

const ConnectedHotel = compose(withRouter)(HotelContainer);

export default styled(ConnectedHotel)`
  .table-card-row.brochure-row {
    display: flex;
    padding-bottom: 12px;
    padding-top: 12px;
    line-height: 14px;
    padding-left: 40px;
    font-size: 12px;
    text-transform: uppercase;
    color: #736a65;
    border-bottom: 1px solid ${pureUiTheme.colors.grayDark};

    &:nth-child(2) {
      padding-top: 0px;
    }

    span {
      flex: 1;
      &::before {
        content: '';
        border-color: transparent #111;
        border-style: solid;
        border-width: 3px 0 3px 4px;
        display: block;
        height: 0;
        width: 0;
        left: -10px;
        top: 10px;
        position: relative;
      }
    }

    a {
      display: block;
      margin-top: 4px;
      margin-bottom: -4px;
      color: ${pureUiTheme.colors.gold};

      &:hover {
        color: ${pureUiTheme.colors.teal};
      }
    }
  }
`;
