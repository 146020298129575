import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnimateHeight from 'react-animate-height';
import classnames from 'classnames';

import {
  tcStatementFilterSetDateRangeValueAction,
  tcStatementFilterSetDateRangeTypeAction,
  tcStatementFilterSetStatusAction,
  tcStatementFilterAddBookingRefAction,
  tcStatementFilterSetBookingRefAction,
  tcStatementFilterSetAmountMinAction,
  tcStatementFilterSetAmountMaxAction,
  tcStatementFilterDeleteBookingRefAction,
  tcStatementFilterResetAction,
  tcStatementFilterUpdateResultsAction,
} from 'store/modules/tcBookingStatement/actions';

import {
  tcStatementFilteDateRangeValueSelector,
  tcStatementFilterDateRangeTypeSelector,
  tcStatementFilterBookingRefsSelector,
  tcStatementFilterStatusSelector,
  tcStatementFilterAmountMinSelector,
  tcStatementFilterAmountMaxSelector,
} from 'store/modules/tcBookingStatement/selectors';

import { Multiselect, IMultiselectValueLabelPair } from 'ui/Multiselect';
import FluidButton from 'ui/FluidButton';

import { BookingRefInput } from './BookingRefInput';
import { theme } from '../../../../tailwind.config';
import PlusIcon from 'ui/Icons/plus.component.svg';
import { PriceAsCentsInput } from 'ui/stateful/PriceAsCentsInput';
import { SvgIcon } from 'ui/SvgIcon';
import InfoIcon from 'ui/Icons/components/Info.component';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';
import { DatePickerStateProvider, IDatePickerSateParams } from 'pureUi/providers/DatePickerStateProvider';
import DateRangeInput from 'pureUi/DateRangeInput';
import { format } from 'date-fns';

interface ITCBookingLedgerFilterProps {
  isOpen: boolean;
}

export const FILTERS_ANIMATION_DURATION = 300;

const STATUS_FILTER_OPTIONS: IMultiselectValueLabelPair[] = [
  {
    value: 'requested',
    label: 'Requested',
    iconHtml: <i className="fas fa-circle text-status-requested"></i>,
    iconAlign: 'end',
  },
  {
    value: 'confirmed',
    label: 'Confirmed',
    iconHtml: <i className="fas fa-circle text-status-confirmed"></i>,
    iconAlign: 'end',
  },
  {
    value: 'cancelled',
    label: 'Cancelled',
    iconHtml: <i className="fas fa-circle text-status-cancelled"></i>,
    iconAlign: 'end',
  },
  {
    value: 'completed',
    label: 'Completed',
    iconHtml: <i className="fas fa-circle text-status-completed"></i>,
    iconAlign: 'end',
  },
];

export const TCBookingLedgerFilter: React.FC<ITCBookingLedgerFilterProps> = ({ isOpen }) => {
  const dispatch = useDispatch();

  const statementFilterDateRangeValue = useSelector(tcStatementFilteDateRangeValueSelector);
  const statementFilterDateRangeType = useSelector(tcStatementFilterDateRangeTypeSelector);
  const statementFilterStatus = useSelector(tcStatementFilterStatusSelector);
  const statementFilterBookingRefs = useSelector(tcStatementFilterBookingRefsSelector);
  const statementFilterAmountMin = useSelector(tcStatementFilterAmountMinSelector);
  const statementFilterAmountMax = useSelector(tcStatementFilterAmountMaxSelector);

  const isAmountMinMaxError =
    statementFilterAmountMax !== null &&
    statementFilterAmountMin !== null &&
    statementFilterAmountMin > statementFilterAmountMax;

  const contentHeight = useMemo(() => (isOpen ? 'auto' : 0), [isOpen]);
  const addBookingRefButtonClass = `
    add-booking-ref w-5 h-5 rounded-full flex justify-center items-center border border-solid
    border-brown-prime cursor-pointer hover:bg-brown-20
  `;

  const handleAddBookingRefClick = useCallback(() => {
    dispatch(tcStatementFilterAddBookingRefAction());
  }, [dispatch]);

  const handleResetFilters = useCallback(() => {
    dispatch(tcStatementFilterResetAction());
    dispatch(tcStatementFilterUpdateResultsAction());
  }, [dispatch]);

  const handleSearchClick = useCallback(() => {
    dispatch(tcStatementFilterUpdateResultsAction());
  }, [dispatch]);

  const handleSDateRangeChanged = useCallback((selectedValues) => {
      const formattedRange = selectedValues.map( (date, index, array) => {
        if( index === 0 || index === array.length-1 ) {
          return  format(new Date(date), 'yyyy-MM-dd');
        }
        return null;
      }).filter(Boolean) as string[];
      dispatch(tcStatementFilterSetDateRangeValueAction(formattedRange));
  }, [dispatch]);

  return (
    // @ts-ignore
    <AnimateHeight className="animate-height" duration={FILTERS_ANIMATION_DURATION} height={contentHeight}>
      <div className="booking-filter px-10px mx-15px py-15px bg-ivory mb-5 rounded">
        <h2 className="text-17px font-bold mt-0">Filters and Search Options</h2>

        <div className="flex items-end flex-wrap">
          <div className="mr-0 mb-15px">
            <span className="block text-13px pb-5px">Date Range</span>
            <DatePickerStateProvider
              defaultSelectedDates={statementFilterDateRangeValue || []}
              onDateChange={handleSDateRangeChanged}
              render={(params: IDatePickerSateParams) => {
                return (
                  <DateRangeInput
                    className="serachBarDateRangeInput w-180px"
                    displayString={params.displayString}
                    currentDate={params.datePickerCurrentDate}
                    showTotalNights={false}
                    selectedDates={params.selectedDates}
                    onDayClick={params.handleDayClick}
                    onDayMouseOver={params.handleDateMouseOver}
                    showDatePicker={params.showDatePicker}
                    onNextClick={params.incrementDate}
                    onPrevClick={params.decrementDate}
                    onMouseDown={params.toggleDatePicker}
                    onClickOutside={params.hideDatePicker}
                    showCalendarIcon={true}
                  />
                );
              }}
            />
          </div>

          <div className="mr-5 mb-15px">
            {!!statementFilterDateRangeValue && (
              <div className="flow-root min-w-180px">
              <span
                className="block float-right content-end text-13px pb-5px cursor-pointer underline text-brown-100 hover:no-decoration hover:text-brown-120"
                onClick={() => dispatch(tcStatementFilterSetDateRangeValueAction(null))}
              >
                Clear Date Range
              </span>
              </div>
            )}
            <Multiselect
              className={'date-type bg-white min-w-180px font-hurmegeometric-sans'}
              placeholderText="Range Date Type"
              borderColourClass="border-gray-17"
              itemsClassname="text-black"
              itemContentClassName="text-black"
              fontClass="font-hurmegeometric-sans"
              itemCtaClassName="bg-white hover:bg-blue-5"
              openClassName="outline outline-2 outline-teal-100"
              placeholderClasses="not-italic text-gray-15"
              height={37}
              onUpdate={selectedValues => {
                dispatch(tcStatementFilterSetDateRangeTypeAction(selectedValues[0]));
              }}
              hideCheckboxes={true}
              isSingleSelectMode={true}
              isCloseOnSelect={true}
              isEnableFuzzySearch={false}
              options={[
                {
                  value: 'arrival',
                  label: 'Arrivals',
                },
                {
                  value: 'departure',
                  label: 'Departures',
                }
              ]}
              selectedValues={statementFilterDateRangeType ? [statementFilterDateRangeType] : []}
            />
          </div>

          {/* statuses */}
          <div className="mr-5 mb-15px">
            <span className="block text-13px pb-5px">Statuses</span>
            <Multiselect
              className="type-select min-w-290px bg-white text-15px"
              itemCtaClassName="hover:bg-teal-20 text-left"
              itemsClassname="bg-white"
              onUpdate={selectedValues => {
                dispatch(tcStatementFilterSetStatusAction(selectedValues));
              }}
              options={STATUS_FILTER_OPTIONS}
              selectedValues={statementFilterStatus == null ? [] : statementFilterStatus}
            />
          </div>

          {/* amount min and max */}
          <div className="mr-5 mb-15px flex flex-row items-center">
            <label className="block">
              <span className="block leading-2xs font-pt-sans text-13px text-black">Amount Min.</span>
              <PriceAsCentsInput
                className="bg-white global-statement-amount-min mt-5px h-37px w-100px border border-solid border-gray-40 text-15px px-10px py-2 focus:outline-gray-80"
                isAllowUndefined={true}
                disabled={false}
                onBlurUpdate={val => {
                  if (val !== statementFilterAmountMin) {
                    dispatch(tcStatementFilterSetAmountMinAction(val));
                  }
                }}
                cents={statementFilterAmountMin !== null ? statementFilterAmountMin : undefined}
              />
            </label>

            <span className="mx-5px mt-4">-</span>

            <label className="block">
              <div className="flex justify-between leading-2xs items-center">
                <span className="font-pt-sans text-13px text-black">Amount Max.</span>
                <HidingTextTooltip
                  tooltipContent="These amounts filter the invoice amount."
                  position="right"
                  tooltipClassname="mt-25px"
                >
                  <span className="cursor-pointer h-4">
                    <SvgIcon
                      className="w-18px"
                      IconComponent={InfoIcon}
                      defaultFill={theme.colors['gray-80']}
                      hoverFill={theme.colors['gray-100']}
                      activeFill={theme.colors['gray-100']}
                    />
                  </span>
                </HidingTextTooltip>
              </div>
              <PriceAsCentsInput
                className={classnames(
                  'bg-white global-statement-amount-max mt-5px h-37px w-100px border border-solid text-15px px-10px py-2 focus:outline-gray-80',
                  {
                    'border-gray-40': !isAmountMinMaxError,
                    'border-red-95 bg-red-25': isAmountMinMaxError,
                  }
                )}
                isAllowUndefined={true}
                disabled={false}
                onBlurUpdate={val => {
                  if (val !== statementFilterAmountMax) {
                    dispatch(tcStatementFilterSetAmountMaxAction(val));
                  }
                }}
                cents={statementFilterAmountMax !== null ? statementFilterAmountMax : undefined}
              />
            </label>
          </div>

          {statementFilterBookingRefs.map((br, brIndex) => {
            return (
              <div key={brIndex} className="booking-ref-inputs mr-5px mb-15px">
                <span className="block text-13px pb-5px">Booking Ref.</span>
                <BookingRefInput
                  bookingRef={br}
                  index={brIndex}
                  onChange={newValue => {
                    dispatch(tcStatementFilterSetBookingRefAction(brIndex, newValue));
                  }}
                  onCrossButtonClick={() => {
                    if (brIndex === 0) {
                      dispatch(tcStatementFilterSetBookingRefAction(brIndex, ''));
                    } else {
                      dispatch(tcStatementFilterDeleteBookingRefAction(brIndex));
                    }
                  }}
                />
              </div>
            );
          })}

          <div className="ml-5px mb-6">
            <HidingTextTooltip
              tooltipContent="Add Booking Ref."
              position="bottom"
              tooltipContentClassname="add-booking-ref-tooltip px-[7px] py-[5px]"
            >
              <span className={addBookingRefButtonClass} onClick={handleAddBookingRefClick}>
                <PlusIcon fill={theme.colors['brown-prime']} />
              </span>
            </HidingTextTooltip>
          </div>
        </div>

        <div className="flex flex-row">
          <FluidButton className="reset-filters mr-2" type="secondary" onClick={handleResetFilters}>
            Reset Filters
          </FluidButton>
          <FluidButton className="search" type="primary" onClick={handleSearchClick}>
            Search
          </FluidButton>
        </div>
      </div>
    </AnimateHeight>
  );
};
