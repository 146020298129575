import React from 'react';
import { cloneDeep } from 'lodash-es';
import { EUserType } from 'services/BackendApi';
import WorkspaceContracting from 'ui/Icons/workspace_contracting.component.svg';
import WorkspaceBigContracting from 'ui/Icons/workspace_big_contracting.component.svg';
import WorkspaceFinances from 'ui/Icons/workspace_finances.component.svg';
import WorkspaceBigFinances from 'ui/Icons/workspace_big_finances.component.svg';
import WorkspaceReservation from 'ui/Icons/workspace_reservation.component.svg';
import WorkspaceBigReservation from 'ui/Icons/workspace_big_reservation.component.svg';
import WorkspaceUsers from 'ui/Icons/workspace_users.component.svg';
import WorkspaceBigUsers from 'ui/Icons/workspace_big_users.component.svg';
import WorkspaceAdmin from 'ui/Icons/workspace_admin.component.svg';
import WorkspaceBigAdmin from 'ui/Icons/workspace_big_admin.component.svg';
import WorkspaceBanking from 'ui/Icons/workspace_banking.component.svg';
import WorkspaceBigBanking from 'ui/Icons/workspace_big_banking.component.svg';
import WorkspaceBusiness from 'ui/Icons/workspace_business.component.svg';
import WorkspaceBigBusiness from 'ui/Icons/workspace_big_business.component.svg';
import WorkspaceMessaging from 'ui/Icons/workspace_messaging.component.svg';
import WorkspaceBigMessaging from 'ui/Icons/workspace_big_messaging.component.svg';
import WorkspaceTasks from 'ui/Icons/workspace_tasks.component.svg';
import WorkspaceBigTasks from 'ui/Icons/workspace_big_tasks.component.svg';
import {
  IInventoryOption,
  EInventoryReferenceL1,
  EInventoryReferenceL2,
  EInventoryReferenceL3,
  InventoryReference,
  IInventoryOptionBespoke,
} from '../../interfaces';
import { theme } from '../../../tailwind.config';
import { ParameterService } from 'services/ParametersProviderApi/ParametersService';
import { IUser } from 'services/BackendApi';

// Sometimes we don't allow some users in sections but we allow that user in a particular page of that section
// Like Finance user can see settings for a specific TC, while he can't enter 'Users' section
const menuOptionsExceptions = [
  {
    userType: [EUserType.FINANCE, EUserType.SR],
    grantedPath: new RegExp(
      '/(travel-companies)/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/(settings)$'
    ),
    inventoryOptions: [EInventoryReferenceL1.USERS, null, null],
  },
  {
    userType: [EUserType.TA],
    grantedPath: new RegExp('/statement'),
    inventoryOptions: [EInventoryReferenceL1.FINANCES, EInventoryReferenceL2.STATEMENTS, null],
  },
];

export const reconstructMenuOptions = (
  profile: EUserType,
  map: IInventoryOption[],
  upperPath: InventoryReference[],
  seekURL: string,
  level: number,
  maxLevel: number,
  bestResult: InventoryReference[] = []
): InventoryReference[] => {
  // First look for specific routes allowed for specific users that are not in allowed categories.
  const exception = menuOptionsExceptions.find(e => e.grantedPath.test(seekURL) && e.userType.includes(profile));
  if (exception) {
    return exception.inventoryOptions;
  } // Return the ad-hoc category tree for menu

  // Look for the categories tree that fits the targeted url
  let finalResult: InventoryReference[] = [...bestResult];
  for (let o of map) {
    let newOptionsPath: InventoryReference[] = [...upperPath, o.value];
    const isSelected = o.urlGroup && o.urlGroup().some(u => seekURL.startsWith(u));
    if (isSelected) {
      const exception = menuOptionsExceptions.find(e => e.grantedPath.test(seekURL) && e.userType.includes(profile));
      const selectedOptionGranted = o.allowedViewRoles.includes(profile);
      if (selectedOptionGranted) {
        const paddedNewOptionsPath = [...newOptionsPath, ...new Array(maxLevel - newOptionsPath.length).fill(null)];
        finalResult = [...paddedNewOptionsPath];
        // Don't put a 'break' here, we want the fitting option closest to bottom of hierarchy
        if (o.options && o.options.length > 0) {
          // Don't evaluate 'isSelected' here, we want the fitting option closest to bottom of hierarchy
          finalResult = reconstructMenuOptions(
            profile,
            o.options,
            newOptionsPath,
            seekURL,
            level++,
            maxLevel,
            finalResult
          );
        }
      } else {
        // Exit loop with the last step granted. Don't look the underlaying options.
        finalResult = [...bestResult];
        break;
      }
    } else {
      if (o.options && o.options.length > 0) {
        // Fitting option may be down to the bottom of hierarchy
        finalResult = reconstructMenuOptions(
          profile,
          o.options,
          newOptionsPath,
          seekURL,
          level++,
          maxLevel,
          finalResult
        );
      }
    }
  }
  return [...finalResult];
};

export const getAvailableWorkspacesLinks = (user: IUser) => {
  const exception = user.type === EUserType.TA && user.taCanReadFinance ? EInventoryReferenceL1.FINANCES : null;
  let availableWorkspacesLinks: IInventoryOptionBespoke[] = getMenuOptionsBespoke(
    inventoryMap,
    [],
    user.type as EUserType,
    exception
  );
  return availableWorkspacesLinks;
};

export const getMenuOptionsBespoke = (
  map: IInventoryOption[],
  upperPath: InventoryReference[] = [],
  userType: EUserType,
  exception?: InventoryReference
): IInventoryOptionBespoke[] => {
  return map
    .filter(e => e.allowedViewRoles.includes(userType) || (exception && e.value === exception))
    .filter(workspace => !workspace.isHidden || (workspace.isHidden && !workspace.isHidden(userType)))
    .map(e => {
      const copy = cloneDeep(e);
      const branchPath = [...upperPath];
      branchPath.push(e.value);
      const paddedPath: InventoryReference[] = [
        ...branchPath,
        ...new Array(3 - branchPath.length).fill(null),
      ] as InventoryReference[];
      // Hardcoded exceptions. Not complicating mapping with exceptions at the moment.
      if (
        (copy.value === EInventoryReferenceL1.FINANCES || copy.value === EInventoryReferenceL2.STATEMENTS) &&
        (userType === EUserType.SR || userType === EUserType.TA)
      ) {
        copy.urlRedirect = () => `/statement`;
      }
      return {
        ...copy,
        path: [...paddedPath],
      };
    });
};

export const URLS_WITHOUT_MENU_HEADER = ['/', '/settings'];

export const inventoryMap: IInventoryOption[] = [
  {
    label: 'Admin',
    value: EInventoryReferenceL1.ADMIN,
    urlRedirect: null,
    iconHtml: <WorkspaceAdmin fill={theme.colors['inv-aqua']} width={16} height={16} />,
    iconHtmlBig: <WorkspaceBigAdmin fill={theme.colors['inv-aqua']} />,
    iconHtmlHomepage: <WorkspaceAdmin fill={theme.colors['inv-aqua']} width={36} height={36} />,
    iconAlign: 'left',
    isDivider: false,
    isExternal: true,
    allowedViewRoles: [],
    options: [],
  },
  {
    label: 'Banking',
    value: EInventoryReferenceL1.BANKING,
    urlRedirect: null,
    iconHtml: <WorkspaceBanking fill={theme.colors['red-100']} width={16} height={16} />,
    iconHtmlBig: <WorkspaceBigBanking fill={theme.colors['red-100']} />,
    iconHtmlHomepage: <WorkspaceBanking fill={theme.colors['red-100']} width={36} height={36} />,
    iconAlign: 'left',
    isDivider: false,
    isExternal: true,
    allowedViewRoles: [],
    options: [],
  },
  {
    label: 'Business',
    value: EInventoryReferenceL1.BUSINESS,
    urlRedirect: null,
    iconHtml: <WorkspaceBusiness fill={theme.colors['red-100']} width={16} height={16} />,
    iconHtmlBig: <WorkspaceBigBusiness fill={theme.colors['red-100']} />,
    iconHtmlHomepage: <WorkspaceBusiness fill={theme.colors['red-100']} width={36} height={36} />,
    iconAlign: 'left',
    isDivider: false,
    isExternal: true,
    allowedViewRoles: [],
    options: [],
  },
  {
    label: 'Messaging',
    value: EInventoryReferenceL1.MESSAGING,
    urlRedirect: null,
    iconHtml: <WorkspaceMessaging fill={theme.colors['inv-yellow']} width={16} height={16} />,
    iconHtmlBig: <WorkspaceBigMessaging fill={theme.colors['inv-yellow']} />,
    iconHtmlHomepage: <WorkspaceMessaging fill={theme.colors['inv-yellow']} width={36} height={36} />,
    iconAlign: 'left',
    isDivider: false,
    isExternal: true,
    allowedViewRoles: [],
    options: [],
  },
  {
    label: 'Reservation',
    value: EInventoryReferenceL1.RESERVATION,
    urlRedirect: () => '/filters',
    urlGroup: () => ['/filters'],
    iconHtml: <WorkspaceReservation fill={theme.colors['inv-aqua']} width={16} height={16} />,
    iconHtmlBig: <WorkspaceBigReservation fill={theme.colors['inv-aqua']} />,
    iconHtmlHomepage: <WorkspaceReservation fill={theme.colors['inv-aqua']} width={36} height={36} />,
    iconAlign: 'left',
    isDivider: false,
    allowedViewRoles: [EUserType.ADMIN, EUserType.TA, EUserType.SR, EUserType.FINANCE],
    options: [
      // TODO. Remove this part when real content for Level 3 is introduced.
      // As the menu is released, we only have 2 levels in hierarchy.
      // Use the option below to see how a menu with 3 levels is working.
      {
        label: 'Search',
        value: EInventoryReferenceL2.SEARCH,
        urlRedirect: () => '/filters',
        urlGroup: () => ['/filters', '/search/beta', '/hotels', '/booking-confirmation', '/basket'],
        allowedViewRoles: [EUserType.ADMIN, EUserType.TA, EUserType.SR],
      },
      {
        label: 'Proposals',
        value: EInventoryReferenceL2.PROPOSALS,
        urlRedirect: () => '/proposals-v2',
        urlGroup: () => ['/proposals-v2'],
        allowedViewRoles: [EUserType.ADMIN, EUserType.TA, EUserType.SR],
      },
      {
        label: 'Enquiries & Bookings',
        value: EInventoryReferenceL2.ENQUIRIES_AND_BOOKINGS,
        urlRedirect: () => '/bookings',
        urlGroup: () => ['/bookings', '/booking-manager'],
        allowedViewRoles: [EUserType.ADMIN, EUserType.TA, EUserType.SR, EUserType.FINANCE],
      },
    ],
  },
  {
    label: 'Contracting',
    value: EInventoryReferenceL1.CONTRACTING,
    urlRedirect: () => {
      const dynamicParameters = ParameterService.getParameters();
      return `${dynamicParameters.ADMIN_BASE_URL}/hotels`;
    },
    urlGroup: () => {
      const dynamicParameters = ParameterService.getParameters();
      return [`${dynamicParameters.ADMIN_BASE_URL}/hotels`];
    },
    isExternal: true,
    iconHtml: <WorkspaceContracting fill={theme.colors['inv-teal']} width={16} height={16} />,
    iconHtmlBig: <WorkspaceBigContracting fill={theme.colors['inv-teal']} />,
    iconHtmlHomepage: <WorkspaceContracting fill={theme.colors['inv-teal']} width={36} height={36} />,
    iconAlign: 'left',
    isDivider: false,
    allowedViewRoles: [EUserType.ADMIN, EUserType.RL],
    options: [
      {
        label: 'Suppliers',
        value: EInventoryReferenceL3.ANCILLARY_SUPPLIERS,
        urlRedirect: () => '/suppliers',
        urlGroup: () => ['/suppliers'],
        allowedViewRoles: [EUserType.ADMIN, EUserType.RL],
        isHidden: () => {
          const dynamicParameters = ParameterService.getParameters();
          if (dynamicParameters.ENABLE_ANCILLARY_SUPPLIERS_IMPORT == null) {
            return true;
          }
          return dynamicParameters.ENABLE_ANCILLARY_SUPPLIERS_IMPORT === false;
        },
      },
      {
        label: 'Products',
        value: null,
        allowedViewRoles: [EUserType.ADMIN, EUserType.TA, EUserType.SR, EUserType.RL],
        options: [
          {
            label: 'Activities',
            value: EInventoryReferenceL3.ANCILLARY_ACTIVITIES,
            urlGroup: () => ['/activities'],
            urlRedirect: () => '/activities',
            allowedViewRoles: [EUserType.ADMIN, EUserType.TA, EUserType.SR],
            isHidden: () => {
              const dynamicParameters = ParameterService.getParameters();
              if (dynamicParameters.ENABLE_ANCILLARY_ACTIVITIES_IMPORT == null) {
                return true;
              }
              return dynamicParameters.ENABLE_ANCILLARY_ACTIVITIES_IMPORT === false;
            },
          },
          {
            label: 'Ground Services',
            value: EInventoryReferenceL3.ANCILLARY_GROUND_SERVICES,
            urlGroup: () => ['/ground-services'],
            urlRedirect: () => '/ground-services',
            allowedViewRoles: [EUserType.ADMIN, EUserType.TA],
            isHidden: () => {
              const dynamicParameters = ParameterService.getParameters();
              if (dynamicParameters.ENABLE_ANCILLARY_GROUND_SERVICES_IMPORT == null) {
                return true;
              }
              return dynamicParameters.ENABLE_ANCILLARY_GROUND_SERVICES_IMPORT === false;
            },
          },
          {
            label: 'Hotels',
            value: EInventoryReferenceL3.HOTELS,
            urlRedirect: () => {
              const dynamicParameters = ParameterService.getParameters();
              return `${dynamicParameters.ADMIN_BASE_URL}/hotels`;
            },
            urlGroup: () => {
              const dynamicParameters = ParameterService.getParameters();
              return [
                `${dynamicParameters.ADMIN_BASE_URL}/hotels`,
                '/rates/import',
                '/products/import',
                '/static-rates-availability/import',
              ];
            },
            isExternal: true,
            allowedViewRoles: [EUserType.ADMIN, EUserType.RL],
          },
          {
            label: 'Transfers',
            value: EInventoryReferenceL3.ANCILLARY_TRANSFERS,
            urlGroup: () => ['/transfers'],
            urlRedirect: () => '/transfers',
            allowedViewRoles: [EUserType.ADMIN, EUserType.RL],
            isHidden: () => {
              const dynamicParameters = ParameterService.getParameters();
              if (dynamicParameters.ENABLE_ANCILLARY_TRANSFERS_IMPORT == null) {
                return true;
              }
              return dynamicParameters.ENABLE_ANCILLARY_TRANSFERS_IMPORT === false;
            },
          },
        ],
      },
      {
        label: 'Rate breaks',
        value: EInventoryReferenceL3.RATE_BREAKS,
        urlRedirect: () => '/rate-breaks',
        urlGroup: () => ['/rate-breaks'],
        allowedViewRoles: [EUserType.ADMIN, EUserType.RL],
      },
      {
        label: 'Live Rates',
        value: EInventoryReferenceL3.LIVE_RATES,
        urlRedirect: () => `/live-rates`,
        urlGroup: () => [`/live-rates`],
        allowedViewRoles: [EUserType.ADMIN, EUserType.RL, EUserType.SR],
        isHidden: () => {
          const dynamicParameters = ParameterService.getParameters();
          return dynamicParameters.ENABLE_V1_LIVE_RATES === true || dynamicParameters.ENABLE_V1_LIVE_RATES_SRS === true
            ? false
            : true;
        },
      },
      {
        label: 'Offers',
        value: EInventoryReferenceL3.OFFERS,
        urlRedirect: () => '/offers',
        urlGroup: () => ['/offers'],
        allowedViewRoles: [EUserType.ADMIN, EUserType.RL],
      },
    ],
  },
  {
    label: 'Finance',
    value: EInventoryReferenceL1.FINANCES,
    urlRedirect: () => '/statement/all',
    urlGroup: () => ['/statement/all'],
    iconHtml: <WorkspaceFinances fill={theme.colors['inv-green']} width={16} height={16} />,
    iconHtmlBig: <WorkspaceBigFinances fill={theme.colors['inv-green']} />,
    iconHtmlHomepage: <WorkspaceFinances fill={theme.colors['inv-green']} width={36} height={36} />,
    iconAlign: 'left',
    isDivider: false,
    allowedViewRoles: [EUserType.ADMIN, EUserType.SR, EUserType.FINANCE],
    options: [
      {
        label: 'Statements',
        value: EInventoryReferenceL2.STATEMENTS,
        urlRedirect: () => '/statement/all',
        urlGroup: () => ['/statement'],
        allowedViewRoles: [EUserType.ADMIN, EUserType.SR, EUserType.FINANCE],
      },
    ],
  },
  {
    label: 'Operations',
    value: EInventoryReferenceL1.OPERATIONS,
    urlRedirect: () => '/tasks',
    urlGroup: () => ['/tasks'],
    iconHtml: <WorkspaceTasks fill={theme.colors['inv-yellow']} width={16} height={16} />,
    iconHtmlBig: <WorkspaceBigTasks fill={theme.colors['inv-yellow']} />,
    iconHtmlHomepage: <WorkspaceTasks fill={theme.colors['inv-yellow']} width={36} height={36} />,
    iconAlign: 'left',
    isDivider: false,
    isHidden: (userType?: EUserType) => {
      const dynamicParameters = ParameterService.getParameters();
      return !(
        (userType === EUserType.TA && dynamicParameters.ENABLE_OPERATIONS_WORKSPACE_TA) ||
        (userType !== EUserType.TA && dynamicParameters.ENABLE_OPERATIONS_WORKSPACE_INTERNAL_USERS)
      );
    },
    allowedViewRoles: [EUserType.ADMIN, EUserType.SR, EUserType.FINANCE, EUserType.RL, EUserType.TA],
    options: [
      {
        label: 'Tasks',
        value: EInventoryReferenceL2.TASKS,
        urlRedirect: () => '/tasks',
        urlGroup: () => ['/tasks'],
        allowedViewRoles: [EUserType.ADMIN, EUserType.SR, EUserType.FINANCE, EUserType.RL, EUserType.TA],
      },
    ],
  },
  {
    label: 'Users',
    value: EInventoryReferenceL1.USERS,
    urlRedirect: () => '/travel-agents',
    urlGroup: () => ['/travel-agents'],
    iconHtml: <WorkspaceUsers fill={theme.colors['inv-mango']} width={16} height={16} />,
    iconHtmlBig: <WorkspaceBigUsers fill={theme.colors['inv-mango']} />,
    iconHtmlHomepage: <WorkspaceUsers fill={theme.colors['inv-mango']} width={36} height={36} />,
    iconAlign: 'left',
    isDivider: false,
    allowedViewRoles: [EUserType.ADMIN, EUserType.SR],
    options: [
      {
        label: 'Travel companies',
        value: EInventoryReferenceL2.TRAVEL_COMPANIES,
        urlRedirect: () => '/travel-companies',
        urlGroup: () => ['/travel-companies', '/ta-history'],
        allowedViewRoles: [EUserType.ADMIN],
      },
      {
        label: 'Travel agents',
        value: EInventoryReferenceL2.TRAVEL_AGENTS,
        urlRedirect: () => '/travel-agents',
        urlGroup: () => ['/travel-agents'],
        allowedViewRoles: [EUserType.ADMIN, EUserType.SR],
      },
      {
        label: 'Internal Users',
        value: EInventoryReferenceL2.INTERNAL_USERS,
        urlRedirect: () => '/users',
        urlGroup: () => ['/users'],
        allowedViewRoles: [EUserType.ADMIN],
      },
    ],
  },
];
