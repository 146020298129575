import { call, takeLatest, select, put, delay } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { selectedTaSelector } from '../../agents';
import { GET_SELECTED_TASK_REQUEST, GetSelectedTaskRequestAction, getSelectedTaskFailureAction, getSelectedTaskSuccessAction, listTaskRemindersSuccessAction } from '../actions';
import { enqueueNotification } from 'store/modules/ui';
import { makeTaskManagementApi } from 'services/TaskManagementApi/TaskManagementApi';
import * as uuid from 'uuid';
import { ITaskAPI } from 'services/TaskManagementApi/types/TaskManagementResponse';
import { APITaskToFETask } from 'services/TaskManagementApi/TaskManagementUtils';

const UUIDV5_NAMESPACE = '27ba6479-1910-4f20-b2cd-424c58c49e5a';

export function* getTaskSaga(action: GetSelectedTaskRequestAction) {
  yield delay(1000);

  try {
    const selectedTa = yield select(selectedTaSelector);

    const taskId = action.taskId;
    const taskManagementApi = makeTaskManagementApi(selectedTa?.uuid);
    const apiTask: AxiosResponse<ITaskAPI> = yield call(taskManagementApi.getTask, taskId); 
    const task = APITaskToFETask(apiTask.data);
    
    // Backend returns list of reminders without uuids
    const reminders = task.reminders.map(item => ({
      ...item,
      id: uuid.v5(`${item.channel}-${item.offset}-${item.offsetUnit}`, UUIDV5_NAMESPACE),
    }));
    
    yield put(getSelectedTaskSuccessAction(task));
    yield put(listTaskRemindersSuccessAction(reminders));
  } catch (e) {
    yield put(getSelectedTaskFailureAction(e));
    yield put(enqueueNotification({ message: 'Error while retrieving this task', options: { variant: 'error' } }));
  }
}

export function* watchGetTaskSaga() {
  yield takeLatest(
    [GET_SELECTED_TASK_REQUEST],
    getTaskSaga
  );
}
