import { append, fromPairs, lensProp, omit, over, pathOr, pipe, prop, reduce, toPairs, isNil } from 'ramda';
import { normalize } from 'normalizr';
import { isNilOrEmpty } from 'ramda-adjunct';
import { proposal as proposalSchema } from 'api/schema';
import { BookingStatusTypes } from 'config/enums';
import { successAction, errorFromResponse, errorAction, isError, genericAction } from 'store/common';
import { completeBooking, setBookings, createBookingWithNewProposal } from 'store/modules/bookings/actions';
import { enqueueNotification } from 'store/modules/ui/actions';
import { getBooking, getBookingStatus } from 'store/modules/bookings/selectors';
import { guestInfoSelector } from 'store/modules/bookingBuilder/selectors';
import { getCurrentUserUuid, isInternalUser } from 'store/modules/auth/selectors';
import { getProposal } from 'store/modules/proposals/selectors';
import { selectedTaSelector } from 'store/modules/agents';
import { makeBackendApi } from 'services/BackendApi';
import * as ActingOnBehalfOfSelectors from 'store/modules/actingOnBehalfOf/selectors';

export const PROPOSAL_AMEND_BOOKING = 'PROPOSAL_AMEND_BOOKING';
export const PROPOSAL_BOOKING_REQUEST = 'PROPOSAL_BOOKING_REQUEST';
export const PROPOSAL_COMPLETE_BOOKING = 'PROPOSAL_COMPLETE_BOOKING';
export const PROPOSAL_FETCH = 'PROPOSAL_FETCH';
export const PROPOSAL_UPDATE = 'PROPOSAL_UPDATE';
export const PROPOSAL_COMPLETE = 'PROPOSAL_COMPLETE';
export const PROPOSAL_COMPLETE_SUCCESS = 'PROPOSAL_COMPLETE_SUCCESS';
export const PROPOSALS_ADD = 'PROPOSALS_ADD';
export const PROPOSALS_FETCH = 'PROPOSALS_FETCH';
export const PROPOSALS_NEW = 'PROPOSALS_NEW';
export const PROPOSALS_NEW_SUCCESS = 'PROPOSALS_NEW_SUCCESS';
export const PROPOSAL_GENERATE_PDF = 'PROPOSAL_GENERATE_PDF';

/**
 * Clean payload
 *
 * Removes values that are empty or null from the payload
 *
 * @param {object}
 * @returns {object}
 */
const cleanPayload = pipe(
  toPairs,
  reduce((accum, [key, value]) => (!isNilOrEmpty(value) ? append([key, value], accum) : accum), []),
  fromPairs
);

const backendApi = getState => makeBackendApi(getState ? selectedTaSelector(getState())?.uuid : null);

/**
 * Fetch proposals
 *
 * @param {*} params
 */
export const fetchProposals = params => async (dispatch, getState) => {
  dispatch(genericAction(PROPOSALS_FETCH, params));

  try {
    const proposalResponse = await backendApi(getState).getAvailableProposals();

    const proposals = {};
    proposalResponse.data.forEach(p => {
      proposals[p.uuid] = p;
    });

    const data = {
      result: proposalResponse.data.map(p => p.uuid),
      entities: {
        proposals,
      },
    };

    dispatch(successAction(PROPOSALS_FETCH, data));
  } catch (e) {
    dispatch(errorFromResponse(PROPOSALS_FETCH, e, 'There was a problem fetching proposals.'));
  }
};

/**
 * Fetch proposal
 *
 * @param {string} id
 * @param {*} params
 * @returns {Function}
 */
export const fetchProposal = id => async dispatch => {
  dispatch(genericAction(PROPOSAL_FETCH, { id }));

  try {
    const {
      data: { data },
    } = await makeBackendApi().getProposal(id);

    const normalized = normalize(data, proposalSchema);
    // Omit any bookings from the entities
    const proposal = over(lensProp('entities'), omit(['bookings']), normalized);

    // Extract the bookings and trigger the `setBookings` action
    const bookings = pathOr({}, ['entities', 'bookings'], normalized);

    const proposalsWithPotentialFlag = Object.keys(proposal.entities.proposals).reduce((acc, proposalKey) => {
      acc[proposalKey] = {
        ...proposal.entities.proposals[proposalKey],
        containsPotentialBookings: someBookingsArePotential(
          proposal.entities.proposals[proposalKey].bookings,
          bookings
        ),
      };
      return acc;
    }, {});

    proposal.entities.proposals = proposalsWithPotentialFlag;

    dispatch(setBookings(bookings));
    dispatch(successAction(PROPOSAL_FETCH, proposal));
  } catch (e) {
    dispatch(errorFromResponse(PROPOSAL_FETCH, e, 'There was a problem fetching proposal.'));
  }
};

const someBookingsArePotential = (bookingKeys, bookings) => {
  return bookingKeys.reduce((res, key) => {
    return bookings[key].status === BookingStatusTypes.POTENTIAL ? true : res;
  }, false);
};

const getGuestInfoPayload = getState => {
  const guestInfo = guestInfoSelector(getState());
  return fromPairs(toPairs(omit(['isRepeatGuest'], guestInfo)).filter(tuple => !isNil(tuple[1])));
};

/**
 * Create new proposal
 *
 * @param {string} name
 * @param {string} bookingId
 * @param {object} backendApi
 * @returns {Function}
 */
export const createNewProposal = (
  name,
  bookingId,
  backendApi,
  bookingBuild = undefined,
  taMarginAmountOverride = '',
  selectedTa = undefined,
  basketBuildUuid = undefined,
  onPostRemove = () => {}
) => async (dispatch, getState) => {
  const booking = getBooking(getState(), bookingId);
  const guestInfo = getGuestInfoPayload(getState);
  const isSr = isInternalUser(getState());

  // If the current user is a SR, then we use the `travelAgentUserUuid` from the booking rather than
  // the current user's UUID
  const userUuid = isSr ? prop('travelAgentUserUuid', booking) : getCurrentUserUuid(getState());
  const newProposalInfo = {
    name,
    userUuid,
    ...guestInfo,
  };

  try {
    dispatch(genericAction(PROPOSALS_NEW, { bookingId }));
    dispatch(enqueueNotification({ message: 'Proposal created successfully.', options: { variant: 'success' } }));

    await dispatch(
      createBookingWithNewProposal(
        onPostRemove,
        bookingId,
        {
          ...guestInfo,
          specialRequests: [guestInfo.comments],
        },
        newProposalInfo,
        //until backendApi isn't default client - we pass it through, as long as it should be built inside component
        backendApi,
        bookingBuild,
        taMarginAmountOverride,
        selectedTa,
        basketBuildUuid
      )
    );

    dispatch(successAction(PROPOSALS_NEW, { bookingId }));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('e', e);
    dispatch(errorFromResponse(PROPOSALS_NEW, e, 'There was a problem creating proposal.'));
  }
};

/**
 * Add to proposal
 *
 * Adds a booking to a proposal
 *
 * @param {string} proposalUuid
 * @param {string} bookingId
 * @returns {Function}
 */
export const addToProposal = (proposalUuid, bookingId) => async (dispatch, getState) => {
  dispatch(genericAction(PROPOSALS_ADD, { proposalUuid, bookingId }));
  const guestInfo = getGuestInfoPayload(getState);
  const actingOnBehalfOfUuid = ActingOnBehalfOfSelectors.actingOnBehalfOfUuidSelector(getState());

  // This time we just need to complete the booking with status POTENTIAL.
  /** @todo this is a duplicate of `createProposal` functionality and should be split out into a resuable function */
  await dispatch(
    completeBooking(
      bookingId,
      {
        proposalUuid,
        ...guestInfo,
        specialRequests: [guestInfo.comments],
      },
      BookingStatusTypes.POTENTIAL,
      actingOnBehalfOfUuid
    )
  );

  const bookingStatus = getBookingStatus(getState());

  dispatch(fetchProposal(proposalUuid));

  if (!isError(bookingStatus)) {
    dispatch(
      enqueueNotification({
        message: 'Added booking to an existing proposal successfully.',
        options: { variant: 'success' },
      })
    );
    dispatch(successAction(PROPOSALS_ADD, { result: proposalUuid }));
  } else {
    dispatch(errorAction(PROPOSALS_ADD, { result: proposalUuid }));
    dispatch(
      enqueueNotification({ message: 'Error creating proposal. Please, try later.', options: { variant: 'error' } })
    );
  }
};

export const addExistingBookingToProposal = (proposalUuid, bookingUuid) => async (dispatch, getState) => {
  dispatch(genericAction(PROPOSALS_ADD, { proposalUuid, bookingUuid }));

  try {
    await backendApi(getState).addBookingToProposal(proposalUuid, bookingUuid);
    dispatch(successAction(PROPOSALS_ADD, { result: proposalUuid }));
  } catch (e) {
    const errors = e?.response?.data?.errors || [];
    const errorMsg = errors[0]?.detail || errors[0]?.message || 'There was a problem adding to existing proposal.';

    dispatch(errorAction(PROPOSALS_ADD, { result: proposalUuid }));
    dispatch(errorFromResponse(PROPOSALS_ADD, e, errorMsg));
  }
};

export const completeProposal = (proposalUuid, payload) => async (dispatch, getState) => {
  dispatch(genericAction(PROPOSAL_COMPLETE, { id: proposalUuid, payload }));
  const proposal = getProposal(getState(), proposalUuid);

  const name = prop('name', proposal);
  const userUuid = prop('userUuid', proposal);

  // Make sure that the name and userUuid are sent with the patch
  const attributes = {
    name,
    userUuid,
    ...cleanPayload(payload),
  };

  const backend = makeBackendApi();

  try {
    await backend.updateProposal(proposalUuid, attributes);
    await backend.completeProposal(proposalUuid);

    const {
      data: { data },
    } = await backend.getProposal(proposalUuid);

    const normalized = normalize(data, proposalSchema);
    dispatch(successAction(PROPOSAL_COMPLETE, normalized));
    dispatch(
      enqueueNotification({ message: `Proposal '${name}' completed successfully .`, options: { variant: 'success' } })
    );
  } catch (e) {
    dispatch(errorFromResponse(PROPOSAL_COMPLETE, e, 'There was a problem completing this proposal.'));
  }
};

export const generateProposalPdf = (proposalUuid, payload) => async (dispatch, getState) => {
  dispatch(genericAction(PROPOSAL_GENERATE_PDF, { id: proposalUuid, payload }));

  try {
    const {
      data: { data },
    } = await backendApi(getState).previewProposal(proposalUuid, { attributesToRewrite: payload });

    dispatch(successAction(PROPOSAL_GENERATE_PDF, data));
  } catch (e) {
    dispatch(errorFromResponse(PROPOSAL_GENERATE_PDF, e, 'There was a problem generating a PDF.'));
  }
};
