import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import * as UiActions from 'store/modules/ui/actions';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import * as AgentsSelectors from 'store/modules/agents/selectors';
import * as FastSearchSelectors from 'store/modules/fastSearch/selectors';
import { useCurrentWidth } from 'effects';
import { useDispatch, useSelector } from 'react-redux';
import SaveIcon from 'ui/Icons/components/Save.component';
import DownloadIcon from 'ui/Icons/components/Download.component';
import AddToPhotosIcon from 'ui/Icons/components/AddToPhotos.component';
import { RIGHT_COLUMN_BREAKPOINT } from './const';
import { StandardModal } from 'pureUi/Modal';
import { RatesWarningModalContent } from 'containers/HotelContainer/RatesWarningModal';
import { ModalModes } from 'containers/SummaryForm/const';
import DownloadQuoteMarginModal from 'containers/SummaryForm/DownloadQuoteMarginModal';
import { BookingBuilder, ENetworkRequestStatus, makeBackendApi } from 'services/BackendApi';
import { selectedTaSelector } from 'store/modules/agents';
import { makeBookingManagerApi } from 'services/BookingManagerApi';
import { ProposalModal } from 'containers/HotelContainer/ProposalModal';
import { useDownloadQuote } from './hooks/useDownloadQuote';
import { useSaveBooking } from './hooks/useSaveBooking';
import { useAddBookingToProposal } from './hooks/useAddBookingToProposal';
import { LodgingSummary } from 'interfaces';
import { useHistory } from 'react-router';
import { ButtonWithIcon } from './components/ButtonWithIcon';

interface ISaveQuoteProposalButtonsProps {
  booking: BookingBuilder;
  lodgingSummaries: LodgingSummary[];
  className?: string;
  canBook: boolean;
  isRequestInProgress: boolean;
  setRequestInProgress: Dispatch<SetStateAction<boolean>>;
  setRedirectLocation: React.Dispatch<React.SetStateAction<string | null>>;
  setShouldShowLeaveAlert: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}

export const SaveQuoteProposalButtons: React.FC<ISaveQuoteProposalButtonsProps> = React.memo(({ booking, canBook, isRequestInProgress, setRequestInProgress, setRedirectLocation, setShouldShowLeaveAlert, isLoading }) => {
  const { currentWidth } = useCurrentWidth();
  const dispatch = useDispatch();
  const history = useHistory();
  
  const selectedTa = useSelector(selectedTaSelector);
  const backendApi = makeBackendApi(selectedTa?.uuid);
  const bookingManagerApi = makeBookingManagerApi();
  const selectedCompanyMembership = useSelector(AgentsSelectors.selectedCompanyMembershipSelector);
  const searchQuery = useSelector(FastSearchSelectors.offersQuerySelector);
  const clientCountryCode = searchQuery.clientCountryCode;
  const enqueueNotification = (notification: any) => dispatch(UiActions.enqueueNotification(notification));

  const [modalRatesWarningMode, setModalRatesWarningMode] = useState(null);
  const openModalRatesWarning = useCallback(mode => setModalRatesWarningMode(mode), []);
  const closeModalRatesWarning = useCallback(() => setModalRatesWarningMode(null), []);
  const containsLiveRates = booking.response.potentialBooking.Accommodation.some(requestedAccommodation => {
    return requestedAccommodation.isLiveRate;
  });
  
  const {
    handleSaveBookingButtonTrigger,
    handleSaveBookingButton,
  } = useSaveBooking({
    backendApi,
    containsLiveRates,
    selectedCompanyMembership,
    clientCountryCode,
    closeModalRatesWarning,
    setShouldShowLeaveAlert,
    setRedirectLocation,
    openModalRatesWarning,
    setRequestInProgress,
  });

  const {
    handleDownloadQuoteModalButton, 
    handleDownloadQuoteModalClose,
    handleDownloadQuoteButtonTrigger,
    handleDownloadQuoteButton,
    isQuoteMarginSelectorVisible,
    isQuoteDownloading
  } = useDownloadQuote({
    setRequestInProgress,
    backendApi,
    bookingManagerApi,
    enqueueNotification,
    selectedCompanyMembership,
    containsLiveRates,
    closeModalRatesWarning,
    setShouldShowLeaveAlert,
    openModalRatesWarning,
  });

  const {
    handleCreateNewProposal,
    handleAddToProposal,
    handleAddToProposalButton,
    handleAddToProposalTrigger,
    proposals,
    proposalStatus,
    isProposalModalOpen,
    handleOnProposalModalClose,
  } = useAddBookingToProposal({
    backendApi,
    containsLiveRates,
    openModalRatesWarning,
    closeModalRatesWarning,
    setShouldShowLeaveAlert,
    setRedirectLocation,
    setRequestInProgress,
  });

  useEffect(() => {
    if (proposalStatus === 'SUCCESS') {
      history.push('/search/beta');
    }
  }, [history, proposalStatus]);
  
  const guestInfo = useSelector(BookingBuilderSelectors.guestInfoSelector);
  const guestInfoFilledIn = guestInfo.guestFirstName?.trim() && guestInfo.guestLastName?.trim();
  const buttonsDisabled = !guestInfoFilledIn || !canBook || isLoading;
  
  const ModalActions = Object.freeze({
    [ModalModes.SAVE]: handleSaveBookingButton,
    [ModalModes.DOWNLOAD]: handleDownloadQuoteButton,
    [ModalModes.PROPOSAL]: handleAddToProposalButton,
  });
  
  return (
    <div className="save-quote-proposal-buttons-container">
      <div className="save-quote-proposal-buttons flex gap-[8px]">
          <ButtonWithIcon
            caption="Save"
            className="booking-button-save"
            Icon={SaveIcon}
            onClick={handleSaveBookingButtonTrigger}
            disabled={buttonsDisabled}
          />
          <ButtonWithIcon 
            caption="Quote"
            Icon={DownloadIcon}
            className="booking-button-quote"
            onClick={handleDownloadQuoteButtonTrigger}
            disabled={buttonsDisabled}
          />
          <ButtonWithIcon
            caption="Proposal"
            Icon={AddToPhotosIcon}
            className={classnames('booking-button-proposal', { 'min-w-[132px]': currentWidth >= RIGHT_COLUMN_BREAKPOINT })}
            onClick={handleAddToProposalTrigger}
            disabled={buttonsDisabled}
          />
        </div>

      {isQuoteMarginSelectorVisible && (
        <DownloadQuoteMarginModal
          isQuoteDownloading={isQuoteDownloading}
          onClose={handleDownloadQuoteModalClose}
          onDownload={handleDownloadQuoteModalButton}
        />
      )}
      {modalRatesWarningMode !== null && (
        <StandardModal removePadding={true} showCloseButton={false}>
          <RatesWarningModalContent
            onConfirm={modalRatesWarningMode ? ModalActions[modalRatesWarningMode] : () => {}}
            onClose={closeModalRatesWarning}
            isConfirmDisabled={isRequestInProgress}
          />
        </StandardModal>
      )}
      {isProposalModalOpen && (
        <ProposalModal
          proposals={proposals}
          hotelUuid={booking.request.hotelUuid}
          createNewProposal={handleCreateNewProposal}
          addToProposal={handleAddToProposal}
          isLoading={isLoading}
          proposalStatus={proposalStatus}
          onClose={handleOnProposalModalClose}
        />
      )} 
    </div>
  );
});
