import React from 'react';
import { formatPrice } from 'utils';

interface ISubTotalProductsRow {
    subTotalCents: number;
    bookingCurrencySymbol: string | null | undefined;
    cta?: React.ReactElement;
    hasItems: boolean;
};

export const ClosingProductsRow = (props: ISubTotalProductsRow) => {
    const {
      subTotalCents,
      bookingCurrencySymbol,
      cta,
      hasItems,
    } = props;

    return(
        (hasItems && (
            <tr>
                <td className="p-0">{cta}</td>
                <td className=""></td>
                <td className="w-14% text-right p-2">
                    <span className="uppercase text-xs">Subtotal</span>
                </td>
                <td className="w-14% text-right pr-4 pl-2">
                <span className="subtotal block w-full bg-teal-20 p-2 text-base text-right font-bold font-pt-sans">
                    {bookingCurrencySymbol}
                    {subTotalCents != undefined && formatPrice(subTotalCents / 100)}
                    {subTotalCents == undefined && '0.00'}
                </span>
                </td>
            </tr>
        )) || (
            <tr>
                <td>
                <div>{cta}</div>
                </td>
            </tr>
        )
    );

};