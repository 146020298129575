import { call, takeLatest, select, put } from 'redux-saga/effects';
import { selectedTaSelector } from '../../agents';
import { enqueueNotification } from 'store/modules/ui';
import { makeTaskManagementApi } from 'services/TaskManagementApi/TaskManagementApi';
import { LIST_TASK_COMMENTS_REQUEST, ListTaskCommentRequestAction, listTaskCommentsFailureAction, listTaskCommentsSuccessAction } from '../actions';

export function* listTaskCommentSaga(action: ListTaskCommentRequestAction) {

  try {
    const selectedTa = yield select(selectedTaSelector);

    const taskManagementApi = makeTaskManagementApi(selectedTa?.uuid);
    const taskCommentsResponse = yield call(taskManagementApi.listTaskComments, action.taskId); 
    yield put(listTaskCommentsSuccessAction(taskCommentsResponse.data));
  } catch (e) {
    yield put(listTaskCommentsFailureAction(e));
    yield put(enqueueNotification({ message: 'Unable to get comments', options: { variant: 'error' } }));
  }
}

export function* watchListTaskCommentsSaga() {
  yield takeLatest(
    [LIST_TASK_COMMENTS_REQUEST],
    listTaskCommentSaga
  );
}
