import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';

import { ENetworkRequestStatus } from 'services/BackendApi';
import { formatDateTimeDisplay } from 'utils';
import { theme } from '../../../../tailwind.config';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { SvgIcon } from 'ui/SvgIcon';
import DownloadIcon from 'ui/Icons/download.component.svg';
import * as TaskManagementActions from 'store/modules/taskManagement/actions';
import { ITaskAttachment } from 'services/TaskManagementApi/types/TaskManagementResponse';
import { renderFullName } from 'utils/guestInformation';
import { ITableColumn, ITableRow } from 'ui/Table/types';
import { CircleIconButton } from 'ui/CircleIconButton';
import { Table } from 'ui/Table';
import { ESortOrder } from 'store/common/types';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';
import { deleteAttachmentRequestAction, setSelectedAttachmentIdAction } from 'store/modules/taskManagement/actions';
import { deleteAttachmentLoadingSelector, selectedAttachmentIdSelector } from 'store/modules/taskManagement/selectors';

const noop = () => {};

export interface ITaskAttachmentsTableProps {
  taskId: string;
  taskAttachments: ITaskAttachment[];
  taskAttachmentsLoading: ENetworkRequestStatus;
}


export const TaskAttachmentsTable: React.FC<ITaskAttachmentsTableProps> = ({ taskId, taskAttachments, taskAttachmentsLoading }) => {
  const dispatch = useDispatch();
  const deleteAttachmentLoading = useSelector(deleteAttachmentLoadingSelector);
  const selectedAttachmentId = useSelector(selectedAttachmentIdSelector);

  useEffect(() => {
    dispatch(TaskManagementActions.listAttachmentRequestAction(taskId));
  }, [taskId, dispatch]);

  const handleDeleteAttachment = useCallback(
    (taskId: string) => {
      dispatch(setSelectedAttachmentIdAction(taskId));
    },
    [selectedAttachmentId]
  );

  const handleConfirmDeleteAttachment = useCallback(
    () => {
      if (selectedAttachmentId) {
        dispatch(deleteAttachmentRequestAction(taskId));
      }
    },
    [selectedAttachmentId]
  );

  const handleCancelDeleteAttachment = useCallback(
    () => {
      dispatch(setSelectedAttachmentIdAction(null));
    },
    [selectedAttachmentId]
  );


  const columns: ITableColumn[] = useMemo(() => {
    return [
      { id: 'uploaded', label: 'Uploaded', width: '15px' },
      { id: 'documentTitle', label: 'Document title', width: '60px', className: 'text-ellipsis overflow-hidden' },
      { id: 'createdBy', label: 'Created By', width: '20px' },
      { id: 'actions', label: '', width: '12px' }
    ];
  }, []);

  const rows: ITableRow[] = useMemo(() => {
    return taskAttachments.map(item => {
      const createdByName = renderFullName(undefined, item.createdBy.firstName, item.createdBy.lastName);
      const actions = (
        <div className="item-actions flex items-center relative">
          <div className="w-4px h-34px rounded bg-brown-15 mr-10px" />
          <div className="download-task-attachment-button cursor-pointer inline-block mx-auto">
            <a onClick={() => {saveAs(item.url, item.name)}}>
              <SvgIcon
                width="30px"
                height="30px"
                IconComponent={DownloadIcon}
                defaultFill={theme.colors['white']}
                hoverFill={theme.colors['brown-20']}
                activeFill={theme.colors['brown-20']}
              />
            </a>
          </div>
          <CircleIconButton
            type="secondary"
            className="delete-action mx-1"
            iconClass="far fa-trash-alt"
            style={{ minWidth: '30px', minHeight: '30px' }}
            onClick={() => handleDeleteAttachment(item.id)}
          />
        </div>
      );
      return {
        id: item.id,
        cells: [
          { id: 'uploaded', value: formatDateTimeDisplay(item.createdAt), title: formatDateTimeDisplay(item.createdAt) },
          { id: 'documentTitle', value: item.name, title: item.name, className: 'text-ellipsis overflow-hidden whitespace-nowrap' },
          { id: 'createdBy', value: createdByName, title: createdByName },
          {
            id: 'actions',
            value: actions,
          },
        ]
      }
    })
  }, [taskAttachments]);

  return (
    <div className="task-attachments w-full font-pt-sans">
      {selectedAttachmentId && (
          <ConfirmationModal
            type={EConfirmationModalType.WARNING}
            title='Delete Document.'
            message='Are you sure you want to delete this document?'
            confirmButtonLabel="Yes"
            cancelButtonLabel="No"
            onConfirm={handleConfirmDeleteAttachment}
            onCancel={handleCancelDeleteAttachment}
            isConfirmLoading={deleteAttachmentLoading === ENetworkRequestStatus.PENDING}
          />
        )}
      {taskAttachmentsLoading === ENetworkRequestStatus.PENDING && (
        <div className="mt-20px">
          <LoadingBar />
        </div>
      )}

      {taskAttachmentsLoading === ENetworkRequestStatus.ERROR && (
        <div className="mt-20px">
          <ErrorBar />
        </div>
      )}

      {taskAttachmentsLoading === ENetworkRequestStatus.SUCCESS && taskAttachments?.length > 0 && (
        <div className="max-h-[220px] overflow-auto border border-solid border-gray-40">
          <Table
            columns={columns}
            rows={rows}
            sortBy="createdAt"
            sortOrder={ESortOrder.DESC}
            evenColor="white-true"
            stickyHeader
            onSort={noop}
            messageWhenEmpty="No results"
          />
        </div>
      )}
    </div>
  );
};
