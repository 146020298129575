import React, { useCallback, useLayoutEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'ramda';
import { SnackbarProvider } from 'notistack';

import Notifications from 'components/Notifications';
import { withUser } from 'hoc';
import GlobalBanner from 'pureUi/GlobalBanner';

import { propTypes } from './Layout.props';
import { StyledLayout, LayoutChildren, LayoutHeader } from './Layout.styles';
import { Footer } from '../../containers/Footer/Footer';
import { InvHeader } from 'ui/InvHeader';
import { useSelector } from 'react-redux';
import { isAuthenticated as isAuthenticatedSelector, isTA as isTASelector, getCurrentUser } from 'store/modules/auth';
import { readFromSessionStorage } from 'services/windowSessionStorage';

import { ANALYTICS_SESSION_UUID_KEY } from 'config';
import { DriftSnippet } from 'integrations/drift/Snippet';
import { WoopraSnippet } from 'integrations/woopra/Snippet';
import { HotjarSnippet } from 'integrations/hotjar/Snippet';
import { GoogleAnalyticsSnippet } from 'integrations/GoogleAnalytics/Snippet';
import { initWoopra } from 'integrations/woopra/initWoopra';
import { ChatWidgetSnippet } from 'integrations/chatWidget/Snippet';
import { useDynamicParameters } from 'hooks/useDynamicParameters';

const ANCHOR_ORIGIN = Object.freeze({
  vertical: 'bottom',
  horizontal: 'left',
});

/**
 * Drift enabled
 *
 * Compares role to env var to see if drift should be enabled
 *
 * @param {string} role
 * @returns {boolean}
 */

export const Layout = ({ children, location: { pathname }, isAppVersionDeprecated, user }) => {
  const { dynamicParameters, dynamicParametersAreEmpty } = useDynamicParameters();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const isTA = useSelector(isTASelector);
  const currentUser = useSelector(getCurrentUser);
  // read the session ID that got set in src\index.jsx
  const sessionUuid = readFromSessionStorage(ANALYTICS_SESSION_UUID_KEY);

  const showInvHeader = isAuthenticated && dynamicParameters.ENABLE_INVENTORY_WORKSPACES === true;
  const showClassicHeader = !isAuthenticated || dynamicParameters.ENABLE_INVENTORY_WORKSPACES === false;

  useLayoutEffect(() => {
    !dynamicParametersAreEmpty && initWoopra(dynamicParameters.WOOPRA_DOMAIN);
  }, [dynamicParameters.WOOPRA_DOMAIN, dynamicParametersAreEmpty]);

  const showChatWidget = useCallback(() => {
    return (
      (isTA ? dynamicParameters.ENABLE_CHAT_TRAVEL_AGENTS : dynamicParameters.ENABLE_CHAT_INTERNAL_USERS) &&
      dynamicParameters.CHAT_WIDGET_URL
    );
  }, [
    dynamicParameters.ENABLE_CHAT_TRAVEL_AGENTS,
    dynamicParameters.ENABLE_CHAT_INTERNAL_USERS,
    dynamicParameters.CHAT_WIDGET_URL,
    dynamicParametersAreEmpty,
  ]);

  return (
    <StyledLayout isAppVersionDeprecated={isAppVersionDeprecated}>
      {dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID && (
        <GoogleAnalyticsSnippet appId={dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID} userUiid={user?.uuid} />
      )}
      {dynamicParameters.HOTJAR_APP_ID && !!user && (
        // only render the hotjar snippet if we have hotjar setup AND they're logged in
        <HotjarSnippet appId={dynamicParameters.HOTJAR_APP_ID} sessionUuid={sessionUuid} currentUserUuid={user.uuid} />
      )}
      {dynamicParameters.DRIFT_APP_ID && <DriftSnippet appId={dynamicParameters.DRIFT_APP_ID} />}
      {dynamicParameters.WOOPRA_DOMAIN && !!user && <WoopraSnippet currentUserEmail={user.email} />}
      {showChatWidget() && (
        <>
          <ChatWidgetSnippet src={dynamicParameters.CHAT_WIDGET_URL} />
          <div
            data-public-key={dynamicParameters.CHAT_BOT_API_KEY}
            data-position="bottomright"
            data-user-id={currentUser?.uuid}
            id="chat-widget-container"
          ></div>
        </>
      )}
      <SnackbarProvider anchorOrigin={ANCHOR_ORIGIN}>
        <Notifications />
      </SnackbarProvider>
      {isAppVersionDeprecated && (
        <GlobalBanner>
          <h1>Please refresh this page for the latest version of the application</h1>
        </GlobalBanner>
      )}
      {showInvHeader && (
        <>
          <InvHeader />
          <div className="h-[40px]"></div>
        </>
      )}
      {showClassicHeader && (
        <>
          <LayoutHeader currentPath={pathname} isAppVersionDeprecated={isAppVersionDeprecated} />
          <div className="h-[40px]"></div>
        </>
      )}
      <LayoutChildren>{children}</LayoutChildren>
      <div className="h-[40px]"></div>
      <Footer currentPath={pathname} className={'w-full'} />
    </StyledLayout>
  );
};

Layout.propTypes = propTypes;

export default compose(withRouter, withUser)(Layout);
