import React, { useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Link } from 'ui/Link';
import './primary-navigation.css';
import { FluidButton } from 'ui/FluidButton';
import { StyledMultiSelect } from './StyledMultiSelect';
import styled from 'styled-components';
import { useDynamicParameters } from 'hooks/useDynamicParameters';

interface IPrimaryNavigationLink {
  title: string;
  href: string;
  hard: boolean;
  id?: string;
  onButtonClick?: () => void;
  buttonStyle?: 'primary' | 'link';
}

export interface IPrimaryNavigationLinksProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  userName: string;
  activeHref?: string;
  links: IPrimaryNavigationLink[];
}

export interface IBuiltLinksProps extends IPrimaryNavigationLinksProps {
  className?: string;
}

const BuiltLinks = (props: IBuiltLinksProps) => {
  const { dynamicParameters } = useDynamicParameters();
  const history = useHistory();

  const adminUserLinks = [
    {
      label: 'Travel Partners',
      value: '/travel-agents',
    },
    {
      label: 'Sales Representatives',
      value: `${dynamicParameters.ADMIN_BASE_URL}/sales-representatives`,
    },
    {
      label: 'Rate Loaders',
      value: `${dynamicParameters.ADMIN_BASE_URL}/rate-loaders`,
    },
  ];
  
  if (dynamicParameters.ENABLE_INTERNAL_USERS) {
    adminUserLinks.push({
      label: 'Internal Users',
      value: `/users`,
    });
  }
  
  return (
    <div
      className={classNames('primary-navigation flex items-center justify-end', {
        [`${props.className}`]: true,
      })}
    >
      {props.links
        .filter(link => link.href != undefined)
        .map(link => {
          const isActive = props.activeHref && props.activeHref === link.href;
          if (link.buttonStyle === 'primary') {
            return (
              <FluidButton className="inline-flex ml-3" type="primary" onClick={link.onButtonClick}>
                {link.title}
              </FluidButton>
            );
          } else if (link.buttonStyle === 'link') {
            return (
              <a
                key={link.title}
                onClick={e => {
                  e.preventDefault();
                  if (link.onButtonClick !== undefined) {
                    link.onButtonClick();
                  }
                }}
                className={classNames(
                  'menu-link text-center px-10px text-sm font-pt-sans border border-solid border-gray-40 border-t-0 border-l-0 border-b-0',
                  {
                    'last-of-type:border-0': props.isAdmin,
                  }
                )}
                href={'#'}
              >
                {link.title}
              </a>
            );
          } else if (link.hard) {
            return (
              <a
                key={link.title}
                href={link.href}
                className={classNames(
                  'menu-link text-center px-10px text-sm font-pt-sans border border-solid border-gray-40 border-t-0 border-l-0 border-b-0',
                  {
                    'last-of-type:border-0': props.isAdmin,
                  }
                )}
              >
                {link.title}
              </a>
            );
          } else {
            return (
              <Link
                key={link.title}
                to={link.href}
                className={classNames(
                  'menu-link text-center px-10px text-sm font-pt-sans border border-solid border-gray-40 border-t-0 border-l-0 border-b-0',
                  {
                    'primary-navigation-is-active text-green-hard font-bold': isActive,
                    'last-of-type:border-0': props.isAdmin,
                  }
                )}
              >
                {link.title}
              </Link>
            );
          }
        })}

      {props.isAdmin && (
        <StyledMultiSelect
          className={classNames('min-w-170px bg-brown-15 text-brown-100 border-brown-15', {
            'ml-3': !props.isAdmin,
            'mr-1': props.isAdmin,
          })}
          openClassName="font-bold"
          itemsClassname="bg-brown-15"
          itemCtaClassName="hover:bg-teal-40 text-black"
          selectedValues={[]}
          overwriteLabelText={'Users'}
          hideCheckboxes={true}
          isCloseOnSelect={true}
          options={adminUserLinks}
          onUpdate={selectedValues => {
            window.location.href = selectedValues[0];
          }}
        />
      )}
      {props.isAuthenticated && (
        <StyledMultiSelect
          className={classNames('min-w-130px bg-brown-15 text-brown-100 border-brown-15', {
            'ml-3': !props.isAdmin,
          })}
          openClassName="font-bold"
          itemsClassname="bg-brown-15"
          itemCtaClassName="hover:bg-teal-40 text-black"
          selectedValues={[]}
          overwriteLabelText={props.isAdmin ? 'Admin' : props.userName}
          hideCheckboxes={true}
          isCloseOnSelect={true}
          options={[
            {
              icon: 'fas fa-user-cog',
              label: 'Settings',
              value: '/settings',
            },
            {
              icon: 'fas fa-sign-out-alt',
              label: 'Logout',
              value: '/logout',
            },
          ]}
          onUpdate={selectedValues => {
            history.push(selectedValues[0]);
          }}
        />
      )}
    </div>
  );
};

const MobileBuiltLinks = styled(BuiltLinks)`
  flex-direction: column;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 500;
  background: white;
  padding-top: 1em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-bottom: 1.5em;

  .menu-link {
    border: 0;
    padding: 10px 0px !important;
    border-bottom: 1px solid #d5d2d1;
    width: 100%;
    text-align: left;
  }

  .multiselect-wrapper {
    width: 100%;
    margin-top: 10px;
  }

  .multiselect-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a.is-active:before {
    border-bottom: none !important;
  }

  button {
    width: 100% !important;
    margin-top: 12px;
  }
`;

export const PrimaryNavigationLinks = (props: IPrimaryNavigationLinksProps) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <React.Fragment>
      <div className="hidden xl:block">
        <BuiltLinks {...props} />
      </div>

      <div className="hidden lg:block xl:hidden">
        <div className="flex justify-end">
          <FluidButton
            className="min-w-35px"
            onClick={() => {
              setIsMobileMenuOpen(!isMobileMenuOpen);
            }}
            type="primary"
          >
            <i className="fas fa-bars" />
          </FluidButton>
        </div>
        {isMobileMenuOpen && (
          <div
            className="absolute"
            style={{
              right: '0',
              paddingRight: '20px',
              background: 'white',
              paddingTop: '20px',
              width: '100%',
              borderBottom: '1px solid #D5D2D1',
              paddingBottom: '20px',
            }}
          >
            <BuiltLinks {...props} />
          </div>
        )}
      </div>

      <div className="block lg:hidden">
        <div className="flex justify-end">
          <FluidButton
            className="min-w-35px"
            onClick={() => {
              setIsMobileMenuOpen(!isMobileMenuOpen);
            }}
            type="primary"
          >
            <i className="fas fa-bars" />
          </FluidButton>
        </div>

        <div>{isMobileMenuOpen && <MobileBuiltLinks {...props} />}</div>
      </div>
    </React.Fragment>
  );
};
