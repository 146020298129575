import React from 'react';
import classnames from 'classnames';
import { SortIcon } from './SortIcon';
import { ESortOrder } from 'store/common/types';

export interface IColumnHeaderProps {
  label: string;
  currentSortBy: string;
  currentSortOrder: ESortOrder;
  columnSortBy?: string;
}

export const ColumnHeader: React.FC<IColumnHeaderProps> = React.memo(
  ({ label, currentSortBy, columnSortBy, currentSortOrder }) => {
    return (
      <span
        className={classnames('group font-normal items-center px-2 justify-between h-8', {
          flex: columnSortBy,
          'hover:bg-gray-10 cursor-pointer rounded': !!columnSortBy,
          'bg-gray-10': !!columnSortBy && currentSortBy === columnSortBy,
        })}
      >
        <span>{label}</span>
        {!!columnSortBy && <SortIcon sortBy={currentSortBy} sortOrder={currentSortOrder} newSortBy={columnSortBy} />}
      </span>
    );
  }
);
