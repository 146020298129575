import { call, takeLatest, select, put } from 'redux-saga/effects';
import { selectedTaSelector } from '../../agents';
import { DELETE_COMMENT_REQUEST, DeleteCommentRequestAction, deleteCommentSuccessAction, deleteTaskFailureAction, listTaskCommentsRequestAction, setSelectedCommentIdAction } from '../actions';
import { enqueueNotification } from 'store/modules/ui';
import { makeTaskManagementApi } from 'services/TaskManagementApi/TaskManagementApi';

export function* deleteCommentSaga(action: DeleteCommentRequestAction) {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const taskManagementApi = makeTaskManagementApi(selectedTa?.uuid);
    yield call(taskManagementApi.deleteComment, action.commentId); 
    yield put(deleteCommentSuccessAction());
    yield put(setSelectedCommentIdAction(null));
    yield put(enqueueNotification({ message: 'Comment deleted', options: { variant: 'success' } }));
    yield put(listTaskCommentsRequestAction(action.taskId));
  } catch (e) {
    yield put(deleteTaskFailureAction());
    yield put(setSelectedCommentIdAction(null));
    yield put(enqueueNotification({ message: 'Unable to delete comment', options: { variant: 'error' } }));
  }
}

export function* watchDeleteCommentSaga() {
  yield takeLatest(
    [DELETE_COMMENT_REQUEST],
    deleteCommentSaga
  );
}
