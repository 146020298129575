import { call, takeLatest, select, put } from 'redux-saga/effects';
import { selectedTaSelector } from '../../agents';
import { DELETE_TASK_REQUEST, DeleteTaskRequestAction, deleteTaskFailureAction, deleteTaskSuccessAction, getTaskListRequestAction } from '../actions';
import { enqueueNotification } from 'store/modules/ui';
import { makeTaskManagementApi } from 'services/TaskManagementApi/TaskManagementApi';

export function* deleteTaskSaga(action: DeleteTaskRequestAction) {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const taskManagementApi = makeTaskManagementApi(selectedTa?.uuid);
    yield call(taskManagementApi.deleteTask, action.taskId); 
    yield put(deleteTaskSuccessAction());
    yield put(enqueueNotification({ message: 'Task deleted', options: { variant: 'success' } }));
    yield put(getTaskListRequestAction());
  } catch (e) {
    yield put(deleteTaskFailureAction());
    yield put(enqueueNotification({ message: 'Unable to delete task', options: { variant: 'error' } }));
  }
}

export function* watchDeleteTaskSaga() {
  yield takeLatest(
    [DELETE_TASK_REQUEST],
    deleteTaskSaga
  );
}
