import { isInstantBookRelatedError } from 'utils/bookingBuilder';
import { handleRequestBooking } from '../helpers';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import * as AuthSelectors from 'store/modules/auth/selectors';
import { useSelector } from 'react-redux';
import { SelectedAccommodation } from 'services/BackendApi';
import { paymentMethodSelector } from 'store/modules/bookingConfirmation/subdomains/payment/selectors';

export const useInstantBooking = ({
  bookingBuild,
  setRequestInProgress,
  backendApi,
  enqueueNotification,
  selectedCompanyMembership,
  bookingDomain,
  bbv1AddBookingError,
  bbv1ClearBookingErrors,
  clientCountryCode,
  instantBookErrorMessage,
  lodgingSummaries,
  setShouldShowLeaveAlert,
  setRedirectLocation,
  taMarginAmountOverride,
  actingOnBehalfOfUser,
  selectedTa,
  basketBuildUuid,
}) => {
  const { dynamicParameters } = useDynamicParameters();
  const isSr: boolean = useSelector(AuthSelectors.isInternalUser);
  const isTa: boolean = useSelector(AuthSelectors.isTA);

  const showInstantBookButton =
    (dynamicParameters.ENABLE_INSTANT_BOOK_TA_USERS && isTa) ||
    (dynamicParameters.ENABLE_INSTANT_BOOK_INTERNAL_USERS && isSr);
  const requestedBookingAccommodations: SelectedAccommodation[] = bookingBuild?.request?.Accommodation ?? [];
  const isAllRoomsStatic = requestedBookingAccommodations.every(accommodation => !accommodation.liveRate);
  const isAnyRoomsFreeSale = requestedBookingAccommodations.some(accommodation => accommodation.availableToInstantBook);
  const isAllRoomsFreeSale =
    isAllRoomsStatic && requestedBookingAccommodations.every(accommodation => accommodation.availableToInstantBook);
  const areAllBasketLodgingInstantBook = (lodgingSummaries || []).every(lodging => lodging.availableToInstantBook);
  const isAnyBasketLodgingInstantBook = (lodgingSummaries || []).some(lodging => lodging.availableToInstantBook);

  const paymentMethod = useSelector(paymentMethodSelector);

  const handleBookNow = async () => {
    setRequestInProgress(true);
    setShouldShowLeaveAlert(false);
    try {
      await handleRequestBooking({
        bookingBuild,
        backendApi,
        bookingDomain,
        bbv1AddBookingError,
        bbv1ClearBookingErrors,
        clientCountryCode,
        selectedCompanyMembership,
        isInstantBook: true,
        setShouldShowLeaveAlert,
        setRedirectLocation,
        paymentMethod,
        taMarginAmountOverride,
        actingOnBehalfOfUser,
        selectedTa, // OWA-7837
        basketBuildUuid,
      });
    } catch (e) {
      setRequestInProgress(false);

      const message = isInstantBookRelatedError(e)
        ? instantBookErrorMessage
        : 'Error creating booking. Please try later to complete.';

      enqueueNotification({ message, options: { variant: 'error' } });
    }
  };

  return {
    handleBookNow,
    showInstantBookButton,
    isAnyBasketLodgingInstantBook,
    isAnyRoomsFreeSale,
    isAllRoomsFreeSale,
    areAllBasketLodgingInstantBook,
  };
};
