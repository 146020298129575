import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { compose } from 'redux';
import WithBasicSearch, { IWithBasicSearchProps } from 'hoc/WithBasicSearch';
import { IWithTravelAgentsDataProps, withTravelAgentsData } from 'hoc/WithTravelAgentsData';
import PredictiveTextInput from 'pureUi/PredictiveTextInput';
import { LodgingsEditor } from 'pureUi/LodgingsEditor';
import DateRangeInput from 'pureUi/DateRangeInput';
import { PrimaryButton } from 'pureUi/Buttons';
import { pureUiTheme } from 'pureUi/pureUiTheme';
import Checkbox from 'pureUi/Checkbox';
import { DatePickerStateProvider, IDatePickerSateParams } from 'pureUi/providers/DatePickerStateProvider';
import { getBootstrapCountriesSelector } from 'store/modules/bootstrap/selectors';
import { useSelector } from 'react-redux';
import { Multiselect } from 'ui/Multiselect';
import { TCountryCode } from 'interfaces';
import * as AuthSelectors from 'store/modules/auth/selectors';
import SearchIcon from 'ui/Icons/search.component.svg';
import RadioButton from 'pureUi/RadioButton';
import { theme } from '../../../tailwind.config';
import { ICompanyMembership } from 'services/BookingManagerApi/types/CompanyMemberships';
import InfoIcon from 'ui/Icons/components/Info.component';
import { SvgIcon } from 'ui/SvgIcon';
import { ModalV1 } from 'ui/ModalV1';
import { useDynamicParameters } from 'hooks/useDynamicParameters';

export interface ISearchBarProps extends IWithBasicSearchProps, IWithTravelAgentsDataProps {
  className: string;
  isSearchIconVisible: boolean;
}

export const SearchBar = (props: ISearchBarProps) => {
  const { dynamicParameters } = useDynamicParameters();
  const [membershipInModal, setMembershipInModal] = useState<ICompanyMembership | null>(null);

  const [showLodgingControls, setShowLodgingControls] = useState(false);

  const countries = useSelector(getBootstrapCountriesSelector);
  const isTa = useSelector(AuthSelectors.isTA);
  const isSr = useSelector(AuthSelectors.isInternalUser);

  const showClientCountryCode = !isTa || (isTa && dynamicParameters.TA_USE_CLIENT_COUNTRY_CODE);

  const closeMembershipModal = useCallback(() => {
    setMembershipInModal(null);
  }, []);

  const taGridCols = dynamicParameters.TA_USE_CLIENT_COUNTRY_CODE ? 4 : 3;
  const gridTemplateColumns = isSr ? 'repeat(3, 1fr) 124px 215px' : `repeat(${taGridCols}, 1fr) 215px`;

  return (
    <div className={props.className}>
      <div className="grid" style={{ gridTemplateColumns }}>
        <label>
          <span>Destination or Resort</span>
          <PredictiveTextInput
            placeholder="Where to"
            inputClassName="destination-or-resort"
            value={props.searchQuery.name!}
            onChange={props.basicSearchHandlers.handleDestinationChange}
            options={props.nameSearchResults}
            onOptionSelect={props.destinationChange}
            showDropDown={props.showNameSearchResults}
            onFocus={props.basicSearchHandlers.handleShowNameSearchDropDown(true)}
            onBlur={props.basicSearchHandlers.handleShowNameSearchDropDown(false)}
          />
        </label>

        <label>
          <span>Dates * </span>
          <DatePickerStateProvider
            key={props.initialized}
            defaultSelectedDates={props.selectedDates}
            onDateChange={props.basicSearchHandlers.handleDateChange}
            render={(params: IDatePickerSateParams) => {
              return (
                <DateRangeInput
                  className="serachBarDateRangeInput"
                  displayString={params.displayString}
                  currentDate={params.datePickerCurrentDate}
                  totalNights={params.totalNights}
                  selectedDates={params.selectedDates}
                  onDayClick={params.handleDayClick}
                  onDayMouseOver={params.handleDateMouseOver}
                  showDatePicker={params.showDatePicker}
                  onNextClick={params.incrementDate}
                  onPrevClick={params.decrementDate}
                  onMouseDown={params.toggleDatePicker}
                  onClickOutside={params.hideDatePicker}
                />
              );
            }}
          />
        </label>

        <label>
          <span>Rooms *</span>
          <LodgingsEditor
            className="lodging-editor"
            showControls={showLodgingControls}
            lodgings={props.searchQuery.lodgings}
            activeLodgingIndex={props.activeLodingIndex}
            onIncrementIndex={props.incrementActiveLodgingIndex}
            onTabSelect={props.setActiveLodgingIndex}
            onIncrementRoomCount={props.incrementRoom}
            onIncrementAdultCount={props.incrementAdult}
            onIncrementChildCount={props.incrementChild}
            onChildAgeChange={props.setAge}
            totalGuestCount={props.totalGuestCount}
            onClick={() => {
              setShowLodgingControls(true);
            }}
            onClickOutside={() => {
              setShowLodgingControls(false);
            }}
          />
        </label>

        {isTa && showClientCountryCode && (
          <label>
            <span>Guest country</span>
            <Multiselect
              className={'guest-country bg-white min-w-290px font-hurmegeometric-sans'}
              placeholderText="SELECT COUNTRY"
              borderColourClass="border-gray-17"
              itemsClassname="uppercase text-black"
              itemContentClassName="uppercase text-black"
              fontClass="font-hurmegeometric-sans"
              itemCtaClassName="uppercase bg-white hover:bg-blue-5"
              openClassName="outline outline-2 outline-teal-100"
              placeholderClasses="not-italic text-gray-15"
              height={37}
              onUpdate={sv => {
                if (sv[0]) {
                  props.guestCountryChangeAction(sv[0] as TCountryCode);
                } else {
                  props.guestCountryChangeAction(null);
                }
              }}
              hideCheckboxes={true}
              isSingleSelectMode={true}
              isCloseOnSelect={true}
              isEnableFuzzySearch={true}
              fuzzySearchInputActiveClass="bg-white"
              options={countries.map(country => ({
                value: country.code,
                label: country.name,
              }))}
              selectedValues={props.searchQuery.clientCountryCode ? [props.searchQuery.clientCountryCode] : []}
            />
          </label>
        )}

        {props.isSr && (
          <label className="repeatGuest">
            <span>Repeat Guest</span>
            <Checkbox checked={props.isRepeatGuest} onChange={props.toggleRepeatGuest} />
          </label>
        )}

        <PrimaryButton
          className="searchButton"
          disabled={!props.canSearch || !props.canSrSearch}
          onClick={props.basicSearchHandlers.handleNavigateToSearch}
        >
          <span className={classnames('flex items-center', { 'justify-center': !props.isSearchIconVisible })}>
            {props.isSearchIconVisible && (
              <SearchIcon className="block cursor-pointer ml-15px mr-2" fill={theme.colors['white']} />
            )}
            <span>Search</span>
            {!!props.isLoading && <i className="fas fa-circle-notch fa-spin ml-2"></i>}
          </span>
        </PrimaryButton>

        {props.isSr && (
          <label className="basicSearchLabel self-start">
            <span>Company</span>
            {props.isFetchingCompanies ? (
              <span>Loading companies...</span>
            ) : (
              <PredictiveTextInput
                placeholder="Select company..."
                value={props.companyNameSearch}
                onChange={e => props.searchCompanyByName(e.currentTarget.value)}
                options={[props.companiesNames]}
                onOptionSelect={props.handleCompanyNameChange}
                showDropDown={props.showCompanyDropdown}
                onFocus={() => props.showCompanyDropdownChange(true)}
                onBlur={() => props.showCompanyDropdownChange(false)}
              />
            )}
          </label>
        )}
        {props.isSr && props.selectedCompany && (
          <label className="basicSearchLabel self-start">
            <span>Travel Agent</span>
            {props.isFetchingTA ? (
              <span>Loading travel agents...</span>
            ) : (
              <PredictiveTextInput
                placeholder="Select agent..."
                value={props.taNameSearch}
                onChange={e => props.searchTaByName(e.currentTarget.value)}
                options={[props.taNames]}
                onOptionSelect={props.handleTaNameChange}
                showDropDown={props.showTaDropdown}
                onFocus={() => props.showTaDropdownChange(true)}
                onBlur={() => props.showTaDropdownChange(false)}
              />
            )}
          </label>
        )}
        {isSr && showClientCountryCode && (
          <label className="self-start">
            <span>Guest country</span>
            <Multiselect
              className={'guest-country bg-white min-w-290px font-hurmegeometric-sans'}
              placeholderText="SELECT COUNTRY"
              borderColourClass="border-gray-17"
              itemsClassname="uppercase text-black"
              itemContentClassName="uppercase text-black"
              fontClass="font-hurmegeometric-sans"
              itemCtaClassName="uppercase bg-white hover:bg-blue-5"
              openClassName="outline outline-2 outline-teal-100"
              placeholderClasses="not-italic text-gray-15"
              height={37}
              onUpdate={sv => {
                if (sv[0]) {
                  props.guestCountryChangeAction(sv[0] as TCountryCode);
                } else {
                  props.guestCountryChangeAction(null);
                }
              }}
              hideCheckboxes={true}
              isSingleSelectMode={true}
              isCloseOnSelect={true}
              isEnableFuzzySearch={true}
              fuzzySearchInputActiveClass="bg-white"
              options={countries.map(country => ({
                value: country.code,
                label: country.name,
              }))}
              selectedValues={props.searchQuery.clientCountryCode ? [props.searchQuery.clientCountryCode] : []}
            />
          </label>
        )}
        {props.companyMemberships && props.companyMemberships.length > 0 && (
          <React.Fragment>
            <div
              className="company-memberships"
              style={{
                gridColumn: props.isSr ? '4/6' : '1/5', // unfortunately have to hardcode this because of the way the grid is set up
              }}
            >
              <span className="uppercase text-xs block mb-10px">Membership</span>
              <div className="memberships flex flex-row flex-wrap gap-3">
                {props.companyMemberships.map(membership => {
                  return (
                    <div key={membership.uuid} className="membership-radio flex flex-row items-center space-x-8px">
                      <RadioButton
                        name="chooseRegions"
                        type="radio"
                        checked={membership.uuid === props.selectedCompanyMembership?.uuid}
                        onChange={() => {
                          props.setSelectedCompanyMembership(membership);
                        }}
                      />
                      <span className="font-hurmegeometric-sans text-black-legacy text-sm font-normal space-x-5px flex items-center">
                        <span>{membership.name}</span>
                        {membership.benefitsDescription && (
                          <button
                            onClick={() => {
                              setMembershipInModal(membership);
                            }}
                            className="show-membership-info-modal bg-none bg-transparent outline-none border-non p-0 m-0 cursor-pointer"
                          >
                            <SvgIcon
                              IconComponent={InfoIcon}
                              defaultFill={theme.colors['gray-40']}
                              hoverFill={theme.colors['gray-80']}
                              activeFill={theme.colors['gray-40']}
                              width="18px"
                              height="18px"
                            />
                          </button>
                        )}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            {membershipInModal && (
              <ModalV1
                className="membership-modal min-w-[500px] max-w-[500px]"
                title={membershipInModal.name}
                message={membershipInModal.benefitsDescription ?? ''}
                onClose={closeMembershipModal}
              />
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export const StyledSearchBar = styled(SearchBar)`
  color: ${pureUiTheme.colorRoles.grayLabel};
  text-align: left;
  margin: auto;

  .grid {
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    align-items: end;
  }

  label > span {
    text-transform: uppercase;
    font-size: 12px;
    display: block;
    margin-bottom: 10px;
  }

  .searchButton {
    height: 39px;
    margin: 0;
  }

  .repeatGuest {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;

    & > span {
      margin-left: 10px;
    }
  }

  .serachBarDateRangeInput {
    width: 100%;
  }
`;

export default compose(WithBasicSearch(), withTravelAgentsData())(StyledSearchBar);
