import React from 'react';
import { Price } from 'ui/Price';
import { useCurrentWidth } from 'effects';
import { RIGHT_COLUMN_BREAKPOINT } from './const';
import { BookingBuilder } from 'services/BackendApi';
import { IBasketBuildL2 } from 'services/BackendApi/types/Basket';

interface IBookingRightTotalCostProps {
  basketBuild: IBasketBuildL2;
  className?: string;
}

export const BasketRightTotalCost: React.FC<IBookingRightTotalCostProps> = React.memo(({ basketBuild, className }) => {
  const { currentWidth } = useCurrentWidth();
  const priceClassName = 'font-hurmegeometric-sans text-19px leading-20px font-semibold text-right mt-5px m-0';

  return (
    <div className="booking-right-total-cost bg-green-25 flex justify-between items-center min-h-[76px] p-[15px]">
      <div className="flex flex-row items-center justify-end mt-[5px]">
        <p className="total-cost ml-1 font-hurmegeometric-sans text-[19px] leading-[23px] text-flint uppercase font-semibold m-0">
          Cost To You
        </p>
      </div>

      <Price
        total={basketBuild.initialBuildResponse.totals.total}
        totalBeforeDiscount={basketBuild.initialBuildResponse.totals.totalBeforeDiscount}
        currencyCode={basketBuild.initialBuildResponse.currency}
        isOnRequest={basketBuild.initialBuildResponse.totals.oneOrMoreItemsOnRequest}
        totalClassName={`${priceClassName} text-black`}
        totalBeforeDiscountClassName={`font-hurmegeometric-sans text-15px leading-18px text-right mt-5px m-0 text-gray-80 line-through`}
        totalAfterDiscountClassName={`${priceClassName} text-red-15`}
        isOnRequestClassName={`${priceClassName} text-black`}
      />
    </div>
  );
});
