import { IMainCompanyInfoResponse } from 'services/BackendApi';
import { IDynamicParametersResponse } from 'services/ParametersProviderApi';

export const BOOTSTRAP_APP_REQUEST = 'bootstrap/BOOTSTRAP_APP_REQUEST';
export const BOOTSTRAP_APP_SUCCESS = 'bootstrap/BOOTSTRAP_APP_SUCCESS';
export const BOOTSTRAP_APP_FAILURE = 'bootstrap/BOOTSTRAP_APP_FAILURE';
export const SAVE_BOOTSTRAP_DATA = 'bootstrap/SAVE_BOOTSTRAP_DATA';
export const GET_MAIN_COMPANY_INFO = 'bootstrap/GET_MAIN_COMPANY_INFO';
export const SET_MAIN_COMPANY_INFO = 'bootstrap/SET_MAIN_COMPANY_INFO';
export const BOOTSTRAP_DYNAMIC_PARAMETERS_REQUEST = 'bootstrap/BOOTSTRAP_DYNAMIC_PARAMETERS_REQUEST';
export const BOOTSTRAP_DYNAMIC_PARAMETERS_SUCCESS = 'bootstrap/BOOTSTRAP_DYNAMIC_PARAMETERS_SUCCESS';
export const BOOTSTRAP_DYNAMIC_PARAMETERS_FAILURE = 'bootstrap/BOOTSTRAP_DYNAMIC_PARAMETERS_FAILURE';

export type BootstrapAppRequestAction = ReturnType<typeof bootstrapAppRequestAction>;
export const bootstrapAppRequestAction = () => ({
  type: BOOTSTRAP_APP_REQUEST as typeof BOOTSTRAP_APP_REQUEST,
});

export type BootstrapAppSuccessAction = ReturnType<typeof bootstrapAppSuccessAction>;
export const bootstrapAppSuccessAction = (
) => ({
  type: BOOTSTRAP_APP_SUCCESS as typeof BOOTSTRAP_APP_SUCCESS,
});

export type BootstrapAppFailureAction = ReturnType<typeof bootstrapAppFailureAction>;
export const bootstrapAppFailureAction = (error: any) => ({
  type: BOOTSTRAP_APP_FAILURE as typeof BOOTSTRAP_APP_FAILURE,
  error,
});

export type SaveBootstrapDataAction = ReturnType<typeof saveBootstrapDataAction>;
export const saveBootstrapDataAction = (
  countries: any[],
  hotels: any[],
  extraPersonSupplementProduct: any,
) => ({
  type: SAVE_BOOTSTRAP_DATA as typeof SAVE_BOOTSTRAP_DATA,
  countries,
  hotels,
  extraPersonSupplementProduct,
});

export type GetMainCompanyInfoAction = ReturnType<typeof getMainCompanyInfoAction>;
export const getMainCompanyInfoAction = () => ({
  type: GET_MAIN_COMPANY_INFO as typeof GET_MAIN_COMPANY_INFO,
});

export type SetMainCompanyInfoAction = ReturnType<typeof setMainCompanyInfoAction>;
export const setMainCompanyInfoAction = (
  mainCompany: IMainCompanyInfoResponse,
) => ({
  type: SET_MAIN_COMPANY_INFO as typeof SET_MAIN_COMPANY_INFO,
  mainCompany,
});

export type BootstrapDynamicParametersRequestAction = ReturnType<typeof bootstrapDynamicParametersRequestAction>;
export const bootstrapDynamicParametersRequestAction = () => ({
  type: BOOTSTRAP_DYNAMIC_PARAMETERS_REQUEST as typeof BOOTSTRAP_DYNAMIC_PARAMETERS_REQUEST,
});

export type BootstrapDynamicParametersSuccessAction = ReturnType<typeof bootstrapDynamicParametersSuccessAction>;
export const bootstrapDynamicParametersSuccessAction = (
  dynamicParameters: IDynamicParametersResponse
) => ({
  type: BOOTSTRAP_DYNAMIC_PARAMETERS_SUCCESS as typeof BOOTSTRAP_DYNAMIC_PARAMETERS_SUCCESS,
  dynamicParameters,
});

export type BootstrapDynamicParametersFailureAction = ReturnType<typeof bootstrapDynamicParametersFailureAction>;
export const bootstrapDynamicParametersFailureAction = (error: any) => ({
  type: BOOTSTRAP_DYNAMIC_PARAMETERS_FAILURE as typeof BOOTSTRAP_DYNAMIC_PARAMETERS_FAILURE,
  error,
});

export type BoostrapDomainAction = 
| BootstrapAppRequestAction 
| BootstrapAppSuccessAction 
| BootstrapAppFailureAction
| SetMainCompanyInfoAction
| BootstrapDynamicParametersRequestAction 
| BootstrapDynamicParametersSuccessAction 
| BootstrapDynamicParametersFailureAction
| SaveBootstrapDataAction
;
