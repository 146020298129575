import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { closeCreateMarkupModalAction } from 'store/modules/companyHotelMarkup';
import { StandardModal } from 'pureUi/Modal';
import FluidButton from 'ui/FluidButton';
import Checkbox from 'ui/Checkbox';
import { NumericMaskedInput } from 'ui/stateful/MaskedInput';
import { ENetworkRequestStatus, makeBackendApi } from 'services/BackendApi';
import { getCountryName } from 'utils/country';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { Radio } from 'ui/Radio';
import * as CompanyHotelMarkupActions from 'store/modules/companyHotelMarkup/actions';
import * as CompanyHotelMarkupSelectors from 'store/modules/companyHotelMarkup/selectors';
import { iCompanyMarkupOption } from 'store/modules/companyHotelMarkup/model';

interface AddMarkupModalProps {
  isOpen: boolean;
}

export const MarkupOption = ({ name, countryCode, checked, check }) => {
  const className = ['markup', ...name.split(' ')].join('-').toLowerCase();

  return (
    <div className="flex font-normal my-2 justify-between items-end">
      <label className="flex cursor-pointer items-end" onClick={check}>
        <Checkbox className={className} checked={checked} onClick={check} />
        <span className="ml-2">{name}</span>
      </label>
      <span className="text-13px">{getCountryName(countryCode)}</span>
    </div>
  );
};

const markupToTypeLabelMap = {
  hotel: 'Hotels',
  supplier: 'Suppliers',
};

export const AddMarkupModal: React.FC<AddMarkupModalProps> = React.memo(({ isOpen }) => {
  const [selectedMarkupOptionIds, setSelectedMarkupOptionIds] = useState<string[]>([]);
  const [markupPercentage, setMarkupPercentage] = useState<string>('00.00');

  const backendApi = makeBackendApi();
  const [markupToType, setMarkupToType] = useState<'hotel' | 'supplier'>('hotel');
  const [markupToOptions, setMarkupToOptions] = useState<iCompanyMarkupOption[]>([]);

  const [getMarkupToOptionsRequest, setGetMarkupToOptionsRequest] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );
  const companyUuid = useSelector(CompanyHotelMarkupSelectors.travelCompanyUuidSelector);

  const [createMarkupRequest, setCreateMarkupRequest] = useState<ENetworkRequestStatus>(ENetworkRequestStatus.IDLE);

  const dispatch = useDispatch();

  const hotelListClassName = classnames(
    'available-hotels border border-solid border-gray-40 bg-ivory p-1 pl-2 rounded',
    {
      'flex justify-center items-center': getMarkupToOptionsRequest === ENetworkRequestStatus.PENDING,
    }
  );

  useEffect(() => {
    if (!companyUuid || !isOpen) {
      return;
    }
    setGetMarkupToOptionsRequest(ENetworkRequestStatus.PENDING);

    if (markupToType === 'hotel') {
      backendApi
        .fetchCompanyHotelAvailableToCreateMarkups(companyUuid)
        .then(res => {
          setGetMarkupToOptionsRequest(ENetworkRequestStatus.SUCCESS);
          setMarkupToOptions(res.data);
        })
        .catch(e => {
          setGetMarkupToOptionsRequest(ENetworkRequestStatus.ERROR);
          console.error('e', e);
        });
    } else if (markupToType === 'supplier') {
      backendApi
        .fetchCompanySupplierAvailableToCreateMarkups(companyUuid)
        .then(res => {
          setGetMarkupToOptionsRequest(ENetworkRequestStatus.SUCCESS);
          setMarkupToOptions(res.data);
        })
        .catch(e => {
          setGetMarkupToOptionsRequest(ENetworkRequestStatus.ERROR);
          console.error('e', e);
        });
    }
  }, [isOpen, companyUuid, markupToType]);

  const handleSelectMarkupTo = (val: 'hotel' | 'supplier') => {
    setSelectedMarkupOptionIds([]);
    setMarkupToType(val);
  };

  const handleCloseClick = useCallback(() => {
    dispatch(closeCreateMarkupModalAction());
  }, [dispatch]);

  const handleCreateMarkup = () => {
    if (!companyUuid) {
      return;
    }
    setCreateMarkupRequest(ENetworkRequestStatus.PENDING);
    backendApi
      .postCompanyHotelSupplierMarkups(companyUuid, Number(markupPercentage), selectedMarkupOptionIds, markupToType)
      .then(res => {
        dispatch(closeCreateMarkupModalAction());
        dispatch(CompanyHotelMarkupActions.fetchCHMarkupListRequestAction());
        setCreateMarkupRequest(ENetworkRequestStatus.SUCCESS);
      })
      .catch(e => {
        console.error(e);
        setCreateMarkupRequest(ENetworkRequestStatus.ERROR);
      });
  };

  const toggleSelectAll = useCallback(
    (e?: React.MouseEvent<HTMLLabelElement> | React.MouseEvent<HTMLInputElement>) => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }

      if (markupToOptions.length === selectedMarkupOptionIds.length) {
        setSelectedMarkupOptionIds([]);
        return;
      }

      setSelectedMarkupOptionIds(
        markupToOptions.map(option => ('uuid' in option ? option.uuid : option.supplierId.toString()))
      );
    },
    [markupToOptions, selectedMarkupOptionIds.length]
  );

  const toggleSelectHotel = useCallback(
    (checked: boolean, uuid: string) => (
      e: React.MouseEvent<HTMLLabelElement> | React.MouseEvent<HTMLInputElement>
    ) => {
      e.stopPropagation();
      e.preventDefault();

      if (checked) {
        setSelectedMarkupOptionIds(selectedMarkupOptionIds.filter(selectedHotel => selectedHotel !== uuid));
        return;
      }

      setSelectedMarkupOptionIds([...selectedMarkupOptionIds, uuid]);
    },
    [selectedMarkupOptionIds]
  );

  if (!isOpen) {
    return null;
  }

  return (
    <StandardModal
      className="items-start bg-black-true-transparent-60 z-11"
      frameClassName={`pt-4 px-8 pb-8 border border-solid text-black border-black-true font-pt-sans overflow-y-hidden`}
      showCloseButton={false}
      closeOnOutsideClick={true}
      onClose={handleCloseClick}
      removePadding
    >
      <div
        className="text-base text-brown-100 absolute top-4 right-4 cursor-pointer outline-none"
        onClick={handleCloseClick}
      >
        <i className="fas fa-times"></i>
      </div>
      <h3 className="font-noe-display font-normal text-21px leading-29px mt-0 mb-0">Add New Mark Up</h3>

      <div className="my-2">
        <span className="font-bold">Mark-Up</span>
        <span className="ml-1">(%)</span>
        <div className="flex text-black items-baseline my-2">
          <div className="w-16">
            <NumericMaskedInput
              inputClassName="markup-input"
              initialValue="0.00"
              value={markupPercentage}
              mask="##.##"
              onChange={setMarkupPercentage}
              allowNegative
            />
          </div>
          <span className="ml-2 text-15px">Including two decimals (##.##)</span>
        </div>
      </div>

      <div className="my-2 space-y-2">
        <span className="font-bold">Mark-Up To</span>
        <div className="flex items-center space-x-4">
          <label onClick={() => handleSelectMarkupTo('hotel')} className="flex items-center space-x-2 cursor-pointer">
            <Radio checked={markupToType === 'hotel'} onClick={() => handleSelectMarkupTo('hotel')} />
            <span>Hotel</span>
          </label>
          <label
            onClick={() => handleSelectMarkupTo('supplier')}
            className="flex items-center space-x-2 cursor-pointer"
          >
            <Radio checked={markupToType === 'supplier'} onClick={() => handleSelectMarkupTo('supplier')} />
            <span>Supplier</span>
          </label>
        </div>
      </div>

      <div className="mb-2">
        <span className="font-bold">Select {markupToTypeLabelMap[markupToType]}</span>
        <span className="ml-1">
          {selectedMarkupOptionIds.length > 0 && `(${selectedMarkupOptionIds.length} selected)`}
        </span>
      </div>

      <div className={hotelListClassName} style={{ width: '400px', minHeight: '366px' }}>
        {getMarkupToOptionsRequest === ENetworkRequestStatus.PENDING && <LoadingBar />}
        {getMarkupToOptionsRequest === ENetworkRequestStatus.SUCCESS && (
          <>
            <div className="flex font-normal py-2 px-1 bg-white rounded">
              <label className="flex cursor-pointer items-end" onClick={toggleSelectAll}>
                <Checkbox
                  checked={markupToOptions.length === selectedMarkupOptionIds.length}
                  onClick={toggleSelectAll}
                  onChange={() => toggleSelectAll()}
                />
                <span className="ml-2">Select / Unselect All</span>
              </label>
            </div>

            <div className="overflow-y-auto px-1 pt-2 pb-2" style={{ height: '320px' }}>
              {markupToOptions.map(option => {
                const uuidOrSupplierId = 'uuid' in option ? option.uuid : option.supplierId.toString();
                const checked =
                  selectedMarkupOptionIds.findIndex(selectedHotel => selectedHotel === uuidOrSupplierId) > -1;
                return (
                  <MarkupOption
                    key={uuidOrSupplierId}
                    check={toggleSelectHotel(checked, uuidOrSupplierId)}
                    checked={checked}
                    countryCode={option.countryCode}
                    name={option.name}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>

      <div className="flex mt-8">
        <FluidButton
          type="secondary"
          className="cancel-button ml-3"
          disabled={createMarkupRequest === ENetworkRequestStatus.PENDING}
          onClick={handleCloseClick}
        >
          Cancel
        </FluidButton>
        <FluidButton
          type="primary"
          className="create-markup-button ml-3"
          isLoading={createMarkupRequest === ENetworkRequestStatus.PENDING}
          disabled={Number(markupPercentage) === 0 || selectedMarkupOptionIds.length === 0}
          onClick={handleCreateMarkup}
        >
          Create Mark-up
        </FluidButton>
      </div>
    </StandardModal>
  );
});
