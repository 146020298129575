import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ENetworkRequestStatus } from 'services/BackendApi';
import FluidButton from 'ui/FluidButton';
import { newTaskAttachmentLoadSelector, newTaskAttachmentPayloadSelector, newTaskAttachmentValidationSelector } from 'store/modules/taskManagement/selectors';
import { closeUploadAttachmentModalAction, setAttachmentDisplayNameAction, setAttachmentToUploadAction, uploadAttachmentRequestAction } from 'store/modules/taskManagement/actions';
import { GeneralModal } from 'ui/GeneralModal';
import { VerifiedIcon } from 'ui/Icons/components/Verified.component';

interface ITaskUploadFileModalProps {
  taskId: string;
}

export const TaskUploadFileModal: React.FC<ITaskUploadFileModalProps> = React.memo(({ taskId }) => {
  const newUploadLoad = useSelector(newTaskAttachmentLoadSelector);
  const newUploadPayload = useSelector(newTaskAttachmentPayloadSelector);
  const newUploadValidation = useSelector(newTaskAttachmentValidationSelector);
  const dispatch = useDispatch();

  const handleSetFileToUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.currentTarget.files) {
        return;
      }

      const file = e.currentTarget.files[0];
      if (!file) {
        // eslint-disable-next-line no-console
        console.error('It seems file is not defined');
        return;
      }
      dispatch(setAttachmentToUploadAction(file));
    },
    [dispatch]
  );

  const handleSetFileDisplayName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setAttachmentDisplayNameAction(e.currentTarget.value));
    },
    [dispatch]
  );

  const handleCloseClick = useCallback(() => {
    dispatch(closeUploadAttachmentModalAction());
  }, [dispatch]);

  const handleUploadFile = useCallback(() => {
    dispatch(uploadAttachmentRequestAction(taskId));
  }, [dispatch, taskId]);

  if (!newUploadPayload) {
    return null;
  }

  return (
    <GeneralModal
      modalWindowClassName="py-[25px] px-[35px] text-black font-pt-sans overflow-y-hidden rounded w-470px"
      shouldCloseByClickingOutside={false}
      onClose={handleCloseClick}
    >
      <h3 className="font-noe-display font-normal text-21px leading-29px mt-0 mb-0">Upload New File</h3>

      <div className="text-base text-black" onClick={e => e.stopPropagation()}>
        <div className="file-form-item flex items-center mt-25px">
          <label className="select-file inline-block font-bold text-sm text-brown-100 tracking-wider py-2 px-3 border border-solid border-brown-100 rounded bg-white-true hover:bg-brown-20 cursor-pointer transition-bg-color duration-200">
            <input onChange={handleSetFileToUpload} type="file" className="hidden" />
            Select a file
          </label>
          {!!newUploadPayload.file?.size && !newUploadValidation.file.length && !!newUploadPayload.displayName?.length && (
            <VerifiedIcon className="ml-[10px]" />
          )}
          {newUploadPayload.file && !!newUploadValidation.file.length && (
            <span className="validation-error ml-[10px] text-sm text-red-95">{newUploadValidation.file[0]}</span>
          )}
        </div>
        <div className="size-message">
          <span className="size-message text-sm text-gray-100">Maximum size 10Mb.</span>
        </div>

        <div className="file-title flex flex-col mt-25px">
          <span className="font-bold">Title of Document</span>
          <input
            onChange={handleSetFileDisplayName}
            value={newUploadPayload.displayName ?? undefined}
            type="text"
            className="filename text-15px bg-ivory border border-solid border-gray-40 px-3 py-2 w-full h-[35px] outline-none transition-border-color duration-200 mt-5px focus:border-teal-80 focus:border-2 focus:py-[6px]"
          />
        </div>
      </div>

      <div className="flex mt-[25px]">
        <FluidButton
          type="primary"
          isLoading={newUploadLoad === ENetworkRequestStatus.PENDING}
          disabled={
            newUploadLoad === ENetworkRequestStatus.PENDING ||
            Object.values(newUploadValidation).some(v => v.length > 0)
            || !newUploadPayload.displayName?.length
          }
          onClick={handleUploadFile}
        >
          Upload File
        </FluidButton>
      </div>
    </GeneralModal>
  );
});
