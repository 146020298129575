import React, { useCallback } from 'react';
import classNames from 'classnames';
import * as uuid from 'uuid';
import FluidButton from 'ui/FluidButton';
import { ITaskReminder } from 'services/TaskManagementApi/types/TaskManagementResponse';
import { TaskReminder } from './TaskReminder';
import { addTaskReminderAction } from 'store/modules/taskManagement/actions';
import { useDispatch } from 'react-redux';

export interface ITaskRemindersProps {
  className?: string;
  reminders: ITaskReminder[];
  taskId?: string;
}

export const TaskReminders: React.FC<ITaskRemindersProps> = (props) => {
  const dispatch = useDispatch();

  const handleAddReminder = useCallback(() => {
    const reminder: ITaskReminder = {
      channel: 'email',
      offset: 1,
      offsetUnit: 'week',
      id: uuid.v4(),
      taskId: props.taskId,
    }
    dispatch(addTaskReminderAction(reminder));
  }, [dispatch, props.taskId]);
  
  return (
    <div className={classNames(props.className, 'task-reminders flex flex-col items-start gap-[10px]')}>
      <p className="font-pt-sans text-black m-0 text-[16px] font-bold">Reminders</p>
      {props.reminders.map(item => (
        <TaskReminder key={item.id} reminder={item} />
      ))}
      <FluidButton type="secondary" onClick={handleAddReminder}>
        Add Reminder
      </FluidButton>
    </div>
  )
};
