import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import UserIcon from 'ui/Icons/user.component.svg';
import SettingsIcon from 'ui/Icons/settings.component.svg';
import LogoutIcon from 'ui/Icons/logout.component.svg';
import MenuShowHideClosedIcon from 'ui/Icons/menu-show-hide-closed.component.svg';
import MenuShowHideOpenIcon from 'ui/Icons/menu-show-hide-open.component.svg';
import WhatsAppIcon from 'ui/Icons/whatsapp-alt.component.svg';
import ContactUsIcon from 'ui/Icons/contact-us.component.svg';
import BasketIcon from 'ui/Icons/basket.component.svg';
import { PigHeaderClosedIcon, PigHeaderOpenedIcon } from 'ui/Icons';
import { theme } from '../../../tailwind.config';
import { InvHeaderIcon } from './InvHeaderIcon';
import { useDispatch, useSelector } from 'react-redux';
import * as CompanyInfoActions from 'store/modules/companyInfo/actions';
import { getCompanySelector, getCurrentUser, getCurrentUserType, isTA as isTASelector } from 'store/modules/auth';
import { EUserType, ICompany, IUser } from 'services/BackendApi';
import { InvHeaderLogo } from './InvHeaderLogo';
import { renderFullName } from 'utils/guestInformation';
import * as InvHeaderActions from 'store/modules/inventoryHeader/actions';
import * as InvHeaderSelectors from 'store/modules/inventoryHeader/selectors';
import { MenuDropdown } from 'ui/MenuDropdown';
import { MenuSingleElement } from 'ui/MenuSingleElement';
import classnames from 'classnames';
import {
  EInventoryReferenceL1,
  EInventoryReferenceL2,
  IInventoryOptionBespoke,
  InventoryReference,
} from '../../interfaces';
import { inventoryMap, getMenuOptionsBespoke, reconstructMenuOptions, URLS_WITHOUT_MENU_HEADER } from './inventoryMap';
import AnimateHeight from 'react-animate-height';
import { getAvailableWorkspacesLinks } from 'ui/InvHeader/inventoryMap';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { VerticalLine } from 'ui/VerticalLine';
import { TADepositAccount } from 'ui/TADepositAccount';
import * as LedgerSelectors from 'store/modules/ledger/selectors';
import { Popup } from 'ui/Popup';
import { companyDataSelector, depositAccountBalanceLoadSelector } from 'store/modules/companyInfo';

export const InvHeader: React.FC = () => {
  const { dynamicParameters } = useDynamicParameters();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const company = useSelector(getCompanySelector) as ICompany;
  const taCompany = useSelector(companyDataSelector) as ICompany;
  const userType = useSelector(getCurrentUserType) as EUserType;
  const isTA = useSelector(isTASelector);
  const user = useSelector(getCurrentUser) as IUser;
  const currentPathOptions = useSelector(InvHeaderSelectors.getCurrentPathOptionsSelector);
  const currentSelectedURL = useSelector(InvHeaderSelectors.getCurrentURLSelector);
  const isMenuOpen = useSelector(InvHeaderSelectors.isMenuOpenSelector);
  const depositTotals = useSelector(LedgerSelectors.ledgerDepositStatementTotalsSelector);
  const depositAccountBalanceLoad = useSelector(depositAccountBalanceLoadSelector);
  const [showMenuHeader, setShowMenuHeader] = useState(!isMenuOpen);
  const [showDepositAccount, setDepositAccount] = useState(false);
  const depositAccountRef = useRef(null);
  const userFullName = renderFullName(undefined, user?.firstName, user?.lastName);
  const userTypeMapping = {
    [EUserType.TA]: 'Travel Agent',
    [EUserType.SR]: 'Sales Rep.',
    [EUserType.FINANCE]: 'Finance',
    [EUserType.RL]: 'Rate Loader',
    [EUserType.ADMIN]: 'Admin',
  };
  const helloString = `Hello ${userFullName} (${userTypeMapping[userType]})`;
  const exception = user.type === EUserType.TA && user.taCanReadFinance ? EInventoryReferenceL1.FINANCES : null;
  const menuOptionsBespoke: IInventoryOptionBespoke[] = getMenuOptionsBespoke(inventoryMap, [], userType, exception);
  // If this user can't get the default currentPath, probably it's because it has no permissions TO CHOOSE that specific option.
  let inventoryL1Option: IInventoryOptionBespoke | null =
    menuOptionsBespoke.find(e => e.value === currentPathOptions[0]) || null;
  if (!inventoryL1Option) {
    // Can happen that the user was allowed to go to an specific page in a NON SELECTABLE section.
    // Look for the same options now including the one previously forced as exception in "currentPathOptions[0]".
    const menuOptionsWithExceptionBespoke: IInventoryOptionBespoke[] = getMenuOptionsBespoke(
      inventoryMap,
      [],
      userType,
      currentPathOptions[0]
    );
    // Now pick the option selected, that won't be available among selectable options
    inventoryL1Option = menuOptionsWithExceptionBespoke.find(e => e.value === currentPathOptions[0]) || null;
    if (!inventoryL1Option) {
      // Not even one option for this role? Redirect to homepage if we are no already there.
      history.push('/');
    }
  }

  const exceptionL2 = exception ? EInventoryReferenceL2.STATEMENTS : null;
  const menuOptionsL2Bespoke: IInventoryOptionBespoke[] = getMenuOptionsBespoke(
    inventoryL1Option?.options ?? [],
    inventoryL1Option ? [inventoryL1Option.value] : [],
    userType,
    exceptionL2
  );

  useEffect(() => {
    // Hide header in some cases like in homepage
    const hideHeader = URLS_WITHOUT_MENU_HEADER.includes(location.pathname);
    setShowMenuHeader(!hideHeader);
    // Detect when navigation is triggered by setting URL in browser's bar. This could mismatch what we have in store 'currentSelectedURL'.
    // Or
    // A top level option is selected and it has childs, but none selected.
    if (
      currentSelectedURL !== location.pathname ||
      (currentPathOptions[1] === null && menuOptionsL2Bespoke.length > 0)
    ) {
      // We need to recalculate the path in options menu to fit the new URL
      const reconstructedPath: InventoryReference[] = reconstructMenuOptions(
        userType,
        inventoryMap,
        [],
        location.pathname,
        1,
        3
      );
      if (reconstructedPath && reconstructedPath.length > 0) {
        dispatch(InvHeaderActions.setInventoryOptionSelectedAction(reconstructedPath, location.pathname));
      } else {
        // If it's not a page routed in the menu option, let the top hierarchy routing in app do its job.
        // For the store parameters of the menu take the default ones. Avoid leaving privileges set.
        dispatch(InvHeaderActions.resetInventoryOptionSelectedAction());
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (company?.uuid) {
      dispatch(CompanyInfoActions.fetchCompanyLogoAction(company.uuid));
    }
  }, [company?.uuid]);

  let prevScrollY = window.scrollY;
  const onScroll = () => {
    // scrolling down, close the menu (if its open)
    // TODO: commented out for now, as we might reinstate this logic in the future
    // if (window.scrollY > prevScrollY && isMenuOpen) {
    //   dispatch(InvHeaderActions.setIsMenuOpenAction(false));
    //   return;
    // }

    // if we scroll to the top and it's not open, open it
    if (window.scrollY === 0 && !isMenuOpen) {
      dispatch(InvHeaderActions.setIsMenuOpenAction(true));
      return;
    }

    prevScrollY = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
    // un-register and re-register the event listener every time isMenuOpen changes, so we have the latest isMenuOpen state value to work with
  }, [isMenuOpen]);

  const handleShowHideMenu = () => {
    dispatch(InvHeaderActions.setIsMenuOpenAction(!isMenuOpen));
  };

  const handleShowDepositAccount = useCallback(
    (e: React.MouseEvent<any>) => {
      dispatch(CompanyInfoActions.fetchCompanyDepositAccountBalanceRequest());
      setDepositAccount(true);
    },
    [showDepositAccount]
  );

  const handleHideDepositAccount = useCallback(
    (e: React.MouseEvent<any>) => {
      setDepositAccount(false);
    },
    [showDepositAccount]
  );

  const handleShowHideDepositAccount = useCallback(
    (e: React.MouseEvent<any>) => (showDepositAccount ? handleHideDepositAccount(e) : handleShowDepositAccount(e)),
    [showDepositAccount]
  );

  const handleInventoryOptionSelected = useCallback(
    (option: IInventoryOptionBespoke) => {
      // Do two things: 1) Remember the option path and actual URL in store. 2) Do redirect user to his option.
      dispatch(
        InvHeaderActions.setInventoryOptionSelectedAction(
          option.path,
          (option.urlRedirect && option.urlRedirect()) || null
        )
      );
      // Assume that if we have mapped an URL we mean we want to go to a page at this level, regardless of what may be defined in levels beneath.
      // Otherwise we are just selecting the path in our menu without going anywhere.
      if (option.urlRedirect) {
        if (option.isExternal) {
          window.location.replace(option.urlRedirect());
        } else {
          history.push(option.urlRedirect());
        }
      }
    },
    [history]
  );

  const availableWorkspacesLinks = getAvailableWorkspacesLinks(user);

  const showWhatsAppIcon = !!dynamicParameters.HEADER_WHATSAPP;
  const showContactUsIcon = !!dynamicParameters.HEADER_CONTACT_FORM_URL;

  const showBasketIcon = dynamicParameters.ENABLE_BASKET && userType !== EUserType.RL && userType !== EUserType.FINANCE;

  return (
    // 2nd answer: https://stackoverflow.com/questions/5873565/set-width-of-a-position-fixed-div-relative-to-parent-div
    // Header was taking more than 100%, widening the page while the 100% elements seemed to be on the left.
    <div className="inv-header sticky w-full my-0 top-0 left-0 right-0 bg-white z-10">
      <div
        // This was breaking and provoking OWA-5464
        /*
        // extremely specific styles to ensure the shadow looks correct
        style={{
          marginLeft: '-50px',
          paddingLeft: '50px',
          paddingRight: '50px',
          width: 'calc(100% + 100px)',
        }}*/
        style={{
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
        className={classnames(
          'inv-header-top flex items-center justify-between my-0 mx-auto w-full min-h-80px max-h-80px',
          {
            'shadow-inv-shadow-sm border-solid border border-l-0 border-r-0 border-t-0 border-gray-20': !isMenuOpen,
          }
        )}
      >
        <InvHeaderLogo />
        <div className="inv-header-right">
          <div className="inv-header-user-info flex items-center">
            <UserIcon fill={theme.colors['gray-40']} />
            <span className="font-pt-sans text-15px leading-16px text-black font-normal ml-2 mr-15px">
              {helloString}
            </span>

            <div className="flex flex-row items-center space-x-5px">
              {taCompany?.enableInstantBooking && taCompany.uuid === user.companyUuid && isTA && (
                <div className="flex flex-col relative">
                  <InvHeaderIcon
                    className="inv-header-deposit-balance-icon text-black min-w-[50px] [&&]:rounded-[3px]"
                    Icon={showDepositAccount ? PigHeaderOpenedIcon : PigHeaderClosedIcon}
                    isExternal={false}
                    isNewTab={false}
                    onClick={handleShowHideDepositAccount}
                    ref={depositAccountRef}
                  />
                  <Popup
                    isVisible={showDepositAccount}
                    className="absolute top-[30px] left-[-50px] shadow"
                    onHide={handleHideDepositAccount}
                    overlayClose={true}
                    triggerRef={depositAccountRef}
                  >
                    <TADepositAccount
                      totals={depositTotals}
                      creditLimitUsd={taCompany.usdCreditLimit}
                      creditLimitEur={taCompany.eurCreditLimit}
                      loading={depositAccountBalanceLoad}
                    />
                  </Popup>
                </div>
              )}
              {showBasketIcon && (
                <InvHeaderIcon
                  className="inv-header-basket text-black"
                  Icon={BasketIcon}
                  url="/basket"
                  tooltipText="Basket"
                />
              )}
              <InvHeaderIcon
                className="inv-header-settings-icon text-black"
                Icon={SettingsIcon}
                url="/settings"
                tooltipText="Settings"
              />
              {showWhatsAppIcon && (
                <InvHeaderIcon
                  className="inv-header-whatsapp-icon text-black"
                  Icon={WhatsAppIcon}
                  url={`https://wa.me/${dynamicParameters.HEADER_WHATSAPP}`}
                  tooltipText="WhatsApp"
                  isNewTab
                  isExternal
                />
              )}
              {showContactUsIcon && (
                <InvHeaderIcon
                  className="inv-header-contact-us-icon text-black"
                  Icon={ContactUsIcon}
                  url={dynamicParameters.HEADER_CONTACT_FORM_URL}
                  tooltipText="Contact"
                  isExternal={true}
                  isNewTab={true}
                />
              )}

              <InvHeaderIcon
                className="inv-header-logout-icon text-black"
                Icon={LogoutIcon}
                url="/logout"
                tooltipText="Logout"
              />

              {showMenuHeader && (
                <>
                  <VerticalLine height="32px" color={theme.colors['gray-10']} borderWidth={2} />
                  <InvHeaderIcon
                    className="inv-header-menu-icon text-black"
                    Icon={isMenuOpen ? MenuShowHideOpenIcon : MenuShowHideClosedIcon}
                    onClick={handleShowHideMenu}
                    tooltipText="Menu"
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* 
      OWA-5857 `currentSelectedURL !== '/'` ensures we don't render the bottom part of the menu
      for any pages that don't belong to the workspaces e.g terms and conditions, privacy policy etc.
      */}
      {currentSelectedURL !== '/' && dynamicParameters.ENABLE_INVENTORY_WORKSPACES && (
        // extremely specific styles to ensure the shadow looks correct
        <AnimateHeight duration={300} height={isMenuOpen && showMenuHeader ? 'auto' : 0}>
          <div
            /*
            // extremely specific styles to ensure the shadow looks correct
            style={{
              marginLeft: '-50px',
              paddingLeft: '50px',
              paddingRight: '50px',
              width: 'calc(100% + 100px)',
            }}*/
            style={{
              paddingLeft: '20px',
              paddingRight: '20px',
            }}
            className="inv-header-dropdown flex items-center justify-between my-0 mx-auto w-full h-50px shadow-inv-shadow-sm border-solid border border-l-0 border-r-0 border-t-0 border-gray-20"
          >
            <div className="flex inv-sub-header-left">
              {availableWorkspacesLinks.length > 1 && (
                <MenuDropdown
                  className="w-200px mt-5px"
                  classNameLabel="pl-3 pr-3 text-xl text-black"
                  itemsClassname="bg-white p-15px rounded-3px"
                  itemCtaClassName="hover:bg-gray-10 rounded-3px"
                  // selectedLabelClassName="font-bold o:text-teal"
                  // selectedItemContentClassName="font-bold o:text-teal"
                  onUpdate={handleInventoryOptionSelected}
                  isCloseOnSelect={true}
                  options={menuOptionsBespoke}
                  preSelectedOption={inventoryL1Option}
                  fixedHeader={null}
                  fixedIconHtml={null}
                  hoverMode={false}
                />
              )}
            </div>
            <div className="flex inv-sub-header-right justify-end">
              {menuOptionsL2Bespoke ? (
                menuOptionsL2Bespoke.map(o => {
                  if (!o.options || o.options.length === 0) {
                    return (
                      <MenuSingleElement
                        key={o.value}
                        className={`${o.value?.toLowerCase()} h-16px pt-5px pl-3 pr-3 pb-5px font-pt-sans text-15px cursor-pointer hover:bg-gray-10 rounded-3px`}
                        selectedClassName="font-bold o:text-teal"
                        onUpdate={handleInventoryOptionSelected}
                        option={o}
                        isSelected={currentPathOptions[1] === o.value}
                        hidden={o.isHidden && o.isHidden()}
                      />
                    );
                  }
                  const upperPath = inventoryL1Option ? [inventoryL1Option?.value, o.value] : [];
                  const menuOptionsL3Bespoke: IInventoryOptionBespoke[] = getMenuOptionsBespoke(
                    o.options || [],
                    upperPath,
                    userType
                  );
                  const inventoryL3Option: IInventoryOptionBespoke | null =
                    menuOptionsL3Bespoke.find(e => e.value === currentPathOptions[2]) || null;
                  return (
                    <MenuDropdown
                      key={o.value}
                      className="h-16px pt-5px pl-3 pr-3 pb-5px font-pt-sans cursor-pointer rounded-3px"
                      classNameLabel="pl-1 pr-1 text-15px"
                      selectedLabelClassName="font-bold o:text-teal"
                      itemCtaPaddingClassName="p-0"
                      itemsClassname="bg-white p-2 rounded-3px"
                      itemCtaClassName="hover:bg-gray-10 rounded-3px"
                      isSelected={currentPathOptions[1] === o.value}
                      onUpdate={handleInventoryOptionSelected}
                      isCloseOnSelect={true}
                      options={menuOptionsL3Bespoke}
                      preSelectedOption={inventoryL3Option}
                      fixedHeader={o.label}
                      fixedIconHtml={null}
                      hoverMode={true}
                      isHidden={o.isHidden && o.isHidden()}
                    />
                  );
                })
              ) : (
                <div>Workspace has no options.</div>
              )}
            </div>
          </div>
        </AnimateHeight>
      )}
    </div>
  );
};
