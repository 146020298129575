import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Home from 'pages/Home';
import { getCurrentUser } from 'store/modules/auth';
import { EUserType } from 'services/BackendApi';

import { LoginModal } from 'ui/LoginModal';

import { propTypes } from './Login.props';
import { ENVIRONMENT } from 'config';
import { LoadingOverlay } from 'ui/LoadingOverlay';
import { useDynamicParameters } from 'hooks/useDynamicParameters';

export const Login = ({ history, ...props }) => {
  const { dynamicParameters } = useDynamicParameters();
  const homeUrl = ENVIRONMENT !== 'local' ? dynamicParameters.MARKETING_APP_URL : '/';
  const user = useSelector(getCurrentUser);
  const [isModalOpen, setModalOpen] = useState(true);
  const [isLoadingOverlayOpen, setLoadingOverlayOpen] = useState(false);

  const handleClose = useCallback(() => {
    setModalOpen(false);
    setLoadingOverlayOpen(true);
    setTimeout(() => {
      window.location.href = homeUrl;
    }, 100);
  }, [homeUrl]);

  // Here we skip homepage for users that only have one option: TA, RL
  // But this login page is only in Legacy flow, not in Cognito flow
  // We have to replicate this decission making in Welcome endpoint response
  // at src/store/modules/cognito/cognitoSaga.ts
  useEffect(() => {
    // https://pureescapes.atlassian.net/browse/OWA-5171
    if (dynamicParameters.ENABLE_INVENTORY_WORKSPACES && user.type === EUserType.RL) {
      window.location.replace(`${dynamicParameters.ADMIN_BASE_URL}/hotels`);
    } else if (dynamicParameters.ENABLE_INVENTORY_WORKSPACES && user.type === EUserType.TA && !user.taCanReadFinance) {
      history.replace('/filters');
    } else if (user.type) {
      history.replace('/');
    }
  }, [dynamicParameters.ADMIN_BASE_URL, dynamicParameters.ENABLE_INVENTORY_WORKSPACES, history, user]);

  return (
    <Fragment>
      <Home history={history} {...props} />
      <LoginModal isOpen={isModalOpen} onClose={handleClose} />
      <LoadingOverlay isOpen={isLoadingOverlayOpen} />
    </Fragment>
  );
};

Login.propTypes = propTypes;

export default Login;
