import { createSelector } from 'reselect';
import { isEqual } from 'lodash-es';
import { IBookingManagerBreakdownSubdomain, ILiveCancellationModalData } from './model';
import { breakdownSubdomainSelector } from '../../selectors';
import { IHeadlineLineItemBreakdown } from 'services/BookingManagerApi';
import { computeTextHash, computeSalesCostHash, computeSalesCost } from 'utils/breakdown';

export const headlineLineItemBreakdownSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.headlineLineItemBreakdown
);

export const headlineLineItemBreakdownCommitedSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.headlineLineItemBreakdownCommited
);

export const headlineLineItemBreakdownUpdatedAtSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.headlineLineItemBreakdownUpdatedAt
);

export const breakdownNetworkRequestsSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.networkRequests
);

export const isHeadlineBreakdownEditedWithoutSavingSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.isHeadlineBreakdownEditedWithoutSaving
);

export const getBreakdownLogoSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.breakdownLogo
);

export const headlineBreakdownVersionListSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.headlineBreakdownVersionList
);

export const headlineBreakdownSelectedVersionSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.headlineBreakdownSelectedVersion
);

export const headlineBreakdownRealLatestVersionSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.headlineBreakdownRealLatestVersion
);

export const breakdownAddActivitySelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.activity
);

export const breakdownAncillaryTransferModalToggleSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.isAddAncillaryTransfersModalOpen
);

export const breakdownAncillaryGroundServiceModalToggleSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.isAddAncillaryGroundServiceModalOpen
);

export const breakdownCreateInvoiceSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.createInvoice
);

export const breakdownCreateManualInvoiceSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.createManualInvoice
);

export const isCreateQuoteModalOpenSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.isCreateQuoteModalOpen
);

export const paymentTermsSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.paymentTerms
);

export const cancellationPoliciesSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.cancellationPolicies
);

export const policiesAndRestrictionsSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.policiesAndRestrictions
);

export const offerTermsSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.offerTerms
);

export const membershipSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.membership
);

export const isPcrDetailsModalOpenSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.isPcrDetailsModalOpen
);

export const isSaveToPerformActionModalOpenSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.isSaveToPerformActionModalOpen
);

export const isBreakdownTextModifiedSelector = createSelector(
  headlineLineItemBreakdownCommitedSelector,
  headlineLineItemBreakdownSelector,
  (commited: IHeadlineLineItemBreakdown | null, current: IHeadlineLineItemBreakdown | null) => {
    if (!commited || !current) {
      return false;
    }
    return !isEqual(computeTextHash(commited), computeTextHash(current));
  }
);

export const isBreakdownSalesCostModifiedSelector = createSelector(
  headlineLineItemBreakdownCommitedSelector,
  headlineLineItemBreakdownSelector,
  (commited: IHeadlineLineItemBreakdown | null, current: IHeadlineLineItemBreakdown | null) => {
    if (!commited || !current) {
      return false;
    }
    return !isEqual(computeSalesCostHash(commited), computeSalesCostHash(current));
  }
);

export const breakdownSalesCostDiffSelector = createSelector(
  headlineLineItemBreakdownCommitedSelector,
  headlineLineItemBreakdownSelector,
  (commited: IHeadlineLineItemBreakdown | null, current: IHeadlineLineItemBreakdown | null) => {
    if (!commited || !current) {
      return null;
    }

    return computeSalesCost(current) - computeSalesCost(commited);
  }
);

export const liveCancellationModalDataSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.liveCancellationModalData
);

export const liveAccommodationCancellationPoliciesSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.liveAccommodationCancellationPolicies
);

export const liveCancellationModalDataForPostBookingEndpointSelector = createSelector(
  liveCancellationModalDataSelector,
  (liveCancelModalData: ILiveCancellationModalData) => {
    return liveCancelModalData.accoms.map(accom => {
      return {
        bookingReference: accom.bookingRef,
        cancellationCostCents: accom.cancellationFeeCents,
        dueDate: accom.date,
      };
    });
  }
);

export const isLiveCancellationWarningModalOpenSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.isLiveCancellationWarningModalOpen
);

export const paymentMethodsSelector = createSelector(breakdownSubdomainSelector, domain => domain.paymentMethods);

export const shouldUserNeedToConfirmCPsAndPTsSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.shouldUserNeedToConfirmCPsAndPTs
);

export const breakdownTransfersSuppliersSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.transfersSuppliers
);

export const breakdownGroundServicesSuppliersSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.groundServicesSuppliers
);

export const breakdownActivitiesSuppliersSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.activitiesSuppliers
);

export const currentlyEditingLineItemSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.currentlyEditingLineItem
);

export const currentlyEditingLineItemIndexSelector = createSelector(
  breakdownSubdomainSelector,
  (subdomain: IBookingManagerBreakdownSubdomain) => subdomain.currentlyEditingLineItemIndex
);
