import { IGuest, EVoucherLang } from 'services/BackendApi';

import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { EPdfLogo } from '../breakdown/model';

export interface IFormError {
  [key: string]: string;
}

// used for form errors
export enum EVoucherFormFields {
  SELECTED_GUESTS,
  ARRIVAL_TIME,
  DEPARTURE_TIME,
  NOTES,
}

export interface IVoucherSubdomain {
  formData: IVoucherSubdomainFormData;
  voucherLogo: EPdfLogo;
  lang: EVoucherLang;
  formErrors: IFormError;
  networkRequests: IVoucherSubdomainNetworkRequests;
}

export interface IVoucherSubdomainFormData {
  selectedGuests: IGuest[];
  selectedTransfers: string[];
  selectedAccomodationIndexes: string[];
  selectedMealPlansCompoundIndexes: string[];
  selectedGroundServices: string[];
  availableGuests: IGuest[];
  availableTransfers: string[];
  availableAccomodations: { title: string; mealPlan: string[] }[];
  availableMealPlans: IVoucherSubdomainFormDataAvailableMealPlan[];
  availableGroundServices: string[];
  notes: string;
  resortName: string;
  resortAddress: string;
  resortPhone: string;
  resortCountryCode: string;
  resortRegion: string;
  resortConfirmationNumber: string;
  policiesAndRestrictions: string;
}

export interface IVoucherSubdomainFormDataAvailableMealPlan {
  compoundIndex: string; // this is needed to handle duplicates, see OWA-2759
  title: string;
}

export interface IVoucherSubdomainNetworkRequests {
  voucherFormViewLoad: ENetworkRequestStatus;
  voucherDownloadLoad: ENetworkRequestStatus;
}

export const VOUCHER_INDEX_SEPARATOR = '---';
