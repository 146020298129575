import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { selectedTaSelector } from '../../agents';
import { GET_TASKS_REQUEST, SET_PAGE_NUMBER, SET_SORT, getTaskListFailureAction, getTaskListSuccessAction, setPageNumberAction } from '../actions';
import { assigneeFilterSelector, bookingRefFilterSelector, categoriesFilterSelector, currentPageSelector, datesFilterSelector, guestNameFilterSelector, idFilterSelector, priorityFilterSelector, reporterFilterSelector, statusesFilterSelector, taskitemsPerPageSelector, taskSortBySelector, taskSortOrderSelector, tasksPageCountSelector, teamFilterSelector } from '../selectors';
import { makeTaskManagementApi } from 'services/TaskManagementApi/TaskManagementApi';
import { ISearchTasksResponse } from 'services/TaskManagementApi/types/TaskManagementResponse';
import { APITaskToFETask } from 'services/TaskManagementApi/TaskManagementUtils';
import { addDays, subMinutes } from 'date-fns';

export function* getTasksSaga() {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const currentPage = yield select(currentPageSelector);
    const itemsPerPage = yield select(taskitemsPerPageSelector);
    const sortField = yield select(taskSortBySelector);
    const sortOrder = yield select(taskSortOrderSelector);

    const key = yield select(idFilterSelector);
    const statuses = yield select(statusesFilterSelector);
    const priority = yield select(priorityFilterSelector);
    const dates = yield select(datesFilterSelector);
    const deadlineFrom = dates[0];
    const deadlineTo = dates.length ? addDays(subMinutes(new Date(dates[dates.length - 1]), 1), 1).toISOString() : dates[0];
    const categoryIds = yield select(categoriesFilterSelector);
    const teamId = yield select(teamFilterSelector);
    let assigneeId = yield select(assigneeFilterSelector);
    if (!assigneeId) {
      assigneeId = undefined;
    }
    if (assigneeId === 'no_assignment') {
      assigneeId = null;
    }
    const bookingHumanReadableId = yield select(bookingRefFilterSelector);
    const bookingLeadGuestName = yield select(guestNameFilterSelector);
    const createdById = yield select(reporterFilterSelector);

    const offset = currentPage * itemsPerPage;
    const limit = itemsPerPage;

    const options = {
      sort: {
        key: sortField,
        order: sortOrder,
      },
      filter: {
        key: key ?? '',
        statuses,
        ...(priority ? { priority } : {}),
        deadlineFrom,
        deadlineTo,
        categoryIds,
        ...(teamId ? { teamId } : {}),
        ...(assigneeId !== undefined && assigneeId !== 'all' ? { assigneeId } : {}),
        ...(bookingHumanReadableId ? { bookingHumanReadableId } : {}),
        ...(bookingLeadGuestName ? { bookingLeadGuestName } : {}),
        ...(createdById && createdById !== 'all' ? { createdById } : {}),
      },
      pagination: {
        offset,
        limit,
      },
    };

    const taskManagementApi = makeTaskManagementApi(selectedTa?.uuid);
    const apiTasks: AxiosResponse<ISearchTasksResponse> = yield call(taskManagementApi.getTasks, options); 
    const tasks = apiTasks.data?.tasks.map(item => APITaskToFETask(item));
    yield put(getTaskListSuccessAction(tasks, apiTasks.data?.count ?? 0));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    yield put(getTaskListFailureAction(e));
  }
}

function* getTasksAndResetPageNumber() {
  yield call(getTasksSaga);
  yield put(setPageNumberAction(0));
}

export function* watchGetTaskListSaga() {
  yield takeLatest(
    [GET_TASKS_REQUEST],
    getTasksAndResetPageNumber
  );
  yield takeLatest(
    [SET_PAGE_NUMBER, SET_SORT],
    getTasksSaga
  );
}
