import { BookingBuilder } from 'services/BackendApi';
import { IBasketBuild, IBasketBuildL2, IBasketBuildL3, IBasketBuildL4 } from 'services/BackendApi/types/Basket';

export const getHotelImage = (build: IBasketBuildL2) => {
  return build.hotelPhotoUrl;
};

export const convertBasketBuildToBookingBuilderFormat = (
  basketBuild: IBasketBuild | IBasketBuildL2 | IBasketBuildL3 | IBasketBuildL4,
  useLatestBookingBuild = false
): BookingBuilder => {
  return {
    bookingErrors: useLatestBookingBuild
      ? (basketBuild as IBasketBuildL4).bookingBuilderResponse?.errors || []
      : basketBuild.initialBuildResponse.errors,
    request: basketBuild.buildRequest,
    response: useLatestBookingBuild
      ? (basketBuild as IBasketBuildL4).bookingBuilderResponse!
      : basketBuild.initialBuildResponse,
  };
};
