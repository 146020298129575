import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Sidebar, Modal } from '@pure-escapes/webapp-ui-components';
import { Link } from 'ui/Link';

import { SettingsForm, SettingsPasswordForm } from 'containers';
import { CompanyDetailsFormConnected } from 'containers/CompanyDetailsForm';

import { propTypes } from './User.props';
import { UserContainer, Main, Aside } from './User.styles';
import * as InvHeaderSelectors from 'store/modules/inventoryHeader/selectors';
import { useDynamicParameters } from 'hooks/useDynamicParameters';

const SettingsPagesEnum = {
  password: 'password',
  companyDetails: 'company-details',
  profileDetails: 'profile-details',
};

export const User = ({ history, match, ...props }) => {
  const { t } = useTranslation();

  const onClose = useCallback(() => history.push('/settings'), [history]);
  const section = match.params.section;
  let Component;
  let activePage;
  const { dynamicParameters } = useDynamicParameters();

  switch (section) {
    case SettingsPagesEnum.password:
      activePage = SettingsPagesEnum.password;
      break;
    case SettingsPagesEnum.companyDetails:
      Component = CompanyDetailsFormConnected;
      activePage = SettingsPagesEnum.companyDetails;
      break;

    case SettingsPagesEnum.profileDetails:
    // this is default page
    // eslint-disable-next-line no-fallthrough
    default:
      Component = SettingsForm;
      activePage = SettingsPagesEnum.profileDetails;
  }

  const invHeaderUrl = useSelector(InvHeaderSelectors.getLastSetURLSelector);

  return (
    <>
      {dynamicParameters.ENABLE_INVENTORY_WORKSPACES && (
        <Link
          type="internal"
          to={invHeaderUrl}
          styledAsButton={true}
          className="ml-20px"
          internalClassName="rounded-none uppercase space-x-2 font-hurmegeometric-sans"
        >
          <span>{`< Go Back`}</span>
        </Link>
      )}
      <div className="container mx-auto max-w-1280px">
        <UserContainer>
          <Aside>
            <Sidebar
              title={t('labels.settings')}
              links={[
                <Link
                  key="root"
                  to={`/settings/${SettingsPagesEnum.profileDetails}`}
                  className={SettingsPagesEnum.profileDetails === activePage ? 'font-bold' : ''}
                >
                  {t('labels.profileDetails')}
                </Link>,
                <Link
                  key="root"
                  to={`/settings/${SettingsPagesEnum.companyDetails}`}
                  className={SettingsPagesEnum.companyDetails === activePage ? 'font-bold' : ''}
                >
                  {t('labels.companyDetails')}
                </Link>,
              ]}
            />
          </Aside>
          <Main>{Component && <Component {...props} />}</Main>
          {activePage === SettingsPagesEnum.password && (
            <Modal open={true} onClose={onClose}>
              <SettingsPasswordForm onComplete={onClose} {...props} />
            </Modal>
          )}
        </UserContainer>
      </div>
    </>
  );
};

User.propTypes = propTypes;

export default User;
