import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { List } from './List';

import { Create } from './Create';
import { Edit } from './Edit';
import { useDynamicParameters } from 'hooks/useDynamicParameters';

export interface InternalUsersRoutingProps extends RouteComponentProps {}

export const InternalUsersRoutingComponent = (props: InternalUsersRoutingProps) => {
  const { dynamicParameters } = useDynamicParameters();
  
  return (
    <Switch>
      {dynamicParameters.ENABLE_INTERNAL_USERS && (
        <>
          <Route path={`${props.match.path}/`} component={List} exact />
          <Route path={`${props.match.path}/create`} component={Create} exact />

          <Route path={`${props.match.path}/:internalUserUuid/edit`} component={Edit} />
        </>
      )}
    </Switch>
  );
};

export const InternalUsersRouting = compose(withRouter)(InternalUsersRoutingComponent);
