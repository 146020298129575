import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useInitialDimensions } from 'hooks/useInitialDimensions';
import * as InvHeaderSelectors from 'store/modules/inventoryHeader/selectors';
import { useDynamicParameters } from 'hooks/useDynamicParameters';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

export const StickyTopBar: React.FC<Props> = ({ className, children }) => {
  const { dynamicParameters } = useDynamicParameters();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isSticky, setSticky] = useState(false);
  const initialButtonDimensions = useInitialDimensions(containerRef);
  const containerHeight = initialButtonDimensions?.height ?? 0;
  const isMenuOpen = useSelector(InvHeaderSelectors.isMenuOpenSelector);

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const topPosition = containerRef.current?.getBoundingClientRect().y ?? 0;
      if (!isSticky && topPosition < 0) {
        setSticky(true);
      }
      if (isSticky && topPosition >= 0) {
        setSticky(false);
      }
    });
  }, [isSticky]);

  return (
    <div ref={containerRef} className="sticky-top-bar" style={{ minHeight: `${containerHeight}px` }}>
      <div
        style={isSticky ? { zIndex: 5 } : undefined}
        className={classNames(className, 'flex justify-center', {
          'fixed top-[80px] left-0 right-0 bg-white': isSticky && !isMenuOpen && dynamicParameters.ENABLE_INVENTORY_WORKSPACES,
          'fixed top-[130px] left-0 right-0 bg-white': isSticky && isMenuOpen && dynamicParameters.ENABLE_INVENTORY_WORKSPACES,
          // there IS no menu to open without inventory workspace UI - therefore, if its sticky and we don't have workspace UI, just set top to 0
          'fixed top-[0px] left-0 right-0 bg-white': isSticky && !dynamicParameters.ENABLE_INVENTORY_WORKSPACES,
        })}
      >
        <div className="main w-1280px">{children}</div>
      </div>
    </div>
  );
};
